.amigDataTableHeader{
    padding: 8px 0px;
    box-shadow: none !important;    
    border: none !important; 
    color: $color-amig-gray-100 !important;
    div{
        @extend .amig-h6-web-text;
        &:hover, &:disabled, &:focus{
            background-color: transparent !important; 
            box-shadow: none !important;
        }
    }
}

.amigDataTableRow{     
    color: $color-amig-gray-80;
    background-color: $color-amig-white !important;
    @extend .amig-body2-web-regular;   
}

.amigPaginationSelectWrapper{
    div{display: none  !important;}
}

.amigPaginationPageButtonWrapper{
    button{
       span{
           span{
               @extend .amig-body1-mobile-regular;
              
           }
       }
   }
}

.amigSelectedPaginationButton{
    color: $color-amig-gray-100 !important;
    background-color: $color-amig-gray-5 !important;
    span{
        font-weight: 700 !important;
    }
}

.amigHeadDivrtrh{
    color: $color-amig-gray-100 !important;
    font-size: 23px !important;
}
.amigrtHead{
    color: $color-amig-blue-100 !important;
    font-size: 23px !important;
}
.amigFieldIconSearchWrapper{
    width: 510px !important;
    height: 48px !important;
   
}

.amigDataTableSearch{
    width: 510px !important;
    height: 48px !important;
    border-radius: 2px;
    background-color: $color-amig-gray-5;
    margin-left: -15px !important;
}

.amigFilterSearchBar{
    margin-left: -15px !important;
}

.amigDataTableHeaderOnelIne{
    &:focus {
        text-decoration: none !important;
    }
    &:active{
        text-decoration: none !important;
    }
}

.amigDivrtth{
    text-decoration: none !important;
}

.amig_Data_Table{
    font-size: 24px !important;
}

.amigData_Table{
    font-size: 16px !important;
}

.amigData_table_noDataMessage{ 
    @extend .amig-arial-font;
    color: $color-amig-gray-80 !important;
    padding: 37.5px 8px 37.5px 8px !important;
}

.amigDataTableBar{
   background-color: $color-amig-white !important;
}

.amigFieldWithIcon{
    @extend .amig-body2-web-regular;
    width: 510px;
    height: 48px;
    border-radius: 2px;
    background-color: $color-amig-gray-5;
    
    @include gw-breakpoint-down(tablet) {
        width: calc(100vw - 240px);
        max-width: 592px;
        font-size: 14px;
    }
     
}

.amigManageFiltersButton{
    width: 124px !important;
    height: 48px !important;
    position: absolute;
    right: 130px;
    background: $color-amig-blue-100 !important;
    span{
        @extend .amig-body2-web-regular;
        color: $color-amig-white !important;
    }
    @include gw-breakpoint-down(tablet) {
        right: 40px !important;
    }
    @extend.primary-btn-states;
}

.amigManageFiltersSelectedButton {
    background: $color-amig-lightBlue-100  !important;
}

.amigSelectControl{
    width: 200px;
    height: 48px !important;
    border-radius: 2px !important;
    @include gw-breakpoint-down(tablet) {
        width: 164px;
    }   
}

.amigSelectControlPlaceholder{
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-60 !important;
    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
    }
}

.amigCollapseShow{
    width: 100%;
    margin-bottom: 24px;
}

.amigFieldLabelPrimaryLabel{
    color: $color-amig-gray-100;
}

.amigFilterBar {
    background-color: $color-amig-blue-30 !important;
    >div {
        flex-wrap: wrap !important;
    }
}

.amigReactDatePickerWrapper{
    width: 200px !important;
    height: 48px !important;
    border-radius: 2px !important;
    @include gw-breakpoint-down(tablet) {
        width: 164px !important;
    }
}

.amigFieldIcon{
    color: $color-amig-gray-100;
    left: auto !important;
    right: 2.5rem;
    @include gw-breakpoint-down(tablet) {
        right: 0.5rem;
    }
}

.amigDatePicker{
    height: 48px !important;
    border-radius: 2px !important;
}

.amigDatePicker::placeholder {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100 !important;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
    }
}

.amigDataTableBody{
    border: none !important;
}

.amigReactDatePicker{
    .div{
    width: 312px !important;
    height: 294px !important;
    }
}

.amigReactDatePickerDay{
    @extend .amig-caption-web-regular;
}

.amigReactDatePickerDayName{
    @extend .amig-caption-web-bold;
}

.amigDatePickerFieldControl{
    width: 122px !important;
    height: 32px !important;
    border-radius: 2px !important;
}

.amigDatePickerFieldTodayButton{
    @extend .amig-body2-web-bold;
    color: $color-amig-blue-90;
}

.amigDataTable{
    border: none !important;   
}

.amigDataTableCell{
    box-shadow: none !important;
    padding: 0px !important;
}

.amigTableHeaderCell{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.amigFiltertitle{
    @extend .amig-body2-web-bold;
}

.amiggwWrap{
    flex-wrap: nowrap !important;
}

.amigfilterItem{
    @include gw-breakpoint-down(tablet) {
    min-width: unset !important;
    }
}

.amigFilterBar{
    button{
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $color-amig-blue-100 !important;
        &:hover, &:disabled, &:focus{
            background-color: transparent !important; 
            box-shadow: none !important;
        }
        span{
            @extend .amig-body1-mobile-bold;
        }
    }
}

.amigFlexgwDirectionRow{
    margin-left: 0px;
    margin-right: 12px;
}