.amigMain {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 80px;
    @include gw-breakpoint-down(tablet) {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 24px;
    }
}

.amigMainContainer {
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
}

.amigMainContainer figure {
    display:block !important;
}

.amigIframeContainer {
    padding: unset !important;
}

.amigContainer{
    &:before, &:after{
        margin:unset !important
    }
}