@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.policyDocDelivery {
    margin: 24px 0px;
}

.notificationsSection {
    margin-top: 16.5px;

    @include gw-breakpoint-down(phone) {
        margin-bottom: 24px !important;
    }
}

.notificationsSectionDelivery {
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    @media (max-width: 680px) {
        margin-bottom: 24px !important;
    }
}

.labelColumn {
    @extend .amig-h6-web-text;
    color: $color-amig-gray-100 !important;
    display: inline-block;
    margin-bottom: 1.5rem ;
    margin-top: 18px;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    @media (max-width: 680px) {
        display: block;
        margin-bottom: 4px;
        margin-top: 0px;
    }
}

.timeZoneSelector {
    padding-bottom:0px
}

.labelColumnInputSection {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100 !important;
    display: inline-block;
    margin-bottom: 1.5rem;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    @include gw-breakpoint-down(phone) {
        margin-bottom: 0rem;
    }
}

.timeZone {
    @extend .amig-h6-web-text;
    color: $color-amig-gray-100 !important;
    width: 300px;
    display: inline-block;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    @include gw-breakpoint-down(phone) {
        margin-bottom: 0rem;
    }
}

.notificationsLabel {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    margin-left: 76px;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
    }

    @media (max-width: 680px) {
        margin-left: 0px;
    }
}

.notificationsDetails {
    display: inline-block;
}

.paperlessTnC {
    @extend .amig-body2-web-regular;
    display: inline !important;
    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.paperlessLabel {
    display: inline;
    margin-right: 3px;
    color: $color-amig-gray-80;
}

.TncLink {
    @extend .text-links-states;
}

.editNotifications {
    margin-left: 16px;
    @extend .text-link-medium;
    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.selectTimeZone {
    width: 100%;
}


.docFormContainer {
    margin-left: 76px;
    margin-top: -27px;
    margin-left: 298px;

    @media (max-width: 680px) {
        margin-left: 0px;
    }
}

.deliveryMethodText {
    @extend .amig-caption-web-bold;
    margin-bottom: 4px;
    @include gw-breakpoint-down(tablet) {
        font-size: 12px;
        line-height: 14px;
    }
}

.cancelButton {
    @extend .btn-secondary-medium;
    margin-top: 22px;
}

.saveChangesButton {
    @extend .btn-primary-medium;
    margin-left: 24px;
    margin-top: 22px
}

.notificationOptions {
    margin: 28px 0px 22px 0px!important;
   span {
        @extend .amig-body2-web-regular;
        color: $color-amig-gray-80;
    }
}

.disabledEmailOptions {
 @extend .notificationOptions;   
 div[aria-label*='Email'] {
    opacity: 0.4 !important;
    cursor: default;
    span{
        cursor: default;
        background-color: white;
        &:hover{
            background-color: white !important; 
        }
    }
  }
}

.timeZoneRow {
    display: inline-flex !important;
    margin-top: 17.5px;

    @media (max-width: 680px) {
        display: unset !important;
    }

    div[class*='jut__GenericSelectControl__control'] {
        width: 360px; 
        @extend .amig-body2-web-regular;   
        @include gw-breakpoint-down(tablet) {
            font-size: 14px;
        }   
        @media (max-width: 730px) {
            width: 230px !important;
            
        }
        @include gw-breakpoint-down(phone) {
            width: 286px !important;
        }
    }
}

.timeZoneSelector {
    >div {
        height: 48px !important;
        width: 360px !important;
        border-radius: 0px !important;
        margin-bottom: -20px !important;

        >label {
            @extend .amig-caption-web-bold;
            
            >span {
                @extend .amig-caption-web-bold;
            }
        }

        @include gw-breakpoint-down(tablet) {
            >label {
                font-size: 12px !important;
                line-height: 14px !important;
                
                >span {
                    font-size: 12px !important;
                    line-height: 14px !important;
                }
            }
        }
        @media (max-width: 730px) {
            width: 254px !important;
            
        }
        @include gw-breakpoint-down(phone) {
            width: 286px !important;
        }
    }
    div[class*=' css-1hb7zxy-IndicatorsContainer'] {
        z-index: 0;
    
      }
      div[class*='jut__GenericSelectControl__option'] {
      
        @extend .amig-body2-web-regular;   
        @include gw-breakpoint-down(tablet) {
            font-size: 14px !important;
        }   
       
    }
}

.accountDelivery {
    @extend .amig-h6-web-text;
    margin: 60px 0px -24px -300px !important;
    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px;
    }
    @include gw-breakpoint-down(phone) {
        margin: 60px 0 0 !important;
    }
}

.warningMessage{
    @extend .alert-sectional-notification;
    margin-left: -300px;
    width: 925px; 
    margin-top: 0px;

    @include gw-breakpoint-down(tablet) {
        width: calc(100vw - 106px);
    }
    @media (max-width: 680px) {
        margin-left: 0px;
        width: calc(100vw - 80px);
    }      
                     
    }

.closeWarningMessage {
   
    margin: 5px 0px 0px -27.5px;
    z-index: 0;
    height: 24px;
    width: 24px;

    i{
           color: $color-amig-gray-100 !important;
    }

    &:active,
    &:hover,
    &:focus {
        background: none;
        box-shadow: none;
    }
}

.showWarningMessage {
    display: inline-flex;   
}

.spinner {
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width:128px;
    height:128px;
}