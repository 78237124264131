.amigAvatar {
    --GW-AVATAR-SIZE: 40px;
    background-color:#31769B;
    border-radius:0%;
    &:hover {
        background-color:#5A91AF;
        box-shadow: 0 0 0 0 #5A91AF;
    }
    --GW-AVATAR-BACKGROUND-COLOR-HOVER:#5A91AF;
    --GW-AVATAR-BACKGROUND-COLOR-ACTIVE:#5A91AF;
    &:focus {
        box-shadow: 0 0 0 0 #5A91AF;
    }
    
}
.amigAvatarOverlay {
}





