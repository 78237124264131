@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.claim-card-label {
    @extend .amig-h6-web-text;
    color: $color-amig-gray-100;
}

.claim-card {
    margin: 30px 0px 18px;
    height: 564px;
    padding: 40px 72px 40px 72px !important;
    position: relative;
    background: $color-amig-white;
    /* Shadow/Base */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-left: 2px solid $color-amig-gray-10;
    @media (max-width: 1120px) {
        padding: 40px 50px 40px 50px !important;
    }
    @media (max-width: 1046px) {
        display: none;
    }
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.progress-bar{
    height: 32px;
    border-radius: 2px;
    background-color: $color-amig-gray-10;
}

.progress-bar-step{
    height: 32px;
    border-radius: 2px;
    background-color: $color-amig-blue-100 !important;
}

.claim-status {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px 0px 0px !important;

    @include gw-breakpoint-down(tablet) {
		margin-bottom: 8px !important;
	}
}

.claim-title {  
    @extend .amig-body2-web-regular;  
    color: $color-amig-gray-100;    
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px 8px 0px;
}


.claim-status-text {
    color: $color-amig-green-100 !important;
    @include gw-breakpoint-down(tablet) {
		margin-bottom: 8px !important;
	}
}


.claim-status-subtext {
    @extend .amig-body2-web-regular;  
    color: $color-amig-gray-80;    
}

.claim-card-row-1 {
    margin-top: 40px;
    margin-bottom: 32px;
    @include gw-breakpoint-down(tablet) {
        margin-top: 24px;
        margin-bottom: 0px;
    }
    @include gw-breakpoint-down(phone) {
        margin-top: 24px;
        margin-bottom: 32px;
    }
}

.claim-issued-payments {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100 !important;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 40px !important;

    @include gw-breakpoint-down(tablet) {
		margin-top: unset;
        margin-bottom: 0px !important;
	}
    
    @media (max-width: 769px) { 
        margin-bottom: 24px !important;
    }
}

.table-font{
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100 !important;   
    span {
        font: inherit; 
    }
}


.word-wrap {
    white-space: normal !important;
  }

.rowStyleBorder {
    @extend .word-wrap;
    box-shadow: inset 0px -2px 0px $color-amig-gray-10 !important;
    padding: 24px 0px !important;
    height: 100%;
    span {
      font-family: Arial !important;
    }
  }




.claim-card-column {
    gap: 0 !important;
}

.claim-header {
    color: $color-amig-gray-100 !important;   
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 64px 0px 28px 0px !important;
}

.claim-minified-header {
    margin: 24px 0px 0px 0px !important;
}
