.amigModal {

    border-radius: 2px;
    padding: 40px;
    width: calc(100vw - 32px);
    max-width: 676px;

    @include gw-breakpoint-down(phone) {
         padding: 40px 24px;
    }

    .jut__ModalHeader__iconWarning {
        display: none;
    }

    .jut__ModalHeader__modalWarningStatus {
        display: none;
    }
    .jut__ModalHeader__modalHeader {
        padding: 0;
        display: contents;
    }

    .jut__ModalHeader__modalHeaderLayout {
        padding: 0;
    }

    .jut__ModalHeader__title {
        @extend .amig-h3-web-text;       
    }    

    .jut__ModalBody__modalBody {
        margin: 32px 0px;
        padding: 0;
        @extend .amig-body2-web-regular;
    }

    .jut__ModalFooter__modalFooter {
        background-color: $color-amig-white;
        padding: 0;
    }

}