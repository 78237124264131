@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.policyContactsDetailGridValues {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    @include gw-breakpoint-down(tablet) {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    @include gw-breakpoint-down(phone) {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.right-aligned {
    text-align: right !important;
    @include gw-breakpoint-down(tablet) {
        text-align: left !important;
    }
}

.policyDetailTableTitle {
    @extend .amig-h6-web-text;
    padding-right: 8px;
    height: 40px !important;
    padding-top: 8px;
    color: $color-amig-gray-100;
    @include gw-breakpoint-down(tablet) {
        height: unset !important;
        display: none;
    }
}

.policyContactDetailBorder {
    margin-top: 0px !important;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
    @include gw-breakpoint-down(phone) {
        display: block;
        margin-top: 24px !important;
    }
}

.policyContactsDetailGridTitles {
    color: $color-amig-gray-100;
    @extend .amig-h6-web-text;
    margin-bottom: 8px;
    margin-top: 24px;
    display: none;
    @include gw-breakpoint-down(tablet) {
        display: block;
    }
}

.policyContactsDetailUpperBorder {
    display: none;
    @include gw-breakpoint-down(tablet) {
        display: block;
        margin-top: 24px !important;
    }
    @include gw-breakpoint-down(phone) {
        display: none;
    }
}

.showForTablet {
    display: none;
    @include gw-breakpoint-down(tablet) {
        display: block;
    }
    @include gw-breakpoint-down(phone) {
        display: none;
    }
}

.policyContactsValue {
    padding-top: 24px;
    @include gw-breakpoint-down(tablet) {
        height: unset !important;
        padding-top: unset !important;
        grid-template-columns: 1fr !important;
    }
}

.addressPadding{
    padding-bottom: 12px;
    @include gw-breakpoint-down(tablet) {       
        padding-bottom: unset;
    }
}

.notificationMargin {
    margin-top: 16px;
    margin-bottom: 32px;
  @include gw-breakpoint-down(tablet)  {
    margin-top: 8px;
    margin-bottom: 16px;
}
}
  

