.amigRadioButton{
   &::before{
    box-shadow: 0px 0px 0px 1px $color-amig-gray-100; 
    height: 24px;
    width: 24px;
   }
    border-color: $color-amig-white;
    margin-right: 12px;
    
}

.amigActiveRadioButton{
    border: calc(0.25 * var(--GW-RADIO-BUTTON-SIZE)) solid var(--GW-SELECTOR-SELECTED-BACKGROUND-COLOR);
    background: $color-amig-blue-100;
    border-color: $color-amig-white;
    box-shadow: 0px 0px 0px 1px $color-amig-blue-100;
    margin-right: 12px;
}