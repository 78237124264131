@import "~amig-digital-theme-styles/styles/amig-typography.scss";

.amigDropdownMenuAvatarHeaderText {
    padding-left: 15px;
}
.amigDropdownMenuAvatarHeaderTitle {
   @extend .amig-h5-web-text;
    margin: 4px !important;
    letter-spacing: 0.2px;
}

.amigDropdownMenuAvatarMenu {
    top: calc(100% + 26px);
    @include gw-breakpoint-down(tablet) { 
        top: calc(100% + 10px);
    }

    @include gw-breakpoint-down(phone) { 
        top: calc(100% + 26px);
    }
}