:root {
    --gw-agent-container-margin:0 auto;
    --gw-agent-container-max-width: 90em;
    --gw-contactagent-phoneicon-height: 6em;
    --gw-contactagent-phoneicon-width:6em;
    --gw-contactagent-phoneicon-margin:var(--GW-SPACING-8) auto;
    --gw-agent-title-margin-bottom: calc(1.5 * var(--GW-SPACING-4));
    --gw-agent-number-margin: var(--GW-SPACING-1);
}

.agentContainer {
    margin: var(--gw-agent-container-margin);
    max-width: var(--gw-agent-container-max-width);

    .contactAgentPhoneIcon {
        height: var(--gw-contactagent-phoneicon-height);
        width: var(--gw-contactagent-phoneicon-width);
        line-height: var(--GW-LINE-HEIGHT-H1);
        color: var(--GW-COLOR-SUCCESS);
        background-color: var(--GW-HIGHLIGHT-COLOR-1);
        border-radius: 50%;
        margin: var(--GW-SPACING-8) auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .iconPhone {
        line-height: inherit;
        font-size: var(--GW-FONT-SIZE-H1);
    }

    .agentTitle {
        color: var(--GW-TEXT-COLOR-1);
        font-size: var(--GW-FONT-SIZE-H1);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        line-height: var(--GW-LINE-HEIGHT-H1);
        text-align: center;
        margin-bottom: var(--gw-agent-title-margin-bottom);
    }

    .buttonLinkStyleRemove {
        text-decoration: none;
        color: var(--GW-COLOR-INFO-CONTRAST);
    }


    .agentNumber {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        margin: var(--gw-agent-number-margin);
    }

    .btnClass {
        text-align: center;
        margin-top: var(--GW-SPACING-2);
    }

    .linkStyleRemove {
        text-decoration: none;
        color: var(--GW-LINK-COLOR);
        cursor: pointer;
    }

    .removeBackgroundcolorLabel {
        background-color: var(--GW-COLOR-INFO-CONTRAST);
    }

    .buttonLinkStyleRemove {
        text-decoration: none;
        color: var(--GW-COLOR-INFO-CONTRAST);
    }

    .contactAgentPara {
        text-align: center;
    }

    .contactAgentParaMargin {
        margin-bottom: 0;
    }

    .claimReferencePara {
        color: var(--GW-TEXT-COLOR-1);
        text-align: center;
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .back {
        text-align: center;
        margin-top: var(--GW-SPACING-2);
    }
}
.callOurAgent {
    text-align: center;
}


