
.gridPaddingLeft{
    padding-bottom: var(--grid-padding-bottom);
    grid-template-columns: 0fr 3fr;
}

.checkBoxLabel {
    font-size: var(--GW-FONT-SIZE-SM);
}

.checkBoxChange {
    --gw-padding-top-checkBox: 20px;
    padding-top: var(--gw-padding-top-checkBox);
}
