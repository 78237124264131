@import "~@jutro/theme/assets/sass/helpers";

/*------------------------------------*\
   CUSTOMER COLORS/OVERRIDES (newer ones from customers AMP application)
\*------------------------------------*/
//$base-text-color: #999;

//Primary colors
$color-amig-blue-100: #31769b;
$color-amig-blue-90: #00739D;
$color-amig-blue-80: #5a91af;
$color-amig-blue-60: #83adc3;
$color-amig-blue-30: #F0F3F6;
$color-amig-blue-40: #adc8d7;
$color-amig-blue-20: #d6e4eb;
$color-amig-blue-10: #eaf1f5;
$color-amig-blue-5: #f5f8fa;

//Primary neutrals
$color-amig-gray-100: #282828;
$color-amig-gray-80: #535353;
$color-amig-gray-60: #7e7e7e;
$color-amig-gray-40: #a9a9a9;
$color-amig-gray-20: #d4d4d4;
$color-amig-gray-10: #eaeaea;
$color-amig-gray-5: #f4f4f4;
$color-amig-black: #000000;
$color-amig-white: #ffffff;

//Secondary colors
$color-amig-lightPurple-100: #625aab;
$color-amig-lightPurple-80: #817bbc;
$color-amig-lightPurple-60: #a19ccd;
$color-amig-lightPurple-40: #c0bddd;
$color-amig-lightPurple-20: #e0deee;
$color-amig-lightPurple-10: #efeff7;
$color-amig-lightPurple-5: #f7f7fb;

$color-amig-lightBlue-100: #3f94c3;
$color-amig-lightBlue-80: #65a9cf;
$color-amig-lightBlue-60: #8cbfdb;
$color-amig-lightBlue-40: #b2d4e7;
$color-amig-lightBlue-20: #d9eaf3;
$color-amig-lightBlue-10: #ecf4f9;
$color-amig-lightBlue-5: #f5fafc;

$color-amig-purple-100: #2d0c6d;
$color-amig-purple-80: #573d8a;
$color-amig-purple-60: #816da7;
$color-amig-purple-40: #ab9ec5;
$color-amig-purple-20: #d5cee2;
$color-amig-purple-10: #eae7f0;
$color-amig-purple-5: #f5f3f8;

$color-amig-green-100: #427d26;
$color-amig-green-80: #689751;
$color-amig-green-60: #8eb17d;
$color-amig-green-40: #b3cba8;
$color-amig-green-20: #d9e5d4;
$color-amig-green-10: #ecf2e9;
$color-amig-green-5: #f6f9f4;

$color-amig-lightGreen-100: #74a433;
$color-amig-lightGreen-80: #90b65c;
$color-amig-lightGreen-60: #acc885;
$color-amig-lightGreen-40: #c7dbad;
$color-amig-lightGreen-20: #e3edd6;
$color-amig-lightGreen-10: #f1f6eb;
$color-amig-lightGreen-5: #f8faf5;

//Tertiary colors
$color-amig-berry-100: #9f237a;
$color-amig-berry-80: #b24f95;
$color-amig-berry-60: #c57baf;
$color-amig-berry-40: #d9a7ca;
$color-amig-berry-20: #ecd3e4;
$color-amig-berry-10: #f5e9f2;
$color-amig-berry-5: #faf4f8;

$color-amig-navy-100: #04315b;
$color-amig-navy-80: #365a7c;
$color-amig-navy-60: #68839d;
$color-amig-navy-40: #9badbd;
$color-amig-navy-20: #cdd6de;
$color-amig-navy-10: #e6eaef;
$color-amig-navy-5: #f2f5f7;

$color-amig-gray-custom: #797979;

//Accent colors
$color-amig-grana: #90293f;
$color-amig-orange: #eb5124;
$color-amig-mustard: #e8af22;
$color-amig-aquamarine: #26b8ad;
$color-amig-teal: #077f91;

//semantic colors
$color-amig-negative-100: #b00020;
$color-amig-negative-200: #8f001a;
$color-amig-negative-40: #df99a6;
$color-amig-negative-10: #ffe7ec;

$color-amig-warning-100: #ffb900;
$color-amig-warning-200: #e6a600;
$color-amig-warning-40: #ffe399;
$color-amig-warning-10: #fffbe7;

$color-amig-positive-100: #237b4b;
$color-amig-positive-200: #1c613b;
$color-amig-positive-40: #a7cab7;
$color-amig-positive-10: #ebfbe6;

// newly added
$color-amig-yellow: #fddb00;

$color-amig-gray-0: #313438;
$color-amig-gray-1: #605f5b;
$color-amig-gray-2: #908f8c;
$color-amig-gray-3: #b0afad;
$color-amig-gray-4: #cfcfce;
$color-amig-gray-6: #efefef;
$color-amig-gray-7: #f6f6f6;

$color-amig-gray-8: #49494c;
$color-amig-gray-9: #3b3b3b;
$color-amig-gray-11: #75787b;
$color-amig-gray-13: #b4b4b4;
$color-amig-gray-15: #c3c8c9;
$color-amig-gray-16: #8a8c8d;

//Popover
$color-popover-background: #dedede;

//== Form states and alerts

$state-success-text: #006600;
$state-success-bg: #dff0d7;

$state-info-text: #31708f;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: #8a6d3b;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: #cc0000;
$state-danger-bg: #f2dede;
