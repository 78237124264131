@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';


.right-aligned {
    text-align: right !important;
}

.left-aligned {
    text-align: left !important;
}

.payment-history-entry {
    display: contents;

    @media (max-width:768px) {
        display: block;
        margin-top:16px;
    }
}

.paymentHistoryDetailValue {
    @media (max-width:768px) {	
        grid-template-columns: 11fr 0fr !important;	
    }

}
.paymentHistoryGridValues {
    @extend .amig-body1-mobile-regular;
    color: $color-amig-gray-80;
    display: block;

    @media (max-width:768px) {
        text-align: right !important;
    }

    >div {
        >div {
            padding: 24px 0px;
        };

        @media (max-width:768px) {
            >div {
                padding: 0px 0px 0px 0px;
            };
            width: 50% !important;
            margin: 0px;
            display: inline-block;
        }
    }
}

.paymentHistoryGridTitles {
    display: none;
    @extend .amig-h4-mobile-text;
    margin-bottom: 8px;
    margin-top: 24px !important;

    @media (max-width:768px) {
        display: block;
        text-align: left !important;
        margin-bottom: 8px !important;
        margin-top: 8px !important;
    }
}

.payment_chevron {
    background: none;
    color: $color-amig-blue-100;
    box-shadow: none;
    width: 24px;
    height: 24px;
    margin: 22px 0px 0px 12px;
    padding: 0px;

    &:active,
    &:focus,
    &:hover {
        -webkit-tap-highlight-color: transparent;
        background: none !important;
        color: $color-amig-blue-100;
        box-shadow: none;
    }

    display: none;

    @media (max-width:768px) {
        display: block;
        margin: 16px 0px 0px 12px;
    }

    @include gw-breakpoint-down(phone) {
        margin: 22px 0px 0px 12px;
    }
}

.chevron-flip {
    transform: rotate(180deg);
}

.displayDetails {
    display: contents;

    @media (max-width:768px) {
        display: block;
    }
}

.hideDetails {
    display: contents;

    @media (max-width:768px) {
        display: none;
    }
}

.rejected{
    @extend .amig-body1-mobile-regular;
    color: $color-amig-negative-100;
    display: block;

    @media (max-width:768px) {
        text-align: right !important;
    }

    >div {
        >div {
            padding: 24px 0px;
        };

        @media (max-width:768px) {
            >div {
                padding: 0px 0px 0px 0px;
            };
            width: 50% !important;
            margin: 0px;
            display: inline-block;
        }
    }
}

.paymentReceiptLabel{
    display: inline-block;
}

.viewDetailsLink {
    @extend .amig-body1-mobile-regular;
    @extend .text-links-states;
    display: inline-block;
    float: right;
    margin: 8px 0px;   
    cursor: pointer;
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}
