@import "~@jutro/theme/assets/sass/helpers";
@import "~amig-digital-theme-styles/styles/core";

.account-profile-titles {  
  margin-bottom: 16px !important;
  margin-top: 40px !important;
  color: $color-amig-gray-100 !important;
  @include gw-breakpoint-down(tablet) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  @include gw-breakpoint-down(tablet) {
    margin-top: 24px;
  }
}

.account-profile-label {
  @extend .amig-h6-web-text;
  color: $color-amig-gray-100;
  @include gw-breakpoint-down(tablet) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

.account-profile-input {
  @extend .amig-body2-web-regular;
  color: $color-amig-gray-80;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  @include gw-breakpoint-down(tablet) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

.desktop-only {
  display: block;

  @media (max-width: 688px) {
    display: none !important;
  }
}

.mobile-only {
  display: none;

  @media (max-width: 688px) {
    display: block;
    width: 100%;
  }
}

.left-section {
  float: left;
  width: 300px;

  @media (max-width: 688px) {
    display: inline-block;
    vertical-align: top;
    width: 32ch;
    margin: 0 !important;
    float: none;
  }
}

.right-section {
  display: inline-flex;
}

.customerServiceContact {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 24px;
  margin-top: 28px;
  background-color: $color-amig-lightBlue-10;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  @include gw-breakpoint-down(tablet) {
    margin-top: 14px !important;
  }

  @include gw-breakpoint-down(phone) {
    left: 0px;
    top: 0px;
    margin-top: 12px;
  }
}

.customerServiceContactText {
  display: table-cell;
  color: $color-amig-gray-100; 
  @extend .amig-body2-web-regular ;
  @include gw-breakpoint-down(phone) {
    width: 120px;
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-16-desktop {
  margin-bottom: 16px;
  @include gw-breakpoint-down(phone) {
    margin-bottom: 0;
  }
}


.left-float {
  float: left;
}

.mb-email-label {
  margin-bottom: 24px;
  @include gw-breakpoint-down(tablet) {
    margin-bottom: 20px;
  }
  @include gw-breakpoint-down(phone) {
    margin-bottom: 0px;
  }
}

.mb-email-input {
  margin-bottom: 24px;
  @include gw-breakpoint-down(tablet) {
    margin-bottom: 20px;
  }
}

.editPhone {
  @extend .text-link-medium;
  display: inline-flex;
  margin-left: 24px;

@include gw-breakpoint-down(tablet) {
  font-size: 14px;
  line-height: 20px;
  margin-left: 16px;
}

@media (max-width: 688px) {
  vertical-align: bottom;
  margin-left: 0 !important;
}

@media (max-width: 389px) { 
  display: table !important;
  margin-left: 0px;
  }
}

.mt-custom{
  margin-top: 28px !important;
  @include gw-breakpoint-down(tablet) {
    margin-top: 8px !important;
  }
}
