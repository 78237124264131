@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.burger-custom-content {
    background: $color-amig-white !important;
    height: calc(100vh - 172px) !important;   
    position: relative;
    @include gw-breakpoint-down(tablet) {      
        height: calc(100vh - 96px) !important;
    }       
    >div{
        position: absolute;
        bottom: 0;
        height: calc(100vh - 172px) !important;
        width: 100%; 
        @include gw-breakpoint-down(tablet) {            
             height: calc(100vh - 96px) !important;            
         }      
    }
}