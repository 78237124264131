.amig_mi-check{
    position: inherit !important;
    font-size: 14px !important; 
    color: $color-amig-blue-100;
}

.amig_jut_CheckboxField{
    background-color: $color-amig-white;
    box-shadow: none !important;
}

.amig_CheckboxElement{
    background-color: $color-amig-white !important;
}