@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.contactUs-wrapper{
    margin: 32px 23px 32px 32px;
    width: 318px;
    @include gw-breakpoint-down(tablet) {
        margin: 32px 16px 32px 16px;
        width: calc(100vw - 48px);
    }
}

.amigSupport {
    line-height: 32px !important;
    margin-bottom: 32px !important;
    color: $color-amig-gray-100 !important;
    @extend .amig-h2-mobile-text;
    @include gw-breakpoint-down(tablet) {
        font-size: 24px !important;
        margin-bottom: 20px !important;
    }
}

.amigCustomerServiceTime {
    @extend .amig-body2-mobile-regular;
    line-height: 24px !important;
    margin-bottom: 24px !important;
    color: $color-amig-gray-80 !important;
    @include gw-breakpoint-down(tablet) { 
        margin-bottom: 32px !important;
    }
    @include gw-breakpoint-down(phone) {        
        font-size: 16px !important;
        margin-bottom: 32px !important;
    }
   
}

.supportDetailsDiv { 
    figure{
        display: inline-block;
        margin: unset;
        @include gw-breakpoint-down(tablet) {
            background-color: $color-amig-lightPurple-100 !important;
            border-radius: 50%;
            height: 32px !important;
            width: 32px;
            
        }
    } 
    margin-bottom: 24px; 
    @include gw-breakpoint-down(tablet) {
        display: flex;        
    }
    
}

.amigSupportLogo {
    @include gw-breakpoint-down(tablet) {
        position: static;
        height: 16px !important;
        width: 16px;
        margin-left: 8px;
        margin-top: 8px;
    }
}

.amigSupportPhoneLogo{
    @extend .amigSupportLogo;
}

.amigSupportEmailLogo{
    @extend .amigSupportLogo;
}

.amigSupportDetails{
    
    margin-left: 4px;
    vertical-align: middle;
    display: inline-block;
    @include gw-breakpoint-down(tablet) {
        margin-left: 16px;
    }
}

.amigSupportPhone {    
    @extend .amigSupportDetails;
    @extend .purple-links-states;
    @extend .amig-body2-web-bold;
    color: $color-amig-gray-80;
    pointer-events: none;
    @include gw-breakpoint-down(tablet) {       
        color: $color-amig-purple-100;
        pointer-events: unset;        
    }
}

.amigSupportEmail {
    @extend .amigSupportDetails;
    @extend .purple-links-states;
    @extend .amig-h6-web-text;
    letter-spacing: 0.5px !important;
}

.supportSeparator{
    margin: 34px 0px 32px 0px!important;
    @include gw-breakpoint-down(tablet) {
        margin: 40px 0px 32px 0px!important;
    }
    
}

.amigNeedHelp{   
    @extend .amig-h6-web-text;
    margin-bottom: 21px !important;
    color: $color-amig-gray-100 !important;
}