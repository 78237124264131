@import "~@jutro/theme/assets/sass/helpers";
@import "~amig-digital-theme-styles/styles/core";
@import "../../Claims.module.scss";

.pageHeaderId {
  display: inherit;
  margin-bottom: 40px;
  color: $color-amig-gray-100 !important;

  @include gw-breakpoint-down(tablet) {
    display: block;
    font-size: 20px !important;
    margin-bottom: 24px !important;
    line-height: 28px !important;
  }
}

.mobileClaimDiv {
  display: block;
}

.desktopClaimDiv {
  display: inline;
}
 
.file-claim-div-mobile {
  display: none;
  @include gw-breakpoint-down(tablet) {
    display: flex;
    position: sticky;
    bottom: 0;
    background-color: $color-amig-gray-5;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
  }
}

.file-claim-mobile {
  @extend .btn-primary-medium;
  display: none;
  @include gw-breakpoint-down(tablet) {
    display: block;
    margin: 24px 0px 24px 0px !important;
    width: 100% !important;
    box-shadow: none !important;
  }
}

.claim-card {
  margin: 48px 0px;
  padding: 40px 21px 40px 40px;
  background: $color-amig-white;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-left: 6px solid $color-amig-blue-100;
  @include gw-breakpoint-down(tablet) {
    padding: 14px 21px 16px 21px !important;
    margin: 16px 0px;
  }

  @include gw-breakpoint-down(phone) {
    padding: 14px 21px 18px 21px !important;
  }
}

.claimCardMinified {
  height: 20px;
}

.word-wrap {
  white-space: normal !important;
}

.rowStyleBorder {
  @extend .word-wrap;
  box-shadow: inset 0px -2px 0px $color-amig-gray-10 !important;
  padding: 36px 0px !important;
  height: 100%;
  span {
      font-family: Arial !important;
      width: 85%;
      display: inline-block;
    }
}

.linkCell {
  @extend .text-links-states;
  color: $color-amig-blue-100 !important;
  text-decoration: none;
}

.claim-card-view-policy-button {
  @extend .btn-primary-medium;
  margin-top: 32px;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.policy-type-icon-container {
  display: flex !important;
}

.policyAvatar img {
	height: 24px !important;
	width: 24px !important;
	background: $color-amig-blue-100;
}

.policyAvatar {
	height: 48px !important;
	width: 48px !important;
	background-color: $color-amig-blue-100 !important;
	border-radius: 50%;
	pointer-events: none;
  margin-right: 16px;
}

.claim-content {
  display: contents;
}

.policy-card-policy-number {
  @extend .amig-body2-web-bold;
  color: $color-amig-gray-60;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  padding-top: 5px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
  text-decoration: none !important;
  &:hover,
  &:active {
    text-decoration: none !important;
  }
  @include gw-breakpoint-down(tablet) {
    font-size: 12px;
    font-weight: 400;
  }
}

.policy-card-policy-type {
  @extend .amig-h3-web-text;
  color: $color-amig-gray-100 !important;
  margin-right: 16px !important;
  margin-top: 16px !important;
  text-decoration: none !important;
  display: inline-block;
  &:hover,
  &:active {
    text-decoration: none !important;
  }
  @include gw-breakpoint-down(tablet) {
    margin-top: 12px !important;
    font-size: 16px !important;
    margin-right: 0px;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.copy-content-container {
  display: inline-block;
  height: 24px;
  pointer-events: all !important;

  @include gw-breakpoint-down(tablet) {
    display: none !important;
  }
}

.copy-content {
  height: 24px;
  width: 24px;
}

.claim-btn-regular {
  @extend .btn-primary-medium;
  float: right;
}

.claimsHeaderDiv {
  display: inline;
}

.toggle-card-wrapper {
  display: contents;

  figure {
    margin: 0px !important;
    display: inline-block !important;
    float: right !important;
  }
}

.displayDetails {
  display: contents;

  @include gw-breakpoint-down(tablet) {
    display: block;
    margin-top: 20px;
  }
}

.hideDetails {
  display: contents;

  @include gw-breakpoint-down(tablet) {
    display: none;
  }
}

.policy-status {
  @extend .amig-h4-web-text;
  color: $color-amig-gray-40;
  margin-top: 9px;
  @include gw-breakpoint-down(tablet) {
    margin-top: 13px;
    font-size: 16px;
    line-height: 24px;
  }
}

.toggle-card-wrapper {
  display: contents;
  figure {
    margin: 0px !important;
    display: inline-block !important;
    float: right !important;
  }
}

.policy-card-type-and-number-container {
  margin-bottom: 24px;
  @include gw-breakpoint-down(tablet) {
    margin-bottom: 8px;
  }
  @include gw-breakpoint-down(tablet) {
    margin-bottom: unset;
  }
}

.contactAgentDiv{
  @extend .amig-body2-web-regular;
  color: $color-amig-gray-80;
  margin-top: 18px;
}

.contactAgentPhoneLink{
  @extend .text-link-regular;
  display: contents !important;
  color: $color-amig-gray-80;
  pointer-events: none;
  text-decoration: none;
  @include gw-breakpoint-down(tablet) {
    color: $color-amig-blue-100;
    pointer-events: unset;
  }  
}