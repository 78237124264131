@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.right-aligned {
    text-align: right !important;
}

.left-aligned {
    text-align: left !important;
}

.payment-history-content {
    @include gw-breakpoint-down(phone) {
        margin-top: 16px !important;
    }
}

.rowStyleBorder{   
    box-shadow: inset 0px -2px 0px $color-amig-gray-10 !important;    
    padding: 36px 0px !important;
    height: 100%;
}

.rowStyle{ 
    padding: 36px 0px !important;
}

.rejected{    
    @extend .amig-body2-web-regular;
    color: $color-amig-negative-100 !important;
    display: block;

    @include gw-breakpoint-down(phone) {
        text-align: right !important;
    }

    >div {
        >div {
            padding: 24px 0px;
        };

        @include gw-breakpoint-down(phone) {
            >div {
                padding: 0px 0px 0px 0px;
            };
            width: 50% !important;
            margin: 0px;
            display: inline-block;
        }
    }
}

.payment-history-iterator {
    display:none;
    @media (max-width:768px) {
        display:block;
    }
} 

.payment-history-div {
    display:none;
    @media (min-width:769px) {
        display:block;
    }
} 

.paymentHistoryGridValues {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    display: block;

    @include gw-breakpoint-down(phone) {
        text-align: right !important;
    }

    >div {
        >div {
            padding: 24px 0px;
        };

        @include gw-breakpoint-down(phone) {
            >div {
                padding: 0px 0px 0px 0px;
            };
            width: 50% !important;
            margin: 0px;
            display: inline-block;
        }
    }
}

.paymentSeparator {
    margin: 0px !important;
    @include gw-breakpoint-down(phone) {
        display: block;
        margin: 10px 0px 16px 0px !important;
    }
}

.paymentHistoryViewDetails {
    @extend .text-links-states;
    @extend .amig-body2-web-regular;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
}
