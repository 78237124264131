@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';
.policy-card {
    position: relative;
    height: 564px;
    min-width: 720px;
    margin: 30px 0px 18px;
    padding: 40px !important;
    background-color: $color-amig-white !important;
    /* Shadow/Base */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-left: 6px solid $color-amig-blue-100;
    @include gw-breakpoint-down(tablet) {
        height: auto;
        min-width: unset;
        width: calc(100vw - 32px);
        margin: 8px 0px;
        padding: 24px 24px 24px 18px !important;
        display: flex;
        background: url('../../../../applications/account-management/public/branding/amig/blue-chevron-right.png') no-repeat right !important;
        background-color: $color-amig-white !important;
        background-origin: content-box;
        background-position: 98% 32px !important;
    }
    @include gw-breakpoint-down(phone) {
        height: auto;
        margin: 8px 0px;
        padding: 24px 24px 24px 18px !important;
        display: flex;
        background: url('../../../../applications/account-management/public/branding/amig/blue-chevron-right.png') no-repeat right !important;
        background-color: $color-amig-white !important;
        background-origin: content-box;
        background-position: 96% 32px !important;

    }
    button {
        box-shadow: none;
        @include gw-breakpoint-down(phone) {}
        @include gw-breakpoint-down(tablet) {}
    }
}

.policy-card-type-and-number-container {
    @include gw-breakpoint-down(phone) {
        height: 40px;
        margin: 0px;
        padding: 0px;
    }
    @include gw-breakpoint-down(tablet) {}
}

.policy-type-icon-container {
    margin-bottom: -31px;
    @include gw-breakpoint-down(phone) {
        display: inline-flex !important;
        margin-right: 8px;
        margin-bottom: 0px;
    }
    @include gw-breakpoint-down(tablet) {
        display: inline-flex !important;
        margin-right: 8px;
        margin-bottom: 0px;
    }
    figure {
        margin: 0px 0px 0px 0px !important;
        @include gw-breakpoint-down(phone) {}
        @include gw-breakpoint-down(tablet) {}
    }
}

.policy-type-icon-background {
    @extend .btn-circle-default;
    margin-bottom: 16px;
    margin-right: 24px;
    justify-content: center;
    @include gw-breakpoint-down(tablet) {
        margin-right: -48px;
        width: 40px;
        height: 40px;
    }
    @include gw-breakpoint-down(phone) {
        margin-right: -48px
    }
}

.policy-type-icon {
    height: 48px;
    padding: 12px;
    margin-top: -105px;
    margin-right: 24px;
    justify-content: center;
    @include gw-breakpoint-down(tablet) {
        height: 24px;
        position: relative;
        width: 24px;
        padding: 0px;
        top: 60px;
        left: 16px; //justify-content: center;
    }
    @include gw-breakpoint-down(phone) {
        justify-content: center;
    }
}

.policy-type-mobile-chevron-icon {
    pointer-events: none;
    display:inline-block !important;
    width: auto;
    i {
        font-size: 25px !important;
        display: none;
        margin-top: 500px !important;
    }
    @include gw-breakpoint-down(tablet) {
        pointer-events: all;
        box-shadow: none;
        margin-left: 10px;
        display: contents !important;
    }
    @include gw-breakpoint-down(phone) {
        pointer-events: all;
        box-shadow: none;
        margin-left: 10px;
    }
    >span {
        width: 100%;
    }
}

.policy-card-policy-type {   
    margin-right: 16px !important;
    color: $color-amig-gray-100;
    text-decoration: none !important;
    display: inline-block;
    &:hover,
    &:active {
        text-decoration: none !important;
    }
    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
        margin-right: 0px;
    }
    @include gw-breakpoint-down(phone) {
        font-size: 20px;
        margin-right: 0px;
    }
}

.policy-card-policy-number {
    @extend .amig-body2-web-bold;
    color: $color-amig-gray-60;   
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";   
    padding-top: 5px;   
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px;
    text-decoration: none !important;
    &:hover,
    &:active {
        text-decoration: none !important;
    }
    @include gw-breakpoint-down(tablet) {
        font-size: 12px;
        font-weight: 400;
        display: block;
        padding-top: 5px;
        margin-top: -41px;
        padding-left: 49px;
    }
}

.policy-card-info-notification{
    @extend .info-sectional-notification;
     margin-top: 16px;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}
.notificationMargin {
    margin-top: 16px;
    @include gw-breakpoint-down(tablet)  {
        margin-top: 8px;
    }
}

.pastDueAmt{
    display: inline-block;
    margin-left: 6px !important;
    @include gw-breakpoint-down(phone) {
        display: block;
        margin-left: 0px !important;
    }
}


.policy-card-mobile-container {
    width: 100%;
    @include gw-breakpoint-down(phone) {
        margin-left: -6px;
        margin-right: -25px;
    }
    @include gw-breakpoint-down(tablet) {
        margin-left: -6px;
        margin-right: -25px;
    }
}


.policy-card-row-1 {
    margin-top: 40px;
    margin-bottom: 32px;
    @include gw-breakpoint-down(phone) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.policy-card section {
    @include gw-breakpoint-down(tablet) {
        margin-bottom: 0px;
        display: none;
    }
}

.policy-card-view-policy-button {
    @extend .btn-primary-medium;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.viewPolicyContainer{
    position: absolute;
    left: 40px;
    bottom: 40px; 
}

.policy-card-view-policy-button-payment {
    margin-top: 130px !important;
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
        display: none;
    }
}

.policy-card-view-policy-button-payment-due{
    margin-top: 51px !important;
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
        display: none;
    }
}

.policy-card-view-policy-button-nopayment {
    margin-top: 102px !important;
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
        display: none;
    }
}

.policy-card-view-policy-button-notification {
    margin-top: 84px !important;
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
        display: none;
    }
}

.policy-card-view-policy-button-canceled {
    margin-top: 100px !important;
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
        display: none;
    }
}

.policy-card-input-data {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;   
    letter-spacing: 0.5px;
    >div{
        min-width: 224px !important;
    }    
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.policy-card-payment-overdue {
    color: $color-amig-negative-100;
    @extend .amig-body2-web-regular;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.5px;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
    div {
        color: $color-amig-negative-100;
        font-weight: 400 !important;
        font-size: 16px !important;
        letter-spacing: 0.5px;
    }
}

.policy-card-contact {
    @extend .amig-body2-web-regular;
    border-top: 2px solid $color-amig-gray-10;
    margin-top: 40px;
    padding-top: 16px;
    color: $color-amig-gray-80;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.policy-card-contact-phone-number {
    @extend .amig-body2-web-bold;
    color: $color-amig-gray-80;
    font-size: 16px;
    margin-left: -3px !important;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.policy-card-label {
    @extend .amig-h6-web-text;
    color: $color-amig-gray-100;
}

.policy-card-make-a-payment-link {
    @extend .text-link-medium;
    margin-top: 8px;
    span {
        line-height: 24px !important;
    }
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
    i {
        transform: rotate(180deg);
        font-size: 24px !important;
    }
}

.claim-card {
    margin: 30px 0px 18px;
    height: 564px;
    padding: 40px 72px 40px 72px !important;
    position: relative;
    background: $color-amig-white;
    /* Shadow/Base */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-left: 2px solid $color-amig-gray-10;
    @media (max-width: 1120px) {
        padding: 40px 50px 40px 50px !important;
    }
    @media (max-width: 1046px) {
        display: none;
    }
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.claim-card-column {
    gap: 0 !important;
}

.claim-header {
     color: $color-amig-gray-100 !important;   
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 64px 0px 28px 0px !important;
}

.claim-minified-header {
    margin: 24px 0px 0px 0px !important;
}

.active-claim-count {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100;
    flex: none; //order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px 0px 0px;
}

.file-claim-link {
    @extend .text-link-medium;
    line-height: 26px !important;
    width: 115px !important;
    flex: none;
    flex-grow: 0;
    margin: 8px 0px;
    text-decoration: none;
    display: block !important;
    span {
        @extend .text-links-states;
    }
}

.file-claim-link i {
    font-size: 24px !important;
    position: absolute !important;
    transform: rotate(180deg);
}

.file-claim-minified-link {
    margin: 16px 0px !important;
}

.latest-claim-updates {   
    display: block !important;    
    color: $color-amig-gray-100 !important;   
    flex: none;
    flex-grow: 0;
    margin: 32px 0px 0px 0px;
}

.api-error-claim-header {
    @extend .amig-body2-web-bold;
    display: block !important;
    color: $color-amig-gray-100 !important;
    flex: none;
    flex-grow: 0;
    margin: 32px 0px 0px 0px;
}

.claim-content {
    display: contents;
}

.no-claim-message,
.api-error-claim-message {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px 0px 0px !important;
}
.track-claims-message{
    @extend .amig-body2-web-regular;
    color: $color-amig-green-100;
}

.copy-content-container {
    display: inline-block;
    height: 24px;
    pointer-events: all !important;

    @include gw-breakpoint-down(tablet) {
        display: none !important;
    }
}

.copy-content {
    height: 24px;
    width: 24px;
}

.hideTooltip {
    @include gw-breakpoint-down(tablet) {
        >div>div {
            display: none !important;
        }
    }
}

.claimsMessage{
    margin-bottom: 32px !important;
}