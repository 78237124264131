@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

  .amigSidebarHeader{
    position: absolute;
    height: 36px;
    width: 128px;
    left: 16px;
    right: 0px;
   
    @include gw-breakpoint-down(tablet) { 
        position: fixed;
        top: 14px;
    }

    @include gw-breakpoint-down(phone) { 
        top: 30px;
    }
 }

 .mobile-amig-sidebar {
    width:100%; 
    display: flex; 
    justify-content: flex-end;
 }

 .mobile-amig-sidebar > div{
     height: 102%;
     position: fixed;
 }

 .mobile-amig-sidebar figure{
    margin: 0px !important;
}

 .mobile-amig-sidebar {
     a {
         @extend .amig-h4-mobile-text;
         letter-spacing: 0.5px;
         color: $color-amig-gray-80;
         display: flex;
         flex-direction: row;
         align-items: flex-start;
         padding: 16px;
         position: static;
         height: 56px;
         left: 0px;
         top: 0px;
         flex: none;
         order: 0;
         flex-grow: 0;

         @include gw-breakpoint-down(tablet) {
             margin: 4px 16px;
         }

         @include gw-breakpoint-down(phone) {
             margin: 4px 16px;
         }

         &:active,
         &:hover {
             background: $color-amig-gray-5;
             border-radius: 2px;
             color: $color-amig-blue-100;
         }

     }

 }

 .activeMobileHeader {
     background: $color-amig-gray-5;
     border-radius: 2px;
     color: $color-amig-blue-100 !important;
 }

 .mobileLogout{
    @extend .btn-secondary-medium;
    box-sizing: border-box;
    box-shadow: none;

    @include gw-breakpoint-down(tablet) { 
        width: 94%;
        margin: 12px 3% 40px 3%; 
    }

    @include gw-breakpoint-down(phone) {
        width: 90%;
        margin: 12px 5% 40px 5%;
    }  
 }

 .sidebarAvatarSection{
    position: static;   

    @include gw-breakpoint-down(tablet) {        
        margin: 16px 16px 28px 16px;
    }

 }

 .sidebarAvatar{ 
    background: $color-amig-blue-100;
    border-radius: 104px;   
    width: 48px;
    height: 48px;
 }

 .sidebarAvatar img{    
    width: 32px !important;
    height: 32px !important;
 }

 .sidebarSubSection{ 

    width: 100%;    
    left: 0px;  
    bottom: 0px;

    @include gw-breakpoint-down(tablet) {     
        margin-top: 36px;
    }

 }

 .name{ 
    @extend .amig-h4-mobile-text;
    color: $color-amig-gray-100;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 16px;
}

 .amigSeparator{
    margin: var(--GW-SPACING-2) var(--GW-SPACING-0);;
    border: 1px solid $color-amig-gray-10;
    background-color: $color-amig-gray-10;
 }

