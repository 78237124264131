.amigFieldComponent {}
.amigFieldComponentAlignLeft {}
.amigContentContainer {}
.amigFullWidth {}
.amigControlReadOnly {}

.amigInputMaskField {
    color: $color-amig-gray-80;
    font-family: $font-amig-raleway !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px !important;

    @include gw-breakpoint-down(phone) {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}