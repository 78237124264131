@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.gwFaqItemContainer {
  background-color: $color-amig-white;

  div[class*='jut__Accordion__accordionFrame'] {
    border: none;
  }

  div[class*='jut__Accordion__cardTitle'] {
    padding: 0px;
    display: block;
    box-shadow: inset 0px 1px 0px $color-amig-gray-10;
    border: none;
  }

  div[class*='jut__Accordion__cardBody'] {
    margin: -8px 0px 24px 0px;
  }

}

.gwFaqItemLink {
  @extend .text-links-states;
  @extend .amig-body2-web-bold;
  color: $color-amig-blue-100;
  display: inline-block;
  margin-top: 16px !important;
  text-decoration: none !important;

  i{
    font-size: 24px !important;
    position: absolute !important;
    transform: rotate(180deg);
    margin-left: 4px !important;
  }
}

.gwFaqText {
  @extend .amig-body2-web-regular;
  color: $color-amig-gray-80;
  white-space: pre-line;
}

.gwQuestionTitle {
  color: $color-amig-black;
  padding: 16px 0px 24px 0px;
  display: table;
  width: 100%;

  h4 {
    @extend .amig-body2-web-bold;    
    display: inline-block;
    width: 96%;
    color: $color-amig-black;
  }
}

.gwFaqYouTube {
  --gw-faq-youtube-max-width: 400px;
  --gw-faq-youtube-max-height: 224px;
  --gw-faq-youtube-width: 100%;
  --gw-faq-youtube-height: 50vw;

  max-width: var(--gw-faq-youtube-max-width);
  max-height: var(--gw-faq-youtube-max-height);
  width: var(--gw-faq-youtube-width);
  height: var(--gw-faq-youtube-height);
  margin: auto;
  display: block;
}

.gwHighlightedText {
  background-color: $color-amig-warning-100;
}

.faqChevronContainer {
  display: table-cell;
  vertical-align: middle;
}