@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.paymentHistoryModalBg {
    background-color: $color-amig-gray-100;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    opacity: 0.7;
    z-index: 400;
}

.paymentHistoryModalCardContainer {
    position: fixed;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    padding: calc(50vh/2) calc((100vw - 450px)/2) 0;
    z-index: 450;

    @include gw-breakpoint-down(tablet) {
        padding: 20px calc((100vw - 450px)/2) 0;
    }

    @include gw-breakpoint-down(phone) {
        padding: 24px 16px 0;
    }
}

.paymentHistoryModalCard {
    position: relative;
    height: auto;
    width: 450px;
    background: $color-amig-white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    padding: 40px;
    overflow-y: auto;
    margin-bottom: 20px;

    @include gw-breakpoint-down(phone) {
        width: calc(100vw - 32px);
        padding: 40px 24px;
    }
}

.dismiss-modal-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    @media print {
        visibility: hidden;
    }
}

.paymentHistoryPaymentTitle {
    color: $color-amig-black;
}

.paymentDetails {
    margin-top: 24px;

    @include gw-breakpoint-down(phone) {
        margin-top: 16px;
    }
}

.paymentModalTitle {
    @extend .amig-h6-web-text;
    display: inline-block;
    width: 45%;
    color: $color-amig-gray-80;
    vertical-align: top;

    @include gw-breakpoint-down(phone) {
        width: 50%;
    }
}

.paymentModalData {
    @extend .amig-body2-web-regular;
    display: inline-block;
    width: 50%;
    color: $color-amig-gray-80;
    vertical-align: top;
}

.printButton {
    @extend .btn-primary-medium;
    margin: 40px 0px 0px;
    float: right;

    &:focus {
        box-shadow: none;
    }

    @include gw-breakpoint-down(phone) {
        width: 100%;
    }
}

.amigPrintLogo {
    display: none;
    position: absolute;
    right: 20px;
    top: 0;
    width: 150px;
    z-index: 455;
}

.amigPrintTitle {
    display: none;
    @extend .amig-h6-web-text;
    color: $color-amig-gray-80;
    vertical-align: top;
}

.amigPrintSubtitle {
    display: none;
}

.printPage {

    @media print {

        visibility: hidden;
        overflow: hidden;
        height: 100vh;

        main {
            height: 0px;
            overflow: hidden
        }

        #mainHeader {
            height: 0px;
            overflow: hidden;
        }

        #mainFooter {
            height: 0px;
            overflow: hidden;
        }

        .paymentHistoryModalBg {
            background-color: none;
        }

        .amigPrintLogo {
            visibility: initial;
            display: block;
        }

        .amigPrintTitle {
            visibility: initial;
            display: inline-block;
            width: 100%;
        }

        .amigPrintSubtitle {
            visibility: initial;
            display: block;
            width: 100%;
            margin-bottom: 24px;
        }

        .paymentHistoryModalCard {
            visibility: initial;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: none;
            background-color: none;
            width: 520px;
            padding: 10px 0px;

            button {
                display: none;
            }
        }

        .paymentModalRow {
            width: 450px;
        }

        .paymentModalTitle {
            width: 40%;
        }

        .paymentDetails {
            margin-top: 24px;
        }
    }
}