.amigValueContainer{}
.amigControl{}

.amigDropdown_option{
    padding: 16px 12px !important;
    font-size: 16px !important;
    color:  $color-amig-gray-100 !important;
    background-color: $color-amig-gray-5 !important;
    &:hover{
        color: $color-amig-gray-100;
        background-color: $color-amig-lightBlue-20 !important;
    }  
}

.amigDropdown_optionSelected{
    color: $color-amig-white !important;
    background-color: $color-amig-lightBlue-100 !important;
    &:hover{
        color: $color-amig-white;
        background-color: $color-amig-lightBlue-100 !important;
    } 
}

.amigInputField_inputInvalid{
    border-color: $color-amig-negative-100; 
}

.amigFieldMessage_error{
    color: $color-amig-negative-100; 
}