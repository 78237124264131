@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.amig-logo{
    position: absolute;
    width: 170px;
    height: 47.69px;
    left: 80px;
    top: 24.15px;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0px;
    box-shadow: 0px 0px $color-amig-blue-100 !important;
    background: $color-amig-blue-100 !important;

    @include gw-breakpoint-down(tablet) { 
        position: absolute;
    height: 36px;
    width: 128px;
    left: 16px;
    right: 0px;
    top: 14px;
     }	

    @include gw-breakpoint-down(phone) { 
        position: absolute;
    height: 36px;
    width: 128px;
    left: 16px;
    right: 0px;
    top: 30px !important;
     }	
     
     &:hover, &:active, &:visited{
         box-shadow: 0px 0px $color-amig-blue-100 !important;       
        background: $color-amig-blue-100 !important; 
        border: none !important; 
     }
 
}


.amig-header {
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding: 24px 80px;
    height: 96px;
    left: 0px;
    top: 0px;
    /* Blue/100 */
    background: $color-amig-blue-100;
    /* Shadow/Base */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    position: sticky; 
    top: 0;
    width: 100%;
    z-index: 350;
    @include gw-breakpoint-down(tablet) { 
        height: 64px;
        padding: 24px;
    }

    @include gw-breakpoint-down(phone) { 
        height: 96px;
        padding: 24px 26px;
    }
}

.amig-header a {
    text-decoration: none;
}

.customHeaderMobile{
    @include gw-breakpoint-down(phone) { 
        margin-bottom: 0px;
    }
}

.avatar-wrapper {
    margin-left:48px;
}


.avatar-button {
    img {
        width:24px !important;
        height:24px !important;
    }
}

.avatar-header {
    pointer-events: none;
    button {
        border-radius: var(--GW-BORDER-RADIUS-FULL);
        width: 48px;
        height: 48px;
        margin-top: 24px;
        margin-bottom: 26px;
    }
}
.user-avatar {
    border-radius: 2px;
}

.user-avatar div{
    margin:4px;
    @extend .amig-h5-web-text;
}

.avatar-menu {
    position: static;
    top: 0px;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 2px;
    margin-top: 24px !important;
    div {
        left: 0px;
        margin-top: 24px;
        margin-bottom: 24px;
        padding: 0px;
    }
}

.avatar-quick-links {
    cursor: pointer;
    text-decoration: none;
    padding: var(--GW-SPACING-4) var(--GW-SPACING-6);
    display: block;
    text-overflow: ellipsis;
    justify-content: initial !important;
    width: 90%;
    color: $color-amig-gray-80 !important;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin-left:5%;
    @extend .amig-body2-web-regular;
    span {
        text-decoration: none !important;
    }

    &:hover {
        background: $color-amig-gray-5;
        color: $color-amig-blue-100  !important;
        text-decoration: none;
    }
}

.active-avatar-quick-links{
    @extend .avatar-quick-links;
    background: $color-amig-gray-5;
    color: $color-amig-blue-100  !important;
    text-decoration: none;
}


.button-link {
    span{
        @extend .amig-h6-web-text;
        position: static;
        padding: 8px 16px;
        letter-spacing: 1px;
        color: $color-amig-white;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 4px;
        border-radius: 2px;
        text-decoration: none !important;
        &:hover, &:active, &:visited, &:focus{
            background: $color-amig-blue-80;
            text-decoration: none !important;
            box-shadow: none !important;
        }
    }

    &:hover, &:active, &:visited, &:focus{
        text-decoration: none !important;
        box-shadow: none !important;
    }
    
}

.active-button-link {
    @extend .button-link;
    span {
        background: $color-amig-blue-80;
    }
}
