@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.pageHeaderId {	
	margin-bottom: 40px !important;
	color: $color-amig-gray-100 !important;
	line-height: 56px;

	@include gw-breakpoint-down(tablet) {
		font-size: 20px !important;
		margin-bottom: 24px !important;
		line-height: 28px !important;
	}
}

.account-preferences-columns {
	@include gw-breakpoint-down(tablet) {
		grid-template-columns: repeat(1, 1fr);
	}

	@include gw-breakpoint-down(phone) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.account-preferences-columns-hidden {
	display: none !important;
}

.account-preferences-card {
	margin-bottom: 48px;
	padding: 24px 40px 24px 40px !important;
	background: $color-amig-white;

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 32px 18px !important;
	}

	@include gw-breakpoint-down(phone) {
		padding: 24px 24px 32px 18px !important;
	}
}

.account-preferences-card-minified {
	height: 84px !important;
	padding: 24px 40px 24px 40px !important;
	cursor: pointer;

	@include gw-breakpoint-down(tablet) {
		height: 4.5rem !important;
		padding: 24px 24px 32px 18px !important;
	}

}

.account-preferences-card-margin{
	margin-bottom: 2.0rem !important;
}

.account-preferences-card-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;	
	display: inline-block;
	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-bottom: 0rem !important;
		padding-bottom: 0.5rem;
	}

	@include gw-breakpoint-down(phone) {
		margin-bottom: 0rem !important;
		padding-bottom: 0.5rem;
	}
}

.accountProfileComponent{
	@include gw-breakpoint-down(phone) {
		margin-bottom: 16px !important;
	}
}

.toggle-card-wrapper {
	display: contents;

	figure {
		margin: 0px !important;
		display: inline-block !important;
		float: right !important;
	}
}

.toggle-icon {
	margin: 6px 0px;
	cursor: pointer;

	@include gw-breakpoint-down(tablet) {
		margin: unset;
	}
}

 .iconChecked {
	width: 24px;
	margin: 3px 0px 6px;
	vertical-align: top !important;
	@media (max-width: 741px) {
		margin: 0px 0px 6px;
	}
 }

 .notificationBanner {
	height: auto;	
	margin: -80px -80px 40px -80px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	padding: 19px 80px 25px 80px;

	@include gw-breakpoint-down(tablet) {
		margin: -24px -16px 40px -16px;
		padding: 19px 28px 30px 24px;
	}
	
	@media (max-width: 741px) {
		padding: 19px 10px 10px 24px;
	}
 }


 .paperlessNotification {
	@extend .notificationBanner;
	background-color: $color-amig-green-10;
}

.notificationBannerContainer {
	display: inline-block;
	margin: 4px 0px 0px 8px;
	@include gw-breakpoint-down(phone) {
		margin: 0px 0px 0px 8px;
	}
}

.customTextContainer {
	@extend .notificationBannerContainer;
	@media (max-width:1170px) {	
		width: 410px;
	}

	@include gw-breakpoint-down(phone) {
		width: 65vw;
	}
}

.notificationBannerHeading {
	@extend .amig-h6-web-text;
	color: $color-amig-black;
}

.customHeading {
	@extend .notificationBannerHeading;
}

.notificationBannerText {
	display: table-cell;
	@extend .amig-body2-web-regular;
	color: $color-amig-gray-100;
	
	@media (max-width:1170px) {	
		width: 410px;
	}

	@include gw-breakpoint-down(phone) {
		width: 70vw;
	}
}

.customText {
	@extend .notificationBannerText;
}

.notificationBannerActionContainer {
	float: right;	
	@media (max-width: 741px) {
		display: contents;
	}
}

.goPaperlessActionContainer {
	@extend .notificationBannerActionContainer;
}

.dismiss-button {
	background-color: transparent !important;
	box-shadow: none;
	height: 24px;
	width: 24px;
	margin: 10px 0px 0px 40px;
	padding: 0px;

	&:focus {
		box-shadow: none;
	}

	@media (max-width: 765px) {
		margin: 10px 0px 0px 20px;	
	}

	@media (max-width: 741px) {
		display: none;
	}

}

.dismiss-button-edit{
	@extend .dismiss-button;
	margin: 0px;
	padding: 0px;
}

.dismiss-button-mobile{
	@extend .dismiss-button;
	margin: 0px;
	float: right;
	display: none;
	@media (max-width: 741px) {
		display: unset
	}
}

.dismiss-button-mobile-edit {
	@extend .dismiss-button-mobile;
	display: none;
	float: none;
	@media (max-width: 732px){
		display: inline;
	}
}

.b2cContainer{
	display: flex;
	justify-content: flex-end;
}

.flowCompleteNotification {
	@extend .notificationBanner;
	height: 80px;
	background-color: $color-amig-positive-10;	
	padding: 24px 80px 25px 80px;

	@media (max-width:1170px) {
		height: 80px;
	}

	@include gw-breakpoint-down(tablet) {
		height: 80px;		
		padding: 24px 28px 0px 24px;
	}
	
	@media (max-width: 741px) {
		height: 96px;
		padding: 24px 10px 0px 24px;
	}
}

.flowCompleteNotification button {
	margin: 0px 0px 0px 0px
}

.changeDeliveryMethod {
	@extend .green-links-states, .text-link-medium;
	background-color: transparent !important;
	box-shadow: none;
	margin-top: 10px;
	height: 24px;
	padding: 0px;

	@media (max-width: 741px) {
		margin: 5.5px 32px;
	}
}

.mb-custom{
	margin-bottom: 0 !important;
	@include gw-breakpoint-down(tablet) {
		margin-bottom: 8px !important;
	}

}

.unsavedChangesNotification{
	@extend .notificationBanner;;
	background-color: $color-amig-negative-10;
	height: auto;
	
}

.b2cContainer{
	margin-bottom: 20px;
	@include gw-breakpoint-down(tablet) {
		margin-bottom: unset;
	}
}