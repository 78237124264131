.sectional-notification {
    @extend .amig-body2-web-regular;
    background-repeat: no-repeat;
    background-position: 24px 16px;
    color: $color-amig-gray-100;
    width: 100%;
    height: auto;
    padding: 20px 24px 16px 72px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);


    @include gw-breakpoint-down(tablet)  {
        font-size: 14px;
        line-height: 20px;
        padding: 18px 24px 16px 56px;
        background-size: 24px;
    }

    span {
        padding: 0px;
        font-weight: normal;
        color: $color-amig-gray-100;
    }

    i {
        display: none;
    }
}


.info-sectional-notification {
    @extend .sectional-notification;
    background-image: url('/branding/amig/blue_info_icon.png');
    background-color: $color-amig-lightBlue-10;
  }

.alert-sectional-notification {
    @extend .sectional-notification;
    background-image: url('/branding/amig/alert_icon.png');
    background-color: $color-amig-negative-10;
  }

.warning-sectional-notification {
    @extend .sectional-notification;
    background-image: url('/branding/amig/warning_icon.png');
    background-color: $color-amig-warning-10;
  }

.success-sectional-notification {
    @extend .sectional-notification;
    background-image: url('/branding/amig/success_icon.png');
    background-color: $color-amig-positive-10;
  }
