@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.imp-notice-notification {
    @extend .info-sectional-notification;    
    h6 {
        font-family: $font-amig-raleway;
        color: $color-amig-black;  
    }
    p {
        color: $color-amig-gray-80;
        white-space: pre-wrap;
    }
}

.cp-custom {
    padding: 20px 120px 20px 120px;
    background: $color-amig-white;
    @include gw-breakpoint-down(tablet) {
        padding: 20px 20px 20px 20px;
    }
    h2 {
        @extend .amig-h2-web-text;
        color: $color-amig-black;
        font-family: $font-amig-raleway;         
        @include gw-breakpoint-down(tablet) {
            font-size: 20px;
            line-height: 28px;
            color: $color-amig-gray-100;
        
        }       
    }
    h3 {
        font-family: $font-amig-raleway;
        color: $color-amig-gray-100;
        @include gw-breakpoint-down(tablet) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    p {
        @extend .amig-body1-web-regular;
        margin-top: 24px !important;
        color: $color-amig-gray-80;        
        @include gw-breakpoint-down(tablet) {
            font-family: $font-amig-raleway; 
            font-size: 16px;
            line-height: 24px;
        }
    }   
}

.cp-pay-button {
    @extend .btn-secondary-medium;
    background-color: transparent !important;
    box-shadow: none !important;
    color: $color-amig-green-100;
    border: 2px solid $color-amig-green-100;

    &:hover,
    &:active {
        color: $color-amig-green-80;
        border: 2px solid $color-amig-green-80;
    }
    @include gw-breakpoint-down(tablet) { 
        width: 100%;
    }
}

.eftPay-custom {   
    margin-left: 20px;
    @include gw-breakpoint-down(tablet) {   
        margin-top: 20px;
        margin-left: 0px;       
    }
}

.mailpay-text-custom {
    white-space: pre-wrap;
    font-weight: 700 !important;
}