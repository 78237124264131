@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.faqSection {
  padding: 64px;
  @include gw-breakpoint-only(phone) {
    padding: 32px;
  }
}

.gwSectionContainer {
  margin: var(--GW-SPACING-6) 0px var(--GW-SPACING-6) 44px;

  @include gw-breakpoint-only(tablet) {
    margin: var(--GW-SPACING-6);
  }
  @include gw-breakpoint-only(phone) {
    margin: 1rem;
  }
}

.gwSectionTitle {
  @extend .amig-h3-web-text;
  color: $color-amig-gray-100;
  padding-bottom: 18px;
  box-shadow: 0px 20px 0px $color-amig-white;
  scroll-margin-top: 170px;

  @include gw-breakpoint-only(tablet) {
    scroll-margin-top: 138px;
  }
}