@import "~@jutro/theme/assets/sass/helpers";
@import "~amig-digital-theme-styles/styles/core";

.printButton {
	justify-self: flex-end;
}

.headerContainer {
	display: contents;
}

.policyDetailContainer {
	padding: var(--GW-SPACING-3) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);

	div[class="jut__TabSet__content"] {
		margin-top: var(--GW-SPACING-6);
	}
}

.policyDetailsSection:not(:last-child) {
	margin-bottom: var(--GW-SPACING-8);
}

.idCardContainer {
	display: flex;
	justify-content: flex-end;
}

.policyBackButton {
	margin-bottom: var(--GW-SPACING-2);

	i {
		position: relative;
		top: calc(1.25 * var(--GW-SPACING-1));
	}
}

h2[class="policyDetailSectionTitle"] {
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-SPACING-3);
}

.policyChangeButtonContainer {
	margin-top: var(--GW-SPACING-6);
}


.policyAvatar img {
	height: 24px !important;
	width: 24px !important;
	background: $color-amig-blue-100;
}

.policyAvatar {
	height: 48px !important;
	width: 48px !important;
	background-color: $color-amig-blue-100 !important;
	border-radius: 50%;
	padding: 7px !important;
	margin-bottom: 8px;
	pointer-events: none;

	@include gw-breakpoint-down(tablet) {
		margin-bottom: 0px;
	}
}

.policyDetailTitle {
	@extend .amig-h1-web-text;
	width: 396px;
	height: 56px;
	left: 80px;
	top: 232px;
	line-height: 56px !important;
	color: $color-amig-gray-100 !important;
	display: block;

	@include gw-breakpoint-down(tablet) {
		font-size: 20px !important;
		line-height: 30px !important;
		margin-left: 8px;
		height: unset;
	}

	@include gw-breakpoint-down(phone) {
		line-height: 28px !important;
		width: 80vw;
	}
}

.policyTypeAndNumberContainer {
	@include gw-breakpoint-down(tablet) {
		display: inline-block;
	}
}

.policy-card-policy-number {
	@extend .amig-body1-web-bold;
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
	color: $color-amig-gray-60;
	margin-top: 4px;
	line-height: 28px;

	@include gw-breakpoint-down(tablet) {
		margin-left: 8px;
		font-size: 14px !important;
		line-height: 20px !important;
		margin-top: 0px;
		margin-left: 8px;
	}
}

.policyHeader {
	@include gw-breakpoint-down(tablet) {
		display: inline-flex;
	}
}

.vehicle-coverages-columns {
	@include gw-breakpoint-down(tablet) {
		grid-template-columns: repeat(1, 1fr);
	}

	@include gw-breakpoint-down(phone) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.vehicle-coverages-columns-hidden {
	display: none;
}

.vehicle-coverages-card {
	margin: 48px 0px;
	padding: 2.5rem 2.875rem 1.5rem 2.875rem !important;
	background: $color-amig-white;

	/* Shadow/Base */
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 32px 18px !important;
	}

	@include gw-breakpoint-down(phone) {
		padding: 24px 24px 32px 18px !important;
	}
}

.vehicle-coverages-card-minified {
	@include gw-breakpoint-down(tablet) {
		height: 4.5rem !important;
		padding: 24px 24px 32px 18px !important;
	}

}

.vehicle-coverages-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;
	margin-bottom: 2.5rem !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		display: inline-block;
		margin-bottom: 0rem !important;
		padding-bottom: 0.5rem;
	}

	@include gw-breakpoint-down(phone) {
		margin-bottom: 0rem !important;
		padding-bottom: 0.5rem;
	}
}

.agent-columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include gw-breakpoint-down(phone) {
		grid-template-columns: repeat(1, 1fr);
	}

	@include gw-breakpoint-down(tablet) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.agent-columns-hidden {
	display: none;
}

.policy-agent-card {
	margin: 48px 0px;
	padding: 40px 34px 0px 34px !important;
	height: 420px !important;
	background: $color-amig-white;
	/* Shadow/Base */
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-green-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
		height: auto !important;
	}

	@include gw-breakpoint-down(phone) {
		padding: 24px 24px 8px 18px !important;
	}

}

.policy-agent-card-minified {
	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-lightGreen-100;
	}
}

.contact-agent-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
		display: inline-block;
	}

	@include gw-breakpoint-down(phone) {
		font-size: 16px !important;
	}
}

.contact-agent-subtitle {
	@extend .amig-body1-web-regular;
	margin: 40px 0px !important;
	color: $color-amig-gray-80 !important;

	@include gw-breakpoint-down(tablet) {
		display: none;
	}

	@include gw-breakpoint-down(phone) {
		display: none;
	}
}

.toggle-card-wrapper {
	display: contents;

	figure {
		margin: 0px !important;
		display: inline-block !important;
		float: right !important;
	}
}


.policy-coverages-card {
	margin: 48px 0px;
	background: $color-amig-white;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	padding: 40px;
	border-left: solid 6px $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
		height: unset !important;
	}

	@include gw-breakpoint-down(phone) {
		height: unset !important;
	}
}



.policy-coverages-card-minified {
	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
	}
}



.card-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
		display: inline-block;
	}
}

.policy-coverages-columns-hidden {
	display: none;
}

.policy-coverages-columns {
	margin-top: 40px;

	@include gw-breakpoint-down(tablet) {
		margin-top: 0px;
	}

}

.policy-contacts-columns-hidden {
	display: none;
}

.policy-contacts-card {
	margin: 48px 0px;
	padding: 40px;
	background: $color-amig-white;

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
	}
}

.policy-contacts-card-minified {

	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-blue-100
	}

}

.policy-contact-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;
	letter-spacing: 0px;
	text-align: left;
	display: inline-block;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;

		margin-top: 1px !important;
		display: inline-block;
	}
}

.covered-drivers-columns {

	margin-top: 40px;

	@include gw-breakpoint-down(tablet) {
		margin-top: unset;
	}
}

.covered-drivers-columns-hidden {
	display: none;
}

.covered-drivers-card {
	margin: 48px 0px;
	padding: 2.5rem 2.875rem 3.5rem 2.875rem !important;
	background: $color-amig-white;

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
	}
}

.covered-drivers-card-minified {

	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-blue-100
	}

}

.covered-drivers-card-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
		display: inline-block;
	}
}

.custom-policy-details-title {
	@extend .amig-h3-web-text;
	display: inline-block;
	color: $color-amig-gray-100 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
	}
}

.policy-details-columns-hidden {
	display: none;
}

.policy-details-card {

	padding: 2.5rem 2.5rem 3.5rem 2.875rem !important;
	background: $color-amig-white;
	margin-top: 32px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {

		padding: 24px 24px 40px 18px !important;
		margin-top: 24px;
	}
}

.policy-details-card-minified {

	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-blue-100
	}

}

.document-link-div {
	margin-top: 24px;
	height: 24px;
	display: -webkit-box;

	&:hover {
		text-decoration: none !important;
	}

	&:active {
		text-decoration: none !important;
	}

	span {
		&:hover {
			text-decoration: none !important;
			color: $color-amig-lightBlue-100;
			;
		}

		&:active {
			text-decoration: none !important;
			color: $color-amig-lightBlue-60;
		}
	}

	@include gw-breakpoint-down(phone) {
		display: block;
		height: auto;
	}

}

.link-div-id {
	@include gw-breakpoint-down(phone) {
		margin-bottom: 16px;
	}
}

.link-div-id,
.link-div-dec {

	height: 24px;
	display: -webkit-box;

	span {
		@extend .text-links-states;
	}

	>figure {
		margin-left: 0px;
		margin-right: 8px;
		margin-top: -2px;
	}

}

.id-Card {
	@extend .text-link-medium;
	font-weight: 400 !important;
	margin-right: 24px;
}

.test {
	background-color: olive;
}

.policy-documents-card {
	margin: 48px 0px;
	padding: 2.5rem 2.875rem 3.5rem 2.875rem !important;
	background: $color-amig-white !important;

	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
		height: unset !important;
	}

	@include gw-breakpoint-down(phone) {
		height: unset !important;
	}
}

.policy-documents-card-minified {

	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-blue-100;
	}

}

.policy-documents-columns {

	margin-top: 40px;

	@include gw-breakpoint-down(tablet) {
		margin-top: 24px;
	}
}

.policy-documents-columns-hidden {
	display: none;
}

.initialContentWidth {
	display: none !important;
}

.changeLinks {
	@extend .text-link-regular;
	display: inline-block !important;
	float: right;
	margin-top: 10px;

	@include gw-breakpoint-down(tablet) {
		float: unset;
		display: block !important;
		margin-top: 8px;
		max-width: fit-content;
	}
}