.amig_mi-help-outline {
    color: $color-amig-blue-100;

    &:after {
        box-shadow: none !important;
    }
}

.amig_mi-help {
    color: $color-amig-blue-100;

    &:after {
        box-shadow: none !important;
    }
}