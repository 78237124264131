.accountSummaryContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);
}

.accountSectionReadonly {
    --gw-user-section-readonly-opacity: 0.5;
    opacity: var(--gw-user-section-readonly-opacity);
    @extend .accountSummaryContainer;
}

.accountHeaderContainer {
    margin-bottom: var(--GW-SPACING-6);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

h2[class*='accountSummarySectionTitle'] {
    margin-bottom: var(--GW-SPACING-6);
    padding-bottom: var(--GW-SPACING-3);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

h2[class*='billingDetailTitle'] {
    margin-top: var(--GW-SPACING-6);
    padding-bottom: var(--GW-SPACING-3);
}

h1[class='accountSummaryPageTitle'] {
    margin: 0;
}

p[class='noMarginBilling'] {
    margin: 0;
}

.marginTopContainer {
    margin-top: var(--GW-SPACING-3);
}

.globalizationChooserField {
    margin-top: var(--GW-SPACING-2);
}
