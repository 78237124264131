:root {
  --gw-box-padding: var(--GW-SPACING-6);
  --gw-border-solid: 2px;
  --gw-divider-margin: var(--GW-SPACING-5);
  --gw-divider-padding: var(--GW-SPACING-5);
  --gw-divider-border: 1px;
  --gw-page-section-header-row-padding-top: var(--GW-SPACING-2);
  --gw-titles-subtitle-offset: var(--GW-SPACING-4);
}

.gwProducerCodeDivider {
  display: inline-block;
  border-left: solid var(--gw-divider-border) var(--GW-SEPARATOR-COLOR);
  padding-right: var(--gw-divider-padding);
  margin-left: var(--gw-divider-margin);
}

.inputFieldThin {
  width: 100%;

  :global(.jut__FieldComponent__controls) {
    display: contents;
  }
}

.costContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.totalDue {
  border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
  padding-top: var(--GW-SPACING-3);
}

.gridContainer {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
}

.endorsementPolicyPeriodDate{
  div[class*='datePicker'] {
    --gw-icon-padding: var(--GW-SPACING-5);
    padding-top: var(--gw-icon-padding);
    font-size: var(--GW-FONT-SIZE-H3);
  }
  text-align: center;
}

.iconStyle {
  --gw-icon-padding: var(--GW-SPACING-6);
  padding-top: var(--gw-icon-padding);
  padding-bottom: var(--gw-icon-padding);
}

.policyChangeDateContainerStyle {
  align-items: center;
  align-content: center;
}
