.signUpContainer {

  > * {
      margin-bottom: var(--GW-SPACING-6);
  }

  .signInLinkContainer {
    border-top: 1px solid var(--GW-BORDER-COLOR);
    padding-top: var(--GW-SPACING-6);
  }

}

