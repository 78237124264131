@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.enrollment-card {
    background-color: $color-amig-white;
    padding: 64px 80px;
    margin-bottom: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 728px;

    @include gw-breakpoint-down(tablet) {
        padding: 32px 24px 40px 24px;
        width: 100%;
    }
}

.policy-card-input-data {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100;   
    letter-spacing: 0.5px;
    >div{
        min-width: 224px !important;
    }    
    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
    }
}

.policy-card-label {
    @extend .amig-h5-mobile-text;
    color: $color-amig-gray-100;
    margin-top: 32px;
    margin-bottom: 12px;

    @include gw-breakpoint-down(tablet) {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 8px;
    }
}

.policyTypeContainer {
    margin-top: 32px;
    margin-bottom: 32px;

    @include gw-breakpoint-down(tablet) {
        margin-bottom: 28px;
    }
}

.addPolicyHeader {
    color: $color-amig-gray-100 !important;
    margin-bottom: 16px !important;

    @include gw-breakpoint-down(tablet) {
        margin-bottom: 12px !important;
        font-size: 24px !important;
        line-height: 32px !important;
    }
}

.informationText {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80 !important;
    margin-top: 16px !important;
    @include gw-breakpoint-down(tablet) {
        margin-bottom: 12px !important;
        margin-top: 12px !important;
    }
}

.policyTypeLabel {
    color: $color-amig-gray-100;
    @extend .amig-caption-web-bold;
    margin-top: 32px;
    margin-bottom: 12px;

    @include gw-breakpoint-down(phone) {
        font-size: 12px;
    }
}

.policyTypes {
    div {
        display: inline-block !important;
        margin-right: 28px;
    }

    label>span {
        @extend .amig-body2-web-regular;
        font-size: 16px !important;
        margin: 0px !important;
        color: $color-amig-gray-100 !important;
    }

    @include gw-breakpoint-down(phone) {
        div {
            width: 46% !important;
            margin-right: 0px;
            vertical-align: top;
        }

        >div {
            width: unset !important;
        }

        >div>div {
            width: unset !important;
        }

        label {
            text-align: right;          
        }


    }

    @media (max-width: 375px) {
        div {
            width: 50% !important;
            margin-right: 0px;
            vertical-align: top;
        }

        >div {
            width: unset !important;
        }

        >div>div {
            width: unset !important;
        }
    }
}

.fieldLabel {
    @extend .amig-h5-mobile-text;
    color: $color-amig-gray-100;

    @include gw-breakpoint-down(tablet) {
        font-size: 12px !important;
        margin-bottom: 4px;
    }
}

.customInputField {
    margin: 8px 0px 32px 0px;

    input {
        height: 48px;
        left: 0px;
        top: 28px;
        background: $color-amig-gray-5;
        -webkit-box-shadow: 0 0 0 1000px $color-amig-gray-5 inset !important;
        border: 1px solid $color-amig-gray-20;
        box-sizing: border-box;
        border-radius: 2px;

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        @extend .amig-body2-web-regular;
        color: $color-amig-gray-100 !important;
        @include gw-breakpoint-down(tablet) {
            font-size: 14px !important;
        }
    }

    div[class*='jut__FieldMessage__error'] {
        @extend .amig-caption-web-regular;
        @include gw-breakpoint-down(tablet) {
            font-size: 12px !important;
        }
    }

    @include gw-breakpoint-down(tablet) {
        margin: 8px 0px 16px 0px;
    }
}

.enrollButton {
    @extend .btn-primary-medium;
    @extend .amig-body2-web-regular;
    width: 48.5%;

    &:disabled {
        background-color: $color-amig-blue-40 !important;
        opacity: 1;
    }

    @include gw-breakpoint-down(tablet) {
        width: 100%;
        order: 1;
        -webkit-order: 1;
        margin: 16px 0px 32px 0px !important;
    }
}

.goBack {
    @extend .text-links-states;
    @extend .amig-body2-web-bold;

    span {
        line-height: 25px;
    }

    @include gw-breakpoint-down(tablet) {
        justify-content: unset !important;
        order: 2;
        -webkit-order: 2;
        margin: auto !important;
        font-size: 14px;
        line-height: 20px;
    }

    i {
        font-size: 24px !important;
        margin: 0px !important;

        @include gw-breakpoint-down(tablet) {
            display: none;
        }
    }
}

.notificationHeading {
    span[class*='jut__InlineNotification__messageDefault'] 
     {
    font-weight:700;
    padding-right: 4px;
    }
}

.notificationMargin {
    margin-top: 16px;
    margin-bottom: 16px;
    @include gw-breakpoint-down(tablet)  {
         margin-top: 12px;
         margin-bottom: 12px;
     }
}

.actionContainer {
    flex-wrap: wrap !important;
    justify-items: flex-end;
}

.justifyButton {
    justify-content: end;
}

.mb-32{
    @include gw-breakpoint-down(tablet) {
        margin-bottom: 8px;
    }
}

.spinner {
    display: block;
    margin-top: 35vh;
    width:128px;
    height:128px;
}