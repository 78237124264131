@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.policy-details-container {
    margin-top: 40px;

    @include gw-breakpoint-down(tablet) {
        height: auto !important;
        margin-top: 24px;
    }

    @include gw-breakpoint-down(phone) {
        height: auto !important;
    }
}


.policy-card-detail-grid {
    @include gw-breakpoint-down(tablet) {
        margin-top: 3px;
    }

    @include gw-breakpoint-down(phone) {
        margin-top: 0px;
    }
}

.overdue-policy-card-detail-grid {
    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
    }
}

.policy-grid-container {
    margin: 8px 0px 40px 0px;

    @include gw-breakpoint-down(phone) {
        margin-top: 0px;
    }
}

.overdue-policy-grid-container {
    margin: 8px 0px 40px 0px;

    @include gw-breakpoint-down(tablet) {
        margin-top: 0px;
    }
}

.policy-card-subheader-label {
    color: $color-amig-gray-100;
    @extend .amig-h6-web-text;
    display: inline-block;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
    }

    @media (max-width: 768px) {
        display: block;
        font-size: 16px !important;
        margin-top: 24px !important;
    }
}
    
.policy-card-subheader {
    @extend .amig-body2-web-regular;
    margin-left: 4px;
    color: $color-amig-gray-80;
    display: inline-block;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
    }

    @media (max-width: 768px) {
        font-size: 16px !important;
        margin-left: 0px;
        margin-top: 8px;
        line-height: 24px !important;
    }
}

.policy-card-policy-type {
    color: $color-amig-gray-100 !important;
    margin-bottom: 10px !important;

    @include gw-breakpoint-down(tablet) {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}


.policy-detail-column {
    @include gw-breakpoint-down(phone) {
        display: contents;
    }
}

.policy-detail-label {
    @extend .amig-h6-web-text;
    margin-top: 24px !important;
    color: $color-amig-gray-100 !important;

    @include gw-breakpoint-down(tablet) {
        margin-top: 16px !important;
    }
}

.policy-card-input-data {
    @extend .amig-body2-web-regular;   
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    margin-top: 8px;
    color: $color-amig-gray-80 !important;
}


.policy-card-payment-overdue {
    @extend .amig-body2-web-regular;
    margin-top: 8px;
    color: $color-amig-negative-100 !important;
}

.policy-card-make-a-payment-link {
    @extend .text-link-medium;
    margin-top: 8px;

    span {
        line-height: 25px;
    }

    @include gw-breakpoint-down(phone) {
        justify-content: unset !important;
    }

    i {
        transform: rotate(180deg);
        font-size: 24px !important;
    }
}

.copy-content {
    height: 24px;
    width: 24px;
}

.hideTooltip {
    @include gw-breakpoint-down(tablet) {
        >div>div {
            display: none !important;
        }
    }
}


.policy-card-view-policy-button {
    @extend .btn-primary-medium;
    color: $color-amig-white !important;

    &:hover,
    &:active {
        text-decoration: none !important;
        background-color: $color-amig-lightBlue-100;
    }

}

.notificationMargin {
    margin-top: 16px;
    @include gw-breakpoint-down(tablet)  {
         margin-top: 8px;
     }
}

.copy-content-container {
    display: inline-block;
    height: 24px;
    margin-right: 16px;
}

.copy-content {
    height: 24px;
    width: 24px;
}

.policy-tablet-col {
    display: contents;

    @include gw-breakpoint-down(tablet) {
        display: block;
    }

    @include gw-breakpoint-down(phone) {
        display: contents;
    }
}

.pastDueNotificationmessage {
    display: inline-block;
    @extend .amig-body2-web-regular;
 
    @include gw-breakpoint-down(tablet) {
       font-size: 14px;
       line-height: 20px;
    }
}

.pastDueNotificationAmount {
    display: inline-block !important;
    @extend .amig-body2-web-bold;
    margin-left: 5px;
    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
        line-height: 20px;
     }
}

.policy-card-type-and-number-container {
    margin-bottom: 2px;

    @include gw-breakpoint-down(tablet) {
        margin-bottom: 0px
    }
}

.policy-card-no-policy{
    @extend .info-sectional-notification;
    @include gw-breakpoint-down(tablet) {
        height: auto !important;
        padding: 0px 0px 0px 48px;
    }
}