.signUpLinkContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid var(--GW-BORDER-COLOR);
    padding-top: var(--GW-SPACING-6);
    flex-grow: 1;
}

.spinner {
    display: block;
    margin-top: 35vh;
    margin-left: calc(50vw - 104px);
    width:128px;
    height:128px;
}