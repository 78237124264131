.amigBurgerSidebarMenu {
    --AMIG-BURGER-SIDEBAR-BACKGROUND-COLOR:red;
    top:30%
}

.amigSidebarMenuHeader {
    background-color: $color-amig-blue-100;
    height: 96px;
    color: white;
    padding-top: var(--GW-LAYOUT-4);
    padding-bottom: var(--GW-LAYOUT-4);
    justify-content: space-between;
    margin-bottom: 25px;

    @media (orientation:landscape){
        height: 64px;
        position: sticky;
        top: 0;
    }

    @media (orientation:portrait){       
        position: sticky;
        top: 0;
    }
    
}

.amigSidebarMenuHeader button{
    margin-left: auto;
    margin-right: 0;
        &:hover {
            i{
                background-color: $color-amig-blue-80;
                height: 24px;
                width: 24px;
            }
            background-color: transparent;
        }
}

.amigSidebarIcon{
    color: #ffffff;
}

.hideSidebarTitle{
    display: none;
}

.amigBurgerIcon{
    margin-top: 1px;
}