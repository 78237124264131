.text-links-states {
  color: $color-amig-blue-100;
  &:hover {
    text-decoration: none !important;
    color: $color-amig-lightBlue-100 !important;
  }
  &:active {
    text-decoration: none !important;
    box-shadow: none;
    color: $color-amig-lightBlue-60 !important;
    background: $color-amig-white;
  }
  &:focus {
    text-decoration: none !important;
    box-shadow: none;
  }
  &:disabled {
    text-decoration: none !important;
    color: $color-amig-blue-20;
  }
  >span{
    text-decoration: none !important;
    }
}

.nav-links-states {
  color: $color-amig-gray-80;
  &:hover,
  &:active {
    text-decoration: none !important;
    box-shadow: none;
    color: $color-amig-gray-80;
    background: $color-amig-white;
  }
  &:focus {
    text-decoration: none !important;
    box-shadow: none;
  }
  &:disabled {
    text-decoration: none !important;
    color: $color-amig-blue-20;
  }
  >span{
    text-decoration: none !important;
    }
}

.purple-links-states {
  color: $color-amig-purple-100;
  &:hover {
    text-decoration: none !important;
    color: $color-amig-lightPurple-100;
  }
  &:active {
    text-decoration: none !important;
    box-shadow: none;
    color: $color-amig-lightPurple-60;
  }
  &:focus {
    text-decoration: none !important;
    box-shadow: none;
  }
  &:disabled {
    text-decoration: none !important;
    color: $color-amig-purple-20;
  }
  >span{
    text-decoration: none !important;
    }
}

.green-links-states {
  color: $color-amig-green-100;
  &:hover {
    text-decoration: none !important;
    color: $color-amig-lightGreen-100 !important;
  }
  &:active {
    text-decoration: none !important;
    box-shadow: none;
    color: $color-amig-lightGreen-60 !important;
  }
  &:focus {
    text-decoration: none !important;
    box-shadow: none;
  }
  &:disabled {
    text-decoration: none !important;
    color: $color-amig-green-20 !important;
  }
  >span{
    text-decoration: none !important;
    }
}

.text-link-regular {
  @extend .amig-body2-web-regular, .text-links-states;
}

.text-link-small {
  @extend .amig-body1-mobile-bold, .text-links-states;
}

.text-link-medium {
  @extend .amig-body2-web-bold, .text-links-states;  
}

.text-link-large {
  @extend .amig-body1-web-bold, .text-links-states; 
}

.nav-link-regular {
  @extend .amig-body2-web-regular, .nav-links-states;
}

.nav-link-small {
  @extend .amig-body1-mobile-bold, .nav-links-states;
}

.nav-link-medium {
  @extend .amig-body2-web-bold, .nav-links-states;
}

.nav-link-large {
  @extend .amig-body1-web-bold, .nav-links-states;
}

// sticky-contact links
.link-purple-small {
  @extend .amig-body1-mobile-bold, .purple-links-states;
}

.link-purple-medium {
  @extend .amig-body2-web-bold, .purple-links-states;
}

.link-purple-large {
  @extend .amig-body1-web-bold, .purple-links-states;
}

// sticky-chat links
.link-green-small {
  @extend .amig-body1-mobile-bold, .green-links-states;
}

.link-green-medium {
  @extend .amig-body2-web-bold, .green-links-states;
}

.link-green-large {
  @extend .amig-body1-web-bold, .green-links-states;
}