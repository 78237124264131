@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';
.agent-card-wrapper {
    margin: 100px;
}

.agent-card-headers {
    @extend .amig-h5-web-text;
    display: grid;
    color: $color-amig-gray-100 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 32px 0px 16px 0px !important;
    @include gw-breakpoint-down(tablet) {
        font-size: 16px;
        margin: -128px 200px 16px 0px !important;
        justify-content: end;
    }
    @media (max-width:700px) { 
        margin-right: 190px !important;
    }
    @media (max-width:665px) {
        font-size: 16px;
        margin: 32px 0px 16px 0px !important;
        justify-content: left;       
    }
    @include gw-breakpoint-down(phone) {
        font-size: 16px;
        margin: 32px 0px 16px 0px !important;
        justify-content: left;
    }
}

.agent-name {       
    color: $color-amig-gray-100 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px;   
    @include gw-breakpoint-down(tablet) {
        font-size: 20px !important;
        margin-top: 24px !important;
    }
}

.avatar-title-wrapper {
    display: flex !important;
    margin-bottom: 30px;
    height: 48px;
    @include gw-breakpoint-down(tablet) {
        height: auto;
    }
}

.agent-name-wrapper {
    display: inline-grid;
    margin: 0px;
}

.company {
    padding-top: 12px;
    @include gw-breakpoint-down(tablet) {
        padding-top: 0px;
    }
}

.agent-company {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 2px 0px;
    @include gw-breakpoint-down(tablet) {
        font-size: 14px;
    }
}

.agent-avatar-logo {
    width: 48px;
    height: 48px;
    margin-right: 24px;
    background-color: $color-amig-green-100 !important;
    border-radius: 104px;
    pointer-events: none;
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.agent-contact-wrapper {
    margin: 16px 0px;
    height: 28px;
    @include gw-breakpoint-down(tablet) {
        margin: 32px 0px;
        height: 24px;
    }
}

.agent-contact-wrapper-minified {   
    @include gw-breakpoint-down(tablet) {
        width: 50%;
        display: inline-block;
        margin: 2px 0px;
    }
    @include gw-breakpoint-down(phone) {
        width: unset;
        display: block;
        margin: 32px 0px;
    }
}

.agent-contact-wrapper figure {
    display: inline !important;
    margin: 0px 0px 0px 0px !important;
}

.agent-contact-wrapper img {
    margin-bottom: 6px !important;
}

.agent-avatar-logo img {
    height: 32px !important;
    width: 32px !important;
}

.phone-icon {
    height: 24px;
    width: 24px;
    margin-left: 17px !important;
    @include gw-breakpoint-down(tablet) {
        margin-left: 1px !important;
    }
}

.email-icon {
    height: 24px;
    width: 24px;
    margin-left: 17px !important;
    @include gw-breakpoint-down(tablet) {
        margin-left: 0px !important;
    }
}

.phone-number {
    @extend .amig-body1-web-bold;
    @extend .green-links-states;
    color: $color-amig-gray-80;
    font-family: Arial !important;
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 32px;
    background: transparent !important;
    box-shadow: none !important;
    pointer-events: none;
    span{
        text-decoration: none !important;
    }
    @include gw-breakpoint-down(tablet) {
        color: $color-amig-green-100;
        font-size: 16px !important;   
        margin: 0px 16px; 
        pointer-events: unset; 
    }
}

.email-add {
    flex: none;
    order: 1;
    flex-grow: 1;
    margin: 0px 32px;
    @extend .link-green-large;
    span {
        @extend .green-links-states;
    }
    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
        margin: 0px 16px;
    }
}

.policy-list {
    @extend .amig-body1-web-regular;
    padding-left: 30px;
    margin-bottom: 0px !important;
    color: $color-amig-gray-80;
    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
        width: 280px;
        padding-left: 28px;
        float: right;
        margin-right: 6.5%;
    }
    @media (max-width:665px) {
        font-size: 16px;
        padding-left: 28px;
        float: left;
    }
    @include gw-breakpoint-down(phone) {
        font-size: 16px;
        padding-left: 28px;
        float: left;
    }
}

.agent-card-title {
    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}