@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';
.claim-title {  
    @extend .amig-body2-web-regular;  
    color: $color-amig-gray-100;    
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin: 16px 0px 8px 0px;
    text-transform: capitalize;
}

.claim-status {  
    @extend .amig-caption-web-regular;   
    color: $color-amig-gray-100;    
    flex: none;
    flex-grow: 0;
    margin: 8px 0px;
}

.progress-bar{
    height: 16px;
    border-radius: 2px;
    background-color: $color-amig-gray-5;
}

.progress-bar-step{
    height: 16px;
    border-radius: 2px;
    background-color: $color-amig-blue-100 !important;
}