@font-face {
    font-family: 'Raleway';
    font-weight: 400;
    src:
        url('#{$raleway-root}/TTF/Raleway-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway Bold';    
    font-weight: 700;    
    src:    
        url('#{$raleway-root}/TTF/Raleway-Bold.ttf') format('truetype');    
}