@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';
.custom-agent-card {
  background: $color-amig-white !important;
  margin: 16px 0px 0px 0px !important;
}

.custom-sticky-card {
  background: $color-amig-white !important;
  margin: 21px 0px 32px 0px !important;
}

.custom-sticky-card-details {
  background: $color-amig-white !important;
  margin: 0px 0px !important;
}

.custom-sticky-card>div {
  float: right;
  background-color: $color-amig-white;
}

.custom-sticky-card button {
  background-color: $color-amig-white;
  box-shadow: 0px 0px !important;
  color: $color-amig-black;
}

.chevron {
  float: right !important;
  margin: 0px !important;
  padding: 10px 0px !important;
  &:hover,
  &:active {
    background-color: $color-amig-white !important;
  }
  @include gw-breakpoint-down(tablet) {
    margin: 0px 6px !important
  } 
  @include gw-breakpoint-down(phone) {
    margin: 0px 8px !important
  }
}

.chevron i {
  margin: 0px !important;
}

.chevron-flip {
  transform: rotate(180deg);
}

.agentHidden {
  display: none;
}

.agentDisplay {
  float: none;
  width: 100%;
  margin-top: 32px;
  background: $color-amig-white !important;
}

.stickyCardTitle {
  margin: 0px 8px !important;
  color: $color-amig-gray-80;
  display: inline-block;
  width: 210px;
  vertical-align: top;
  padding-top: 4px;
  overflow-wrap: anywhere;
  @extend .amig-h6-web-text;
  &:hover,
  &:active {
    cursor: pointer;    
  }
}

.stickyAva img {
  height: 16px !important;
  width: 16px !important;
  background: $color-amig-lightPurple-100;
  vertical-align: baseline;
}

.stickyAva {
  height: 32px !important;
  width: 32px !important;
  background-color: $color-amig-lightPurple-100 !important;
  border-radius: 50%;
  padding: 7px !important;
  pointer-events: none;
}

.stickyAvatarLogo {
  margin: 16px 0px 0px 8px;
  height: 24px;
  width: 24px;
  pointer-events: none;
}

.stickyAvatarLogo img {
  height: 24px !important;
  width: 24px !important;
  background: $color-amig-white;
}

.stickyCardDetail {
  margin: 0px 8px !important;
  color: $color-amig-gray-100;
  vertical-align: top;
  margin-top: 4px !important;
  display: inline-block;
  @extend .amig-h6-web-text;
}

.stickyCardDetailPolicyNumber {
  margin: 0px !important;  
  @extend .amig-body1-mobile-bold;
  @extend .purple-links-states;
  text-decoration:none; 
}

.stickyCardDetailEmail {
  margin: 0px 8px !important;  
  text-decoration: none;
  @extend .amig-body2-web-regular;
  @extend .purple-links-states;
}

.stickyCardDetailPhone {
  @extend .amig-body2-web-regular;
  margin: 0px 8px !important;
  @extend .purple-links-states;
  pointer-events: none;
  text-decoration: none;
  color: $color-amig-gray-80;
  @include gw-breakpoint-down(tablet) {
    color: $color-amig-purple-100;
    pointer-events: unset;
  }  
}

.custom-separator {
  margin: 24px 0px;
  border: 1px solid $color-amig-gray-10 !important;
  background: $color-amig-gray-10;
}
