.linkSpace {
    padding: var(--GW-SPACING-6);
}
.billingInvoicesContainer {
    overflow: auto;
}

.gwBillingSummaryContainer {
    width: auto;
}
