@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.disabledModalBg {
    background-color: $color-amig-gray-100;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    opacity: 0.7;
    z-index: 400;
}

.accountPrefModal {
    position: initial;
    top: 0px;
    right: calc((100vw - 676px)/2);
    height: auto;
    width: 676px;
    background: $color-amig-white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    padding: 40px;
    overflow-y: auto;

    @include gw-breakpoint-down(tablet) {
        width: calc(100vw - 136px);
        padding: 40px 40px 40px 24px;
    }

    @include gw-breakpoint-down(phone) {
        width: calc(100vw - 24px);
        padding: 40px 24px;
    }
}

.accountPrefModalTitle {
    color: $color-amig-black !important;
    @include gw-breakpoint-down(tablet) {
        font-size: 20px !important
    }
}

.accountPrefModalSubTitle {
    margin: 24px 0px 21.5px 0px !important;
    color: $color-amig-gray-80;

    @include gw-breakpoint-down(tablet) {
        margin: 16px 0px 20px 0px !important;
    }
}

.tnCContainer {
    @extend .amig-body2-mobile-regular;
    font-family: Arial !important;
    margin: 9.5px 0px 24px 0px;
    color: $color-amig-gray-80;
    max-width: 565px;

    @include gw-breakpoint-down(tablet) {
        margin: 12.5px 0px 24px 0px;
    }

    @include gw-breakpoint-down(phone) {
        margin: 16px 0px;
    }
}


.deliveryTnCContainer {
    @extend .amig-body2-mobile-regular;
    font-family: Arial !important;
    margin: 4px 0px 39px 0px;
    color: $color-amig-gray-80;

    @include gw-breakpoint-down(tablet) {
        margin: -4px 0px 39px 0px;
    }

    @include gw-breakpoint-down(phone) {
        margin: -4px 0px 35px 0px;
    }
}

.tnCText {
    display: contents
}

.tnCLink {
    @extend .text-links-states;
    display: contents !important
}


.submitPrefButton {
    @extend .btn-primary-medium;
    float: right;
    @extend .amig-h6-web-text;
    letter-spacing: 1px;
    box-shadow: none;
    @include gw-breakpoint-down(phone) {
        width: 100%;
    }

}

.accountPrefOptions {

    label {
        margin: 0px;

        >span {
            height: unset;
            width: unset;
            margin: 2.5px 0px 3px 16px;
            @extend .amig-body2-web-regular;           
            border: none;
            color: $color-amig-gray-80;

            &:hover,
            &:active,
            &:focus {
                border: none;
            }

            @include gw-breakpoint-down(tablet) {
                margin: 0px 0px 7.5px 16px;
            }
        }
    }

    span {
        height: 24px;
        width: 24px;
        border: 1px solid $color-amig-gray-100;
        margin: 2.5px 0px 3px 0px;

        &:hover {
            background: $color-amig-lightBlue-5;
            border: 1px solid $color-amig-lightBlue-100;
            box-shadow: none !important;
        }

        &:active,
        &:focus {
            background: $color-amig-white;
            box-shadow: none !important;
            border: 1px solid $color-amig-gray-100;
        }

        @include gw-breakpoint-down(tablet) {
            margin: 0px 0px 7.5px 0px;
        }
    }

    >div>div>div {
        padding-top: 0px !important;
    }

    i {
        display: inline-flex;
        background-color: $color-amig-white !important;
        outline: 2px solid $color-amig-blue-100;
        border-radius: 4px;
        
        &::before {
            font-size: 18px !important;
        }
    }

}


.deliveryMethods {

    label {
        margin: 0px;

        ::before {
            height: 24px;
            width: 24px;
        }
    }

    div {
        margin: 0px 0px 24px 0px !important;

        @include gw-breakpoint-down(tablet) {
            margin: 0px 0px 24px 0px !important;
        }
    }

    span {
        @extend .amig-body2-web-regular;       
        color: $color-amig-gray-80 !important;
    }
}

.timeZoneContainer { 
    @extend .amig-caption-web-regular;
    margin-top: 17px;

    @include gw-breakpoint-down(tablet) {
        margin-top: 12.5px;
    }

    label {
        margin-bottom: 4px
    }

    span[class*='jut__FieldLabel__primaryLabel'] {
        @extend .amig-caption-web-bold;
        color: $color-amig-gray-100
    }

    div[class*='jut__GenericSelectControl__control'] {
        width: 360px;
        padding: 0px 16px;
        font-size: 14px;

        @include gw-breakpoint-down(phone) {
            width: 270px;
        }
    }

    div[class*='css-1uccc91-singleValue'] {
        color: $color-amig-gray-60 !important;
    }

    div[class*='jut__GenericSelectControl__menu'] {
        width: 360px !important;

        @include gw-breakpoint-down(phone) {
            width: 270px !important;
        }

        &:active {
            border: none;
        }
    }

    div[class*='jut__GenericSelectControl__option'] {
        font-size: 14px !important;
    }

}

.timezoneBorder {
    div[class*='jut__GenericSelectControl__control'] {
        border: 2px solid $color-amig-gray-20;
    }
}

.accountPrefModalContainer {
    position: fixed;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    padding: 20px calc((100vw - 676px)/2);
    z-index: 450;

    &::-webkit-scrollbar {
        display: none;
    }

    @include gw-breakpoint-down(tablet) {
        padding: 20px 68px;
    }

    @include gw-breakpoint-down(phone) {
        padding: 24px 12px;
    }
}

.modalError {
    text-align: center;
    padding: 50px 0px 110px 0px !important;

    @include gw-breakpoint-down(phone) {
        padding: 50px 0px 71px 0px !important;
    }
}

.errorHeader {
    color: $color-amig-gray-100 !important;
    margin-bottom: 24px !important;

    @include gw-breakpoint-down(tablet) {
        font-size: 28px !important;
        margin-bottom: 44px !important;
    }
}

.modalErrorMessage {
    @extend .amig-body1-web-regular;
    text-align: center;
    color: $color-amig-gray-80;
    margin-bottom: 40px;

    @include gw-breakpoint-down(tablet) {
        font-size: 16px;
        margin-bottom: 52px;
    }

    @include gw-breakpoint-down(phone) {
        margin: 0px -8px 40px -8px;
    }
}

.modalButton {
    @extend .btn-primary-medium;
    @extend .amig-h6-web-text;
    letter-spacing: 1px;
}

.spinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:128px;
    height:128px;
    margin: 24px 0px 0px 194px;

    @include gw-breakpoint-down(tablet) {
       margin-left: calc(50vw - 196px);
    }

    @include gw-breakpoint-down(phone) {
        margin-left: calc(50vw - 140x);
    }
}