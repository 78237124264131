@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';


.coveredDriversTableTitle {
    @extend .amig-h6-web-text;
    padding-right: 8px;
    color: $color-amig-gray-100;
    height: 40px !important;
    padding-top: 8px;
    @include gw-breakpoint-down(tablet) {
        display: none;
        height: unset !important;
    }
}

.coveredDriversTable{
    @include gw-breakpoint-down(tablet) {
        grid-template-columns: 1fr 1fr 1fr !important;
        grid-row-gap: 0.5rem;
    } 
    @include gw-breakpoint-down(phone) {
        grid-row-gap: unset;
        grid-template-columns: 1fr !important;
    }
}

.coveredDriversDetailGridTitles {
    color: $color-amig-gray-100;
    @extend .amig-h6-web-text;
    margin-bottom: 8px;
    margin-top: 24px;
    display: none;
    @include gw-breakpoint-down(tablet) {
        display: block;
    }
}
.coveredDriversDetailGridValues {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    @include gw-breakpoint-down(tablet) {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    @include gw-breakpoint-down(phone) {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.coveredDriversDetailBorder {
    margin-top: 0px !important;
    @include gw-breakpoint-down(tablet) {
        display: block;
        margin-top: 26px !important;
    }
    
}

.showForTablet {
    display: none;
    @include gw-breakpoint-down(tablet) {
        display: block;
    }
    @include gw-breakpoint-down(phone) {
        display: none;
    }
}