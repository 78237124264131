@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.right-aligned{
    text-align: right !important;

    @include gw-breakpoint-down(tablet) {
        text-align: left !important;
    }
}

.policyDetailTableTitle{
    @extend .amig-h6-web-text;
    height: 40px !important;
    padding-top: 8px;
    color: $color-amig-gray-100;
    @include gw-breakpoint-down(tablet) {
        height: unset !important;
    }
}

.propertyDetailsGridValues{
    @extend .amig-body2-web-regular; 
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    color: $color-amig-gray-80;  
}

.propertyDetailsGridTitles{
    @extend .amig-h6-web-text;
    margin-bottom: 8px; 
    margin-top: 24px;   
}

.property-detail-table-titles{
    padding-right: 8px;
}

.propertyCoverageDetailValue{
    height: 72px !important;
    padding-top: 24px;
    padding-right: 8px;
    @include gw-breakpoint-down(tablet) {
        height: unset !important;
        padding: unset;
    }
}

.property-details-container {
    float: right;
	display:-webkit-box;
    margin-top: -65px;
    @include gw-breakpoint-down(tablet) {
        float:unset !important;
        margin-top: unset;
        height: 24px;
        max-width: fit-content;
}
	span{
		@extend .text-links-states;
	}  
	
	>figure{
		margin-left: 0px;
		margin-right: 8px;
		margin-top: -4px;
        @include gw-breakpoint-down(tablet) {
        margin-top: 6px;
    }	
	}
}

.showCoverageDetailLink{
    @extend .text-link-regular;

    @include gw-breakpoint-down(tablet) {
        margin: 8px 0px 0px 0px !important;
        float: unset !important;

    }
}

.policyCoverageDetailBorder{
    margin-top: 0px !important;

    @include gw-breakpoint-down(tablet) {
        display:none;       
    }
    @include gw-breakpoint-down(phone) {
        display:block;
        margin-top: 26px !important;
    }
}

.policyCoverageDetailUpperBorder{
    display: none;

    @include gw-breakpoint-down(tablet) {
        display:block;
        margin-top: 26px !important;     
    }
    @include gw-breakpoint-down(phone) {
        display: none;
    }
}

.showForTablet{
    display: none;
    @include gw-breakpoint-down(tablet) {
        display:block;       
    }
    @include gw-breakpoint-down(phone) {
        display: none;
    }
}

.propertyDetailsGridTitles{
    display: none;
    @include gw-breakpoint-down(tablet) {
        display:block;       
    }
}