@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.sideBar {
    @include gw-breakpoint-only(phone) {
        display: none;
    }
}

.gwWizard {
    --gw-wizard-min-height: 600px;
    display: inline-block;
    height: 100%;
    width: 100%;
    min-height: var(--gw-wizard-min-height);
    text-align: center;
    transform: translateX(0);
    transition: 0.5s all cubic-bezier(0.32, 1.25, 0.375, 1.15);
}

.wizardSidebar {
    --wizardSidebar-width: 23%;
    --wizardSidebar-margin-right: .4%;

    display: inline-block;
    width: var(--wizardSidebar-width);
    vertical-align: top;
    margin-right: var(--wizardSidebar-margin-right);
    text-align: center;
}

.wizardMain {
    --wizardMain-width: 75%;

    text-align: left;
    display: inline-block;
    width: var(--wizardMain-width);
    vertical-align: top;
}

.wizardSidebarSteps {
    gap: 10px;
    text-align: left;
    border-radius: var(--GW-SPACING-1);
    background: $color-amig-gray-5;
}


.gwsteps {
    --gwsteps-line-height: var(--GW-LINE-HEIGHT-H5);
    line-height: var(--gwsteps-line-height);
}

.ulStyle {
    list-style: none;
    text-align: left;
    margin: var(--GW-SPACING-8);
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.wizardWrapper {
    padding-top: 32px;
    padding-bottom: var(--GW-SPACING-1);
    text-align: left;
    position: sticky;
    position: -webkit-sticky;
    top: 128px;

    @include gw-breakpoint-only(tablet) {
        padding-top: unset;
        top: 68px
    }
}

.gwFaqSection {
    padding-top: var(--GW-SPACING-4);
}

.faqSectionContainer {
    padding-bottom: var(--GW-NEXT-SPACING-12);

    .faqnoresult {
        @extend .amig-body2-web-bold;
        color: $color-amig-gray-100;
        text-align: center;
        padding-top: var(--GW-NEXT-SPACING-10);
    }
}

.wizardLi {
    display: block;
    position: relative;
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    cursor: pointer;
    box-shadow: inset 0px -1px 0px $color-amig-gray-20;

    a {
        @extend .amig-h6-web-text;
        letter-spacing: 0.25px;
        cursor: pointer;
        color: $color-amig-gray-80;
        text-decoration: none;
        display: block;
        padding: 16px 0px 16px 24px;
    }


    a:hover {
        color: $color-amig-lightBlue-100;
    }
}

.wizardLiDisabled {
    cursor: default;

    a {
        color: var(--GW-SEPARATOR-COLOR);
        text-decoration: none;
        pointer-events: none;
    }
}

.wizardLink {
    --wizardLink-width: 100%;

    text-decoration: none;
    width: var(--wizardLink-width);
}

.sideBarTitle {
    --sideBarTitle-margin-top: 15px;
    font-size: var(--GW-FONT-SIZE-H5);
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    line-height: var(--GW-LINE-HEIGHT-H4);
    padding: 0 var(--GW-NEXT-LAYOUT-3);
    margin-bottom: var(--GW-NEXT-LAYOUT-3);
    margin-top: var(--GW-NEXT-SPACING-5);
    border-bottom: var(--GW-BORDER-WIDTH-THIN) solid var(--GW-SEPARATOR-COLOR);
    display: block;
}

.gwSectionInactive {
    pointer-events: none;
    color: var(--GW-SEPARATOR-COLOR) !important;
}

.gwSectionActive {
    background-color: var(--GW-COLOR-INFO-CONTRAST)
}

.gwPageSectionTitle {
    color: $color-amig-gray-100 !important;
    padding-bottom: var(--GW-SPACING-2);
    margin-left: 44px !important;
    margin-bottom: var(--GW-SPACING-8);
    margin-top: -16px !important;

    @include gw-breakpoint-only(tablet) {
        margin-left: var(--GW-SPACING-5);
        margin-top: unset;
    }

    @include gw-breakpoint-only(phone) {
        margin: 20px 0 0 16px !important;
        font-size: 20px !important;
        line-height: 28px !important;
    }
}

.searchTopic {
    padding: 0px;
    margin: 32px 0px 40px 44px;
    border-radius: 2px;
    border: 1px solid $color-amig-gray-20;
    box-shadow: none;

    @include gw-breakpoint-only(tablet) {
        margin: var(--GW-SPACING-6);
    }

    @include gw-breakpoint-only(phone) {        
        margin: 32px 16px 40px 16px !important;
    }
}

.searchTopicInputField {
    input {
        background-color: $color-amig-white !important;
        padding: 0px 24px !important;
        height: 64px !important;
        border: none;
        --gw-search-input-width: 100%;
        width: 100% !important;

        @extend .amig-body1-web-regular;
        color: $color-amig-gray-60;
    }

    i {
        left: auto !important;
        right: 1.5rem !important;
    }
}

.activeSection {
    background: $color-amig-white;
    box-shadow: 0px -1px 0px $color-amig-white;

    a {
        color: $color-amig-blue-100 !important;
    }
}