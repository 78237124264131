@import "~@jutro/theme/assets/sass/helpers";
@import "~amig-digital-theme-styles/styles/core";

.policy-card-input-data {
  @extend .amig-body2-web-regular;
  color: $color-amig-gray-80;
  letter-spacing: 0.5px;
  padding: 32px 0px 0px 0px;
  width: 224px !important;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  div {
    padding: 0 !important;
    color: $color-amig-gray-80;
  }

  @include gw-breakpoint-down(tablet) {
    padding: 24px 0px 0px 0px;
    width: unset !important;
  }  
}

.address-data{
  @extend .amig-h6-web-text;
  @include gw-breakpoint-down(tablet) {   
    margin-bottom: 4px !important; 
  }    
}

.policy-detail-label{
  @extend .amig-h6-web-text;
  color: $color-amig-gray-100 !important;
  @include gw-breakpoint-down(tablet) {   
    margin-bottom: 8px;
  }
}

.policy-card-input-title {
  @extend .amig-h5-web-text;
  color: $color-amig-gray-100 !important;
  @include gw-breakpoint-down(tablet) {
    font-size: 16px !important;
  }
}

.policy-card-address-title{
  >div{
    margin: 0px !important;
  }
}

.policy-detail-column {
  padding: 0px 112px;

  @include gw-breakpoint-down(tablet) {    
    padding: 0px 21px;
  }

  @media (max-width: 768px) {    
    padding: 0px;
  }
  
}

.policy-detail-column-border {
  border-right: 1px solid $color-amig-gray-10;

  @media (max-width: 768px) {
    border: none;
  }
}

.policy-detail-column-no-padding {
  padding: 0px;
}

.policy-detail-phone-number {
  padding-top: 16px;

  @include gw-breakpoint-down(tablet) {   
    padding-top: 8px ;
  }

  @include gw-breakpoint-down(phone) {
    padding-top: 24px ;
  }
}

.policyDetailsGridSeparator {
  display: none;
  margin: 26px 0px 24px 0px !important;

  @media (max-width: 768px) {
    display: block;
  }
}

.notificationMargin {
  margin-top: 16px;
  margin-bottom: 32px;
  @include gw-breakpoint-down(tablet)  {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
