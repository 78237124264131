@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.policyDocDelivery {
    margin: 16.5px 0 24px 0px;

    @include gw-breakpoint-down(tablet) {
        margin: 8px 0 24px 0px;
    }
}

.labelColumn {
    @extend .amig-h6-web-text;
    width: 300px;
    display: inline-block;
    color: $color-amig-gray-100;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    @media (max-width: 680px) {
        width: 100%;
        margin-bottom: 8px !important;
    }
}

.deliveryTypeLabel {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }

}

.docDetails {
    display: inline-block;
}

.docDeliveryTnC {
    @extend .amig-body2-web-regular;
    display: inline !important;

    @include gw-breakpoint-down(tablet) {  
        font-size: 14px !important;
        line-height: 20px !important;      
    }
}

.tnCContainer{
    margin-bottom: 14.5px;
    @include gw-breakpoint-down(tablet) {  
        margin-bottom: 32px;     
    }
    @include gw-breakpoint-down(phone) {  
        margin-bottom: 31px;     
    }
}

.paperlessLabel {    
    color: $color-amig-gray-80;
}

.TncLink {
    @extend .text-links-states;    
    @include gw-breakpoint-down(tablet) {  
        margin: 0px;
    }
}

.editDocDelivery {
    margin-left: 16px;
    @extend .text-link-medium;

    @include gw-breakpoint-down(tablet) {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.docFormContainer {
    margin-left: 300px;

    @media (max-width: 680px) {
        margin-left: 0px;
    }
}

.deliveryMethodText {
    @extend .amig-caption-web-bold;
    margin-bottom: 4px;
    color: $color-amig-gray-100 !important;
    @include gw-breakpoint-down(tablet) {
        font-size: 12px !important;
    }  
}

.cancelButton {
    @extend .btn-secondary-medium;
    margin-top: 22px;
    background-color: $color-amig-white !important;
}

.saveChangesButton {
    @extend .btn-primary-medium;
    margin-left: 24px;
    margin-top: 22px;

    &:disabled {
        opacity: 1;
    }
}

.deliveryMethods {

    label {
        margin: 0px;

        ::before {
            height: 24px;
            width: 24px;
        }
    }

    div {
        margin: 24px 0px 22px 0px !important;
    }

    span {
        @extend .amig-radio-body2-web-regular;
        color: $color-amig-gray-80 !important;
    }
}

.warningMessage {
    @extend .alert-sectional-notification;
    margin-left: -300px;
    width: 925px;
    margin-top: 0px;

    @include gw-breakpoint-down(tablet) {
        width: calc(100vw - 106px);
    }

    @media (max-width: 680px) {
        width: calc(100vw - 80px);
        margin-left: 0px;
    }

}

.closeWarningMessage {
   
    margin: 5px 0px 0px -27.5px;
    z-index: 0;
    height: 24px;
    width: 24px;

    i{
           color: $color-amig-gray-100 !important;
    }

    &:active,
    &:hover,
    &:focus {
        background: none;
        box-shadow: none;
    }
}

.showWarningMessage {
    display: inline-flex;    
}

.spinner {
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width:128px;
    height:128px;
}