@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.amigActiveTab{
    color: $color-amig-blue-100 !important;
    border-bottom: 4px solid $color-amig-blue-100 !important;
    &:hover{
        color: $color-amig-blue-100 !important;
    }
}

.amigTabHeader{
    color: $color-amig-gray-40 !important;
    &:hover{
        color: $color-amig-gray-80 !important; 
    }
    &:focus{      
        box-shadow: none !important;      
    }
}