@import "~@jutro/theme/assets/sass/helpers";
@import "~amig-digital-theme-styles/styles/core";

h2[class='policyDocumentTitle'] {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: 0;
}

.policyDocumentContainer:not(:last-child) {
    margin-bottom: var(--GW-SPACING-8);
}


.rotatepage {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .rotatepagecontent {
	text-align: center;
	padding-top: 45px;
  }

  .rotatepageimage {
	width: 61.79px;
	height: 74.34px !important;
	margin-bottom: 20px;
  }

  .rotatemessageheader {
	  @extend .amig-h3-web-text;
	  text-align: center !important;
	  color: $color-amig-gray-100 !important;
	  padding-bottom: 24px;

  }
  
  .rotatepagemessage {
	@extend .amig-body1-mobile-regular;
	color: $color-amig-gray-100 !important;
	padding-bottom: 40px;
	width: 253px;
	height: 72px;
  }

  .signRequired {
	vertical-align: sub !important;
	margin-left: 16px !important;
  }

  .word-wrap {
	  white-space: normal !important;
  }

  .rowStyleBorder { 
	@extend .word-wrap;
    box-shadow: inset 0px -2px 0px $color-amig-gray-10 !important;    
    padding: 36px 0px !important;
    height: 100%;

	span {
		font-family: Arial !important;
	}
}

.rowStyle { 
	@extend .word-wrap;
    padding: 36px 0px !important;
}

.linkCell {
	@extend .text-links-states;
	color: $color-amig-blue-100 !important;
}


div[class*='jut__FilterBar__filterItem'] {
	label>span {
		@extend .amig-caption-web-bold;
		color: $color-amig-gray-100 !important;

		@include gw-breakpoint-down(tablet) {
			font-size: 12px;
		}
	}
}

div[class*='jut__GenericSelectControl__control'] {
	div {
		@extend .amig-body2-web-regular;
		color: $color-amig-gray-100;

		@include gw-breakpoint-down(tablet) {
			font-size: 14px;
		}
	}
}

button[id*='applyFilter'] {
    display: none;
}