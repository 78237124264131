@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.amig-footer-margin{
    height: 60px;
}

.cp-footer-margin {
    border-bottom: solid 1px $color-amig-gray-20;  
    background-color: $color-amig-white; 
    height: 60px;
}

.amig-footer {
    background-color: $color-amig-white;
    border-top: solid 1px $color-amig-white;
    min-height: 140px;
    padding: 64px 80px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;

    @include gw-breakpoint-down(tablet) {
        padding: 32px 16px;
    }

    background: $color-amig-white;


    .section-header {
        color: $color-amig-gray-custom;
        font-family: $font-amig-raleway;
        font-size: 14px;
        font-style: normal;
        font-variant: normal;
        font-weight: bold;
        text-transform: uppercase;
    }

    a {
        font-size: 14px;
    }

    a:not([href]) {
        cursor: pointer;
    }

    .main-footer__faq {
        margin-bottom: 20px;
    }

    .main-footer__copyright-text {
        padding: 0px !important;
        color: $color-amig-gray-80;
        @extend .amig-body2-web-regular;
        text-align: right;

        @include gw-breakpoint-down(tablet) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 16px;
            text-align: center;
        }
    }

    .hrStyle {
        margin: 50px 0px 48px 0px;
        border: 1px solid $color-amig-gray-20;
        background-color: $color-amig-gray-20;

        @include gw-breakpoint-down(tablet) {
            margin: 34px 0px 32px 0px;
        }

    }

    .main-footer-content__legal-nav {

        color: $color-amig-gray-80;
        display: inline-block;
    
        @include gw-breakpoint-down(tablet) {
            margin-left: auto;
            margin-right: auto;
        }

        @media (max-width: 367px) {
            height: 48px;
        }

        .main-footer-content__legal-nav-item {
            display: inline-block;
            list-style: none;
            margin-right: 10px;
            padding-right: 0px;            
            color: $color-amig-gray-80;
            @extend .amig-body2-web-regular,
            .nav-links-states;

            @include gw-breakpoint-down(tablet) {
                margin-right: 5px;
                margin-left: 5px;
            }

            span {
                text-align: right;
            }
        }

        .main-footer-content__legal-nav-item span {
            text-decoration: none !important;
        }

        .disableForPortrait {
            @extend .main-footer-content__legal-nav-item;
            @media (max-width: 450px) {
            display: none ;
            }
        }
    }

    .parent-logo {
        background: transparent;
        box-shadow: none;
    }

    .logo {
        width: 170px;
        height: 48px;
        margin-bottom: 40px;

        @include gw-breakpoint-down(tablet) {
            width: 140px;
            height: 40px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            display: block;
        }
    }

    .logo-grid {
        height: 120px !important;

        @include gw-breakpoint-down(tablet) {
            height: unset !important;
        }
    }

    .social-icons-tray {
        height: 24px;
        width: 120px;
        border-radius: 0px;

        @include gw-breakpoint-down(tablet) {
            display: flex;
            width: 80px;
            margin-right: auto;
            margin-left: auto;
            height: 16px;
        }
    }

    .positioning-bg-img {
        background-position: center;
        background-repeat: no-repeat;
    }

    .social-media-icons-1 {
        background-image: url('../../../public/footer/facebook-desktop.png');
        display: flex !important;
        box-shadow: none;
        height: 24px;
        width: 24px;
        margin-right: 24px;
        border-radius: 0px;
        @extend .positioning-bg-img;

        @include gw-breakpoint-down(tablet) {
            background-image: url('../../../public/footer/facebook-mobile.png');
            height: 16px;
            width: 16px;
            margin-right: 16px;
        }
    }

    .social-media-icons-2 {
        background-image: url('../../../public/footer/twitter-desktop.png');
        box-shadow: none;
        height: 24px;
        width: 24px;
        margin-right: 24px;
        border-radius: 0px;
        @extend .positioning-bg-img;

        @include gw-breakpoint-down(tablet) {
            background-image: url('../../../public/footer/twitter-mobile.png');
            height: 16px;
            width: 16px;
            margin-right: 16px;
        }
    }

    .social-media-icons-3 {
        background-image: url('../../../public/footer/instagram-desktop.png');
        box-shadow: none;
        height: 24px;
        width: 24px;
        border-radius: 0px;
        @extend .positioning-bg-img;

        @include gw-breakpoint-down(tablet) {
            background-image: url('../../../public/footer/instagram-mobile.png');
            height: 16px;
            width: 16px;
        }
    }

    .commonFooterFontStyle {
        @extend .nav-link-regular;
    }

    .commonFooterFontStyle span {
        text-decoration: none !important;
    }

    .disableForMobile {
        @include gw-breakpoint-down(tablet) {
            display: none;
        }
    }

    .footerHeader {
        width: 193px;
        height: 24px;
        left: 0px;
        top: 0px;
        letter-spacing: 1px;
        color: $color-amig-gray-100;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    .subFooterDiv {
        background-color: $color-amig-white;
        margin-top: 48px;

        @include gw-breakpoint-down(tablet) {
            text-align: center;
            margin-top: 32px;
        }

        ;

        @include gw-breakpoint-down(phone) {
            text-align: center;
        }
    }

    .gridCustomStyle {

        letter-spacing: 0.2px;
        color: $color-amig-gray-100;
    }

    .takeActionGridStyle {
        width: 193px;
        height: 24px;
        left: 0px;
        top: 48px;
        line-height: 24px;
        color: $color-amig-gray-80;
    }

    .disclosure-ParentBlock {
        padding: 50px 0 0;
        padding: 16px 0 0;
        margin: 0 80px 20px 0 !important;
        height: auto !important;

        @include gw-breakpoint-down(tablet) {
            margin: 0 16px 0 !important;
            padding: 16px 0 0;
        }

        @include gw-breakpoint-down(phone) {
            height: 295px !important;
        }
    }

    .disclosure-block1 {
        margin-bottom: 20px;

    }

    .footer-legal-mainBlock {
        @extend .amig-body2-web-regular;
    }

    .grid-footer {
        color: $color-amig-gray-80;
        height: auto;
        display: block;
    }
}

.legalLinks {
    line-height: 20px !important;

    @include gw-breakpoint-down(tablet) {
        line-height: 14px !important;
    }
}

.mobileAccordionLinks {
    @extend .amig-body1-mobile-regular;
    @extend .commonFooterFontStyle;
    width: 100%;
    justify-content: left !important;
    margin-top: 9px;
    -webkit-tap-highlight-color: transparent;
    span{
        padding-bottom: 6px;
        line-height: 18px;
    }
    span:hover {       
        padding-bottom: 4px;        
        border-bottom: 2px solid $color-amig-gray-80;
    }
}

.customAccordionHeading  {
    @extend .amig-h6-web-text;
    color: $color-amig-gray-100;
    -webkit-tap-highlight-color: transparent;
    height: 24px;
    
    div[class*='jut__Accordion__cardTitle'] { 
        padding: 0px 0px 3px;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }    
}

.customAccordionBody {
    margin: 3px 0px -26px 0px;
}

.customFooterAccordion {
    margin: 14px auto 36px auto;
    width: 100%;

    @media (min-width: 1201px) {
       display: none;
    }

    @include gw-breakpoint-down(tablet) {
        max-width: 295px;
    }

    @include gw-breakpoint-down(phone) {
        margin: 15px auto 36px auto;
        max-width: calc(100vw - 80px);
    }

    div[class*='isOpen'] {
        border-bottom: none;
    }

}

.customAccordionCard {
    padding: 24px 0px 26px 0px;
    border-bottom: 2px solid $color-amig-gray-20;

    i[class*='jut__Accordion__chevron'] {
        transform: rotateZ(90deg);
    }

    i[class*='jut__Chevron__open'] {
        transform: rotateZ(270deg);
    }   
}

.footerGrid {
    @media (min-width: 1201px){
        grid-template-columns: 1.5fr 4fr !important;
    }
}

.informationPractices { 
    @media (max-width: 450px) {
         display: block !important;
  }
}

.footerRows {
    a span {
        padding-bottom: 6px;
    }
    a span:hover {
        padding-bottom: 4px;
        border-bottom: 2px solid $color-amig-gray-80;
    }
}
