@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.vehicleCoverageDetailTitle {
    @extend .amig-h3-web-text;
    padding-bottom: 1rem;
    display: inline;

    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
        line-height: 24px !important;
    }

    @include gw-breakpoint-down(phone) {
        font-size: 16px !important;
    }
}

.vehicleCoverageDetailGridValues {
    @extend .amig-body2-web-regular;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    color: $color-amig-gray-80;

    @include gw-breakpoint-down(tablet) {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }

    @include gw-breakpoint-down(phone) {
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.vehicleCoverageDetailGridTitles {
    @extend .amig-h6-web-text;
    padding-bottom: 1.5rem;
    color: $color-amig-gray-100;
    @include gw-breakpoint-down(tablet) {
        padding-bottom: 8px; 
    }
}

.vehicle-policy-container {
    float: right;
    display: -webkit-box;
        margin-top: -4.375rem;
        @include gw-breakpoint-down(tablet) {
            float: unset !important;
            margin-top: unset;
            height: 24px;
            max-width: fit-content;
        }
	span{
		@extend .text-links-states;
	}  
	
	>figure{
		margin-left: 0px;
		margin-right: 8px;
		margin-top: -4px;	
	}
}

.vehicleCoverageDetailLink{
    @extend .text-link-regular;
    font-weight: 400 !important;
    background: transparent !important;
    box-shadow: none;
        @include gw-breakpoint-down(tablet) {
            float: none;
            margin-top: 0rem !important;          
            padding: 0rem 0rem !important;
            height: 18px !important;
        }
    
        @include gw-breakpoint-down(phone) {
            float: none;
            margin-top: 0rem !important;
        }
}

.detailGrid{
    @include gw-breakpoint-down(tablet) {
      margin-top: 24px;
    }    
}

.hideLabel {
    display: none;
    
    @include gw-breakpoint-down(tablet) {
        display: block;
    }

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.spinner {
    display: block;
    margin-top: 35vh;
    margin-left: calc(50vw - 104px);
    width:128px;
    height:128px;
}
