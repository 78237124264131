//button states
.primary-btn-states {
  background-color: $color-amig-blue-100;
  &:hover,
  &:active {
    text-decoration: none !important;
    background-color: $color-amig-lightBlue-100 !important;
  }
  &:disabled {
    background: $color-amig-blue-40;
    color: $color-amig-white;
    border-radius: 2px;
    pointer-events: none;
  }
}

.secondary-btn-states {
  &:hover,
  &:active {
    background: $color-amig-white;
    color: $color-amig-lightBlue-100;
    border: 2px solid $color-amig-lightBlue-100;
  }
  &:disabled {
    border: 2px solid $color-amig-blue-40;
    background: $color-amig-white;
    color: $color-amig-blue-40;
    pointer-events: none;
  }
}

.circle-btn-states {
  &:hover {
    background: $color-amig-lightBlue-100;
    color: $color-amig-white;
  }
  &:disabled {
    background: $color-amig-blue-40;
    color: $color-amig-white;
    pointer-events: none;
  }
}

//buttons
.btn-primary-small {
  height: 40px;
  border-radius: 2px;
  padding: 10px 16px;
  @extend .amig-body2-mobile-regular, .primary-btn-states;
}

.btn-primary-medium {
  height: 48px;
  color: $color-amig-white;
  border-radius: 2px;
  padding: 12px 24px;
  @extend .amig-body2-web-regular, .primary-btn-states;
}

.btn-primary-large {
  height: 56px;
  border-radius: 2px;
  padding: 14px 32px;
  @extend .amig-body1-web-regular, .primary-btn-states;
}

.btn-primary-small-fullWidth {
  height: 40px;
  border-radius: 2px;
  padding: 10px 16px;
  @extend .amig-body2-mobile-regular, .primary-btn-states;
}

.btn-primary-medium-fullWidth {
  height: 48px;
  border-radius: 2px;
  padding: 12px 24px;
  @extend .amig-body2-web-regular, .primary-btn-states;
}

.btn-primary-large-fullWidth {
  height: 56px;
  border-radius: 2px;
  padding: 14px 32px;
  @extend .amig-body1-web-regular, .primary-btn-states;
}

.btn-secondary-small {
  height: 40px;
  border-radius: 2px;
  padding: 8px, 16px, 8px, 16px;
  @extend .amig-body2-mobile-regular, .secondary-btn-states;
}

.btn-secondary-medium {
  height: 48px;
  border-radius: 2px;
  padding: 12px 24px;
  color: $color-amig-blue-100;
  background: $color-amig-white;
  border: 2px solid $color-amig-blue-100;
  @extend .amig-body2-web-regular, .secondary-btn-states;
}

.btn-secondary-large {
  height: 56px;
  border-radius: 2px;
  padding: 14px, 32px, 14px, 32px;
  @extend .secondary-btn-states;
}

// icon buttons
.btn-circle-default {
  height: 48px;
  width: 48px;
  border-radius: 104px;
  padding: 12px;
  background-color: $color-amig-blue-100;
  @extend .circle-btn-states;
}