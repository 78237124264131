@font-face {
    font-family: 'Raleway Variable';
    font-weight: 200 900;
    font-style: normal;
    font-stretch: normal;
    src:
        url('#{$raleway-root}/TTF/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway Variable';
    font-weight: 200 900;
    font-style: italic;
    font-stretch: normal;
    src:
        url('#{$raleway-root}/TTF/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
}
