.chevronStyle {
    top: var(--GW-SPACING-1);
}
.accordionTitle {
    margin-left: var(--GW-SPACING-5);
}
.accordionMainTitle {
    margin-right: calc(1.5 * var(--GW-SPACING-5));
    font-size: var(--GW-FONT-SIZE-H2);
}
.renderAccordionHeader {
    .title {
        font-size: calc(3 * var(--GW-SPACING-3));
    }
}
.titleSeparator {
    margin: var(--GW-SPACING-4) 0;
}
.cardBodyMainMargins {
    margin: var(--GW-SPACING-2);
}
.cardBodyMargins {
    margin: var(--GW-SPACING-2) 0;
    margin-left: var(--GW-SPACING-8);
}
.gridMargin {
    --gw-quote-change-grid-width: 99%;
    margin-left: calc(3 * var(--GW-SPACING-4));
    width: var(--gw-quote-change-grid-width);

    .rootLabel{
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
}
.gridColumn {
    text-align: right;
    padding-right:var(--GW-SPACING-10);

    &:last-child {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
}
.gridTitle {
    border-bottom: calc(0.25 * var(--GW-SPACING-1)) solid var(--GW-BORDER-COLOR);
    margin-bottom: var(--GW-SPACING-4);
    padding-bottom: var(--GW-SPACING-2);

    &:first-child {
        padding-left: var(--GW-SPACING-8);
    }

    &:not(:first-child) {
        text-align: right;
    }
}
