@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';
@import '../../Claims.module.scss';

.claimDetailsPageBreadcrumb {
	display: none;

	@include gw-breakpoint-down(tablet) {
		display: block;
	}
}

.claimTitle {
	@extend .amig-h3-mobile-text;
	margin-bottom: 32px;
	color: $color-amig-gray-100;
	display: none;

	@include gw-breakpoint-down(tablet) {
		display: block;
	}
}

.trackClaimsTitle {
	@extend .amig-h1-web-text;
	color: $color-amig-gray-100 !important;
	margin-bottom: 6px;
	display: inline-block;

	@include gw-breakpoint-down(tablet) {
		display: none;
	}
}

.backToClaimsButton {
	float: right;
	background-color: transparent !important;
	margin-top: 23.25px;

	&:focus {
		box-shadow: none;
	}

	@include gw-breakpoint-down(tablet) {
		display: none;
	}
}

.trackClaimsPolicyType {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100;
	display: inline-block;

	@include gw-breakpoint-down(tablet) {
		display: block;
		font-size: 20px;
		line-height: 28px;
	}
}

.trackClaimsPolicyNumber {
	@extend .amig-body2-web-bold;
	display: inline-block;
	color: $color-amig-gray-60;
	margin-left: 16px;

	@include gw-breakpoint-down(tablet) {
		margin-left: 0px;
		margin-top: 8px;
		font-weight: 400;
	}
}

.trackClaimsPolicyNumberLabel {
	@extend .amig-h6-web-text;
	color: $color-amig-gray-100;
	display: none;

	@include gw-breakpoint-down(tablet) {
		display: block;
		margin-top: 8px;
	}
}

.trackClaimsPolicyNumberTooltip {
	display: contents;
}

.claimDetailsPolicy {
	@include gw-breakpoint-down(tablet) {
		display: inline-block;
	}
}

.trackClaimsViewPolicyButton {
	display: block;
	margin-top: 8px;

	@include gw-breakpoint-down(tablet) {
		display: inline;
		float: right;
		margin-top: 0px;
	}

	@include gw-breakpoint-down(phone) {
		display: none;
	}

	&:focus {
		box-shadow: none;
	}
}

.copy-content {
	height: 24px;
	width: 24px;
}

.hideTooltip {
	display: inline;
	margin-left: 5.5px;

	@include gw-breakpoint-down(tablet) {
		>div>div {
			display: none !important;
		}
	}
}

.claim-status-card {
	margin: 45px 0px 40px;
	padding: 2.5rem 2.875rem 3.5rem !important;
	height: 100%;
	background: $color-amig-white;
	/* Shadow/Base */
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
		height: auto !important;
	}

	@include gw-breakpoint-down(phone) {
		padding: 24px 24px 8px 18px !important;
	}

}

.claim-status-card-mobile {
	margin: 40px 0px;
	padding: 2.5rem 2.875rem 3.5rem !important;
	height: 100%;
	background: $color-amig-white;
	/* Shadow/Base */
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 40px 0px;
		padding: 24px 24px 24px 18px !important;
		height: auto !important;
	}

	@include gw-breakpoint-down(phone) {
		padding: 24px 24px 24px 18px !important;
	}

}

.claim-issued-payments {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-100 !important;
    flex: none;
    align-self: stretch;
    flex-grow: 0;

	@include gw-breakpoint-down(tablet) {
		margin-top: unset;
		margin-bottom: 0px !important;
	}

	@media (max-width: 769px) {
		margin-bottom: 24px !important;
	}
}

.claim-status-title {
	color: $color-amig-gray-100 !important;
	margin-bottom: 32px !important;

	@include gw-breakpoint-down(tablet) {
		margin-bottom: 24px !important;
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
		display: inline-block;
	}

	@include gw-breakpoint-down(phone) {
		font-size: 16px !important;
	}
}

.claim-status {
	@extend .amig-body2-web-regular;
	color: $color-amig-gray-100;
	flex: none;
	align-self: stretch;
	flex-grow: 0;
	margin: 16px 0px 0px 0px !important;
}

.headerContainer {
	display: contents;
}

.claimDetailsPageContainer {
	padding: var(--GW-SPACING-3) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);

	div[class="jut__TabSet__content"] {
		margin-top: var(--GW-SPACING-6);
	}
}

.claimDetailsSection:not(:last-child) {
	margin-bottom: var(--GW-SPACING-8);
}

.idCardContainer {
	display: flex;
	justify-content: flex-end;
}

.claimBackButton {
	margin-bottom: var(--GW-SPACING-2);

	i {
		position: relative;
		top: calc(1.25 * var(--GW-SPACING-1));
	}
}

h2[class="claimDetailSectionTitle"] {
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-SPACING-3);
}

.claimChangeButtonContainer {
	margin-top: var(--GW-SPACING-6);
}


.claimAvatar img {
	height: 24px !important;
	width: 24px !important;
	background: $color-amig-blue-100;
}

.claimAvatar {
	height: 48px !important;
	width: 48px !important;
	background-color: $color-amig-blue-100 !important;
	border-radius: 50%;
	padding: 7px !important;
	margin-bottom: 8px;
	pointer-events: none;

	@include gw-breakpoint-down(tablet) {
		margin-bottom: 0px;
	}
}

.adjuster-columns {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include gw-breakpoint-down(phone) {
		grid-template-columns: repeat(1, 1fr);
	}

	@include gw-breakpoint-down(tablet) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.adjuster-columns-hidden {
	display: none;
}

.claim-adjuster-card {
	margin: 40px 0px;
	padding: 40px 34px 0px 34px !important;
	height: 360px !important;
	background: $color-amig-white;
	/* Shadow/Base */
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-green-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
		height: auto !important;
	}

	@include gw-breakpoint-down(phone) {
		padding: 24px 24px 8px 18px !important;
	}

}

.claim-adjuster-card-minified {
	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-lightGreen-100;
	}
}

.contact-adjuster--questions-title {
	@extend .amig-h2-web-text;
	color: $color-amig-gray-100 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 20px !important;
		line-height: 28px !important;
		margin-top: 1px !important;
		display: inline-block;
		margin-bottom: 0 !important;
	}

	@include gw-breakpoint-down(phone) {
		font-size: 16px !important;
		margin-bottom: 0 !important;

	}
}

.contact-adjuster-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;
	margin-bottom: 2.5rem !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
		display: inline-block;
		margin-bottom: 0 !important;
	}

	@include gw-breakpoint-down(phone) {
		font-size: 16px !important;
		margin-bottom: 0 !important;

	}
}

.contact-adjuster-subtitle {
	@extend .amig-body1-web-regular;
	margin: 40px 0px !important;
	color: $color-amig-gray-80 !important;

	@include gw-breakpoint-down(tablet) {
		display: none;
	}

	@include gw-breakpoint-down(phone) {
		display: none;
	}
}

.toggle-card-wrapper {
	display: contents;

	figure {
		margin: 0px !important;
		display: inline-block !important;
		float: right !important;
	}
}

.card-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;
		margin-top: 1px !important;
		display: inline-block;
	}
}

.claim-columns-hidden {
	display: none;
}

.claim-columns {
	margin-top: 40px;

	@include gw-breakpoint-down(tablet) {
		margin-top: 0px;
	}
}

.claim-contacts-columns-hidden {
	display: none;
}

.claim-contacts-card {
	margin: 48px 0px;
	padding: 40px;
	background: $color-amig-white;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	border-left: 6px solid $color-amig-blue-100;

	@include gw-breakpoint-down(tablet) {
		margin: 16px 0px;
		padding: 24px 24px 40px 18px !important;
	}
}

.claim-contacts-card-minified {

	@include gw-breakpoint-down(tablet) {
		height: 72px !important;
		border-left: 6px solid $color-amig-blue-100
	}

}

.claim-contact-title {
	@extend .amig-h3-web-text;
	color: $color-amig-gray-100 !important;
	letter-spacing: 0px;
	text-align: left;
	display: inline-block;

	@include gw-breakpoint-down(tablet) {
		font-size: 16px !important;
		line-height: 24px !important;

		margin-top: 1px !important;
		display: inline-block;
	}
}