@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';


.right-aligned {
    text-align: right !important;
}

.left-aligned {
    text-align: left !important;
}

.payment-schedule-entry {
    display: contents;

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.paymentScheduleGridValues {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    display: block;

    @include gw-breakpoint-down(phone) {
        text-align: right !important;
    }

    >div {
        >div {
            padding: 24px 0px;
        };

        @include gw-breakpoint-down(phone) {
            >div {
                padding: 0px 0px 0px 0px;
            };
            width: 50% !important;
            margin: 0px;
            display: inline-block;
        }
    }
}

.paymentScheduleGridTitles {
    display: none;
    @extend .amig-h4-mobile-text;
    margin-bottom: 8px;
    margin-top: 24px;

    @include gw-breakpoint-down(phone) {
        display: block;
        text-align: left !important;
        margin-bottom: 8px;
        margin-top: 8px;
    }
}

.payment_chevron {
    background: none;
    color: $color-amig-blue-100;
    box-shadow: none;
    width: 24px;
    height: 24px;
    margin: 6px 0px 0px 12px;
    padding: 0px;

    &:active,
    &:focus,
    &:hover {
        -webkit-tap-highlight-color: transparent;
        background: none !important;
        color: $color-amig-blue-100;
        box-shadow: none;
    }

    display: none;

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.chevron-flip {
    transform: rotate(180deg);
}

.displayDetails {
    display: contents;

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.hideDetails {
    display: contents;

    @include gw-breakpoint-down(phone) {
        display: none;
    }
}