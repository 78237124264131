@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.account-information-card-sub-header {
	color: $color-amig-gray-100 !important;
	margin-top: 24px !important;
	margin-bottom: 40px !important;
	display: inline-block;

	@include gw-breakpoint-down(tablet) {
		display:none;
	}

	@include gw-breakpoint-down(phone) {
		display:none;
	}
}

.securityDetails {
    display: block;
	margin: 16px 0px 0px 0px;
}

.editSecurity {
    @extend .text-link-medium;
	display: inline-flex;
    margin-left: 24px;

	@include gw-breakpoint-down(tablet) {
		font-size: 14px;
		line-height: 20px;
		margin-left: 16px;
	}

	@media (max-width: 688px) { 
		vertical-align: bottom;
	}

	@media (max-width: 389px) { 
		display: table !important;
		margin-left: 0px;
	  }
}

.editUserID {
	@extend .text-link-medium;
	display: inline-flex;
	margin-left: 24px;

	@include gw-breakpoint-down(tablet) {
		font-size: 14px;
		line-height: 20px;
		margin-left: 16px;
	}

	@media (max-width: 688px) {
		vertical-align: bottom;
	}

	@media (max-width: 389px) {
		display: table !important;
		margin-left: 0px;
	}
}

.userIdCardLabel {
	@extend .amig-h6-web-text;
	color: $color-amig-gray-100;
	width: 300px;
	height: 24px;
	margin-right: 8px !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 14px !important;
		line-height: 20px !important;
		height: 20px;
	}

	@media (max-width: 688px) {
		width: fit-content;
	}
}

.passwordCardLabel {
	@extend .amig-h6-web-text;
	color: $color-amig-gray-100;
	width: 300px;
	height: 24px;
	margin-right: 8px !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 14px !important;
		line-height: 20px !important;
		height: 20px;
	}

	@media (max-width: 688px) {
		width: fit-content;
	}
}

.userIdInputData {
	@extend .amig-body2-web-regular;
	display: inline-flex;
	color: $color-amig-gray-80 !important;
	min-width: 32ch;

	@include gw-breakpoint-down(tablet) {
		font-size: 14px !important;
		line-height: 20px !important;
	}

	@media (max-width: 688px) {
		display: inline-block;
		vertical-align: top;
	}
}

.passwordInputData {
	@extend .amig-body2-web-regular;
	display: inline-flex;
	min-width: 32ch;
	color: $color-amig-gray-80 !important;

	@include gw-breakpoint-down(tablet) {
		font-size: 14px !important;
		line-height: 20px !important;
	}

	@media (max-width: 688px) { 
		display: inline-block;
		vertical-align: top;
	}
}