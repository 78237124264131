@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';


.linkCell{
	@extend .text-link-regular;
    text-decoration: none;
}

.mobileTableTitle {
    @extend .amig-h6-web-text;
    span {
       font: inherit; 
    }
}

.mobileTableCell {
    @extend .amig-body2-web-regular;
    span {
        font: inherit; 
    }
    margin-bottom: 20px;
}

.mobileClaimsTableSeparator {
    margin-bottom: 24px !important;
}

.noClaimsFoundMessage{
    @extend .amig-body1-mobile-regular;
    color: $color-amig-gray-80;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 2px solid $color-amig-gray-10;
}