@import "~amig-digital-theme-styles/styles/amig-colors.scss";
@import "~amig-digital-theme-styles/styles/amig-typography.scss";

.amigRoot{
    --GW-FONT-FAMILY: 'Raleway', sans-serif;
    --GW-BURGER-SIDEBAR-WIDTH: 100%;
    --GW-DROPDOWN-MENU-AVATAR-WIDTH: 411px;
    --GW-BACKGROUND-COLOR-BODY: #{$color-amig-gray-5};
    --GW-PAGINATION-COLOR: #{$color-amig-gray-80};
    --GW-BREAKPOINT-PHONE: 580px;
    --GW-BREAKPOINT-PHONEWIDE: 768px;
    --GW-BREAKPOINT-TABLET: 1200px;
    --GW-FIELD-COMPONENT-PLACEHOLDER-COLOR: #{$color-amig-gray-60};

    h1{
        @extend .amig-h1-web-text;         
    }
    h2{
        @extend .amig-h2-web-text;    
    }
    h3{
        @extend .amig-h3-web-text;    
    }
    h4{
        @extend .amig-h4-web-text;    
    }
    h5{
        @extend .amig-h5-web-text;    
    }
    h6{
        @extend .amig-h6-web-text;    
    }
    hr{
        border: 1px solid $color-amig-gray-10;
        background: $color-amig-gray-10;
    }
}

.amigRoot .jut__CubeLoader__cubeLoader {
    display:none;
}

.registeredIcon {
    display: contents;
    background: $color-amig-white !important;
    sup {
        margin: 0px 0px 0px 2px;
        top: -0.25em;
        font-size: 80% !important;
    }
  }