:root {
    --gw-divider-margin: 20px;
    --gw-divider-padding: 20px;
    --gw-billing-input-field-thin-height: 20px;
  }

  .gwProducerCodeDivider {
    display: inline-block;
    border-left: solid var(--gw-divider-border) var(--GW-SEPARATOR-COLOR);
    padding-right: var(--GW-SPACING-5);
    margin-left: var(--GW-SPACING-5);
    margin-bottom: var(--GW-SPACING-5);
  }

  .inputFieldThin {
    height: var(--gw-billing-input-field-thin-height);
    width: 100%;

    :global(.jut__FieldComponent__controls) {
      display: contents;
    }
  }

  .costContainer {
    width: 100%;
  }

  .totalDue {
    border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
    padding-top: var(--GW-SPACING-5);
  }

  .gridContainer {
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    padding: var(--GW-SPACING-5);
  }

.endorsementPolicyPeriodDate{
  div[class*='datePicker'] {
    --gw-icon-padding: var(--GW-SPACING-5);
    font-size: var(--GW-FONT-SIZE-H3);
  }
  text-align: center;
}

.iconStyle {
  padding-top: var(--GW-SPACING-6);
  padding-bottom: var(--GW-SPACING-6);
}

.policyChangeDateContainerStyle {
  align-items: center;
  align-content: center;
}

.inlineNotificationStyle {
  margin: var(--GW-SPACING-5) 0px;
}
