.amigTooltip {
    display: inline-block;
    margin-left: 4px;

    @include gw-breakpoint-down(tablet) {
        >div>div {
            display: none !important;
        }
    }

    &:hover {
        img {
            opacity: .8;
        }
    }
}

.amigTooltipCopyContent {
    height: 24px;
    width: 24px;
}