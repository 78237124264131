:root {
    --gw-agent_container-margin:0 auto;
    --gw-agent_container-max-width: 90em;
    --gw-success_details-max-width: 60em;
    --gw-contactagent_checkicon-height: 5em;
    --gw-contactagent_checkicon-width:5em;
}

.agentContainer {
    margin: var(--gw-agent_container-margin);
    max-width: var(--gw-agent_container-max-width);
}
.successDetailsContainer {
    margin: var(--gw-agent_container-margin);
    max-width: var(--gw-success_details-max-width);
}

.confirmationChanges {
    font-size: var(--GW-FONT-SIZE-H2);
    text-align: center;
}

.confirmationDetails {
    text-align: center;
}

.confirmationCheckIcon {
    height: var( --gw-contactagent_checkicon-height);
    width: var(--gw-contactagent_checkicon-width);
    color: var(--GW-COLOR-SUCCESS);
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    border-radius: 50%;
    margin: var(--GW-SPACING-8) auto;
    text-align: center;
}

.confirmationIcon {
    line-height: inherit;
    font-size: var(--GW-FONT-SIZE-H1);
}
