@import "~@jutro/theme/assets/sass/helpers";

.gwInfolabelLabelStyle {
    --info-label-color: var(--GW-TEXT-COLOR-2);
}

.gridPaddingLeft{
    padding-bottom: var(--GW-SPACING-4);
    grid-template-columns: 0fr 3fr;
}

.containerStyle {
    padding-left: var(--GW-SPACING-5);
}

.buttonContainer {
    display: flex;
}

.checkBoxLabel {
    font-size: var(--GW-FONT-SIZE-SM);
}

.checkBoxChange {
    padding-top: var(--GW-SPACING-5);
}

.vehicleMakeModelLabel {
    --gw-min-width-vehiclesMakeModel: 157px;
    font-size: var(--GW-FONT-SIZE-BODY);
    width: max-content;
    min-width: var(--gw-min-width-vehiclesMakeModel);
    padding-right: var(--GW-SPACING-2);
}

.vehicleYearLabel {
    --gw-width-vehicleYear-label: 100%;
    width: var(--gw-width-vehicleYear-label);
}

.driverNameLabel{
    --gw-width-driverName-label: 100%;
    font-size: var(--GW-FONT-SIZE-BODY);
    width: var(--gw-width-driverName-label)
}

.inlineNotificationStyle {
    margin: var(--GW-SPACING-5) 0;
}

.cancelButton {
    float: right;
}

.iconMargin{
    margin-right: 5px;
}

.titleRight {
    clear: both;
    @include gw-breakpoint-down(phone) {
        flex-direction: row;
    }
}
