:root {
    --gw-button-margin: 0.6rem;
  }

.buttonMargin {
    margin-right: var(--gw-button-margin);
}

.cancelButton {
    float: right,
}

.titleRight {
    clear: both;    
}

.mortgageePageTitle {
    div[class*="fullWidth"] {
        width: 100%;
    }
}
