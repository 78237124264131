@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';


.right-aligned {
    text-align: right !important;
}

.left-aligned {
    text-align: left !important;
}

.payment-schedule-table-titles {
    @extend .amig-h6-web-text;
    padding: 8px 0px;
    margin-top: 40px;
    color: $color-amig-gray-100;
    @include gw-breakpoint-down(phone) {
        display: none;
    }
}

.paymentScheduleSeparator {
    margin: 0px !important;

    @include gw-breakpoint-down(phone) {
        display: block;
        margin: 10px 0px 16px 0px !important;
    }
}

.payment-schedule-content {
    @include gw-breakpoint-down(phone) {
        margin-top: 16px;
    }
}
.notificationMargin {
    margin-top: 32px;
}
