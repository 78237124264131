@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';
:root {
  --gw-tablet-sidebar-width: 12rem;
}

.page {
  --gw-amp-page-width: 100%;
  --gw-amp-page-margin-bottom: 50px;
  --gw-amp-page-margin: auto;

  @include gw-breakpoint-only(tablet) {
    --gw-amp-page-width: 100%;
  }

  @include gw-breakpoint-only(phone) {
    --gw-amp-page-width: auto;
    --gw-amp-page-padding-top: 0;
    --gw-amp-page-margin-bottom: 0;
    --gw-amp-page-margin: var(--GW-SPACING-4) 0 var(--GW-SPACING-6);
  }

  // width: var(--gw-amp-page-width);
  margin-bottom: var(--gw-amp-page-margin-bottom);
  margin: var(--gw-amp-page-margin);
  padding-top: var(--gw-amp-page-padding-top)
}

body.themeRoot {
  background: var(--GW-BACKGROUND-COLOR-BODY);

 [class*='subApplicationHeader'] {
    background: var(--GW-BACKGROUND-COLOR);
  }
}

// theme
$fonts-root: "../assets/fonts";

// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ------------------------------------
//   COMMON CSS MODULES
// ------------------------------------
@import "~@jutro/layout/hashedStyles";
@import "~@jutro/components/hashedStyles";
@import "~@jutro/theme/hashedStyles";
@import "~@jutro/datatable/hashedStyles";
@import "~@jutro/router/hashedStyles";

@import "~amig-digital-theme-styles/AMIGDefaultTheme";
@import '../assets/fonts/fonts.scss';
@import '../assets/fonts/material-icons/material-icons.scss';

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}

.error-page-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page-content {
  height: 600px;
  width: 750px;
  text-align: center;
  padding-top: 100px;
  margin-left: 30px;
  margin-right: 30px;
}

.noWrap {
  white-space: nowrap;
}

.error-page-image{
  content: url('../../public/branding/amig/pg-missing-icon.png');
  padding-bottom: 42px;
  @include gw-breakpoint-down(phone){
      content: url('../../public/branding/amig/pg-missing-mb-icon.png');
      padding-bottom: 47px;
  }
}

.modal-error-image{
  content: url('../../public/branding/amig/pg-missing-icon.png');
  margin-bottom: 32px;
}

.error-page-header {
  text-align: center;
  color: $color-amig-gray-100;
  padding-bottom: 24px;
  @include gw-breakpoint-down(phone){
    font-size: 28px !important;
    line-height: 36px !important;
    letter-spacing: 0px;
  }
}

.error-page-message {
  @extend .amig-body1-web-regular;
  text-align: center;
  color: $color-amig-gray-80;
  padding-bottom: 40px;
  @include gw-breakpoint-down(tablet){
    font-size: 16px;    
  }
}

.error-page-button {
  padding: 12px 24px;
  width: 167px;
  height: 48px;
}

.customLoader {
  margin-top: 35vh;
  margin-left: calc(50vw - 104px);
  img {
    display: block;
    margin: auto;
  } 
}

.spinner {
  width:128px;
  height:128px;
}