@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';


.pageContainer {
    --gw-amp-landing-page-page-container-max-width: 100em;
    --gw-amp-landing-page-page-container-grid-areas: "pageTitle pageTitle""infoAndPolicyTable quickLinksAndAdSection";
    --gw-amp-landing-page-page-container-grid-areas-tablet: "pageTitle""infoAndPolicyTable";
    --gw-amp-landing-page-page-container-grid-template-row: auto auto;
    --gw-amp-landing-page-page-container-grid-template-column: 100%;
    --gw-amp-landing-page-page-container-grid-template-column-tablet: 1fr;

    @include gw-breakpoint-down(tablet) {
        --gw-amp-landing-page-page-container-grid-areas: var(--gw-amp-landing-page-page-container-grid-areas-tablet);
        --gw-amp-landing-page-page-container-grid-template-column: var(--gw-amp-landing-page-page-container-grid-template-column-tablet);
    }

    grid-template-rows: var(--gw-amp-landing-page-page-container-grid-template-row);
    grid-template-columns: var(--gw-amp-landing-page-page-container-grid-template-column);
    max-width: 100%;
    display: grid;
    grid-gap: 0;
    justify-content: space-between;
    grid-template-areas: var(--gw-amp-landing-page-page-container-grid-areas);


    .icon>i {
        font-size: var(--GW-FONT-SIZE-H2);
        color: var(--GW-TEXT-COLOR-2);
    }


}

.pageTitle {
    grid-area: pageTitle;
}

.hero-div {
    height: 224px !important;
    margin: -80px;
    margin-bottom: 80px !important;
    background: linear-gradient(90deg, $color-amig-white 15.45%, rgba(255, 255, 255, 0) 83.06%), url('../../assets/images/LandingPage/heroCarousel_desktop.png');
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    @include gw-breakpoint-down(tablet) {
        background: linear-gradient(90deg, $color-amig-white 15.39%, rgba(255, 255, 255, 0) 87.5%), url('../../assets/images/LandingPage/heroCarousel_mobilelandscape.png');
        height: 160px !important;
        margin: -24px -16px;
        margin-bottom: 40px !important;
    }

    @include gw-breakpoint-down(phone) {
        background: linear-gradient(90deg, $color-amig-white 16.13%, rgba(255, 255, 255, 0) 84.53%), url('../../assets/images/LandingPage/heroCarousel_mobilePortrait.png');
        height: 180px !important;
        margin-bottom: 40px !important;
    }
}

.greeting-div {
    margin: 120px 80px 48px;
    position: absolute;
    height: 56px;

    @include gw-breakpoint-down(tablet) {
        height: 36px !important;
        margin: 92px 32px 32px 16px !important;
    }

    @include gw-breakpoint-down(phone) {
        margin: 102px 16px 42px !important;
    }
}

.greeting-title {
    @extend .amig-h1-web-text;
    color: $color-amig-black !important;

    @include gw-breakpoint-down(tablet) {
        font-size: 28px !important;
        line-height: 36px !important;
    }
}

.myPoliciesTitle {
    color: $color-amig-gray-100 !important;
    margin-bottom: 10px !important;
    display: inline-block;

    @include gw-breakpoint-down(tablet) {
        font-size: 20px !important;
        grid-area: pageTitle;
        margin-bottom: 0 !important;
    }

    @include gw-breakpoint-down(phone) {
        font-size: 20px !important;
        grid-area: pageTitle;
    }
}

.policy-card-component-panel {
    @include gw-breakpoint-down(tablet) {
        margin-top: 8px;
    }
}

.tileWithAdContainer {
    --gw-amp-landing-page-tile-container-width: 100%;

    width: var(--gw-amp-landing-page-tile-container-width);
    grid-column-gap: 0;
}

.gwTileAndTableContainer {
    grid-area: infoAndPolicyTable;
}

.gwQuickLinksAndAdContainer {
    grid-area: quickLinksAndAdSection;
}

.gwPoliciesTableTitle {
    padding: 0;

    @include gw-breakpoint-only(phone) {
        padding-top: var(--GW-SPACING-4);
    }
}

.adSection {
    padding-top: var(--GW-SPACING-8);

    @include gw-breakpoint-down(tablet) {
        display: none;
    }
}

.quickLinksContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);

    @include gw-breakpoint-down(tablet) {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: fit-content;

        a {
            padding: 0 var(--GW-SPACING-3);
            justify-content: center;
        }
    }
}

.tileContainer {
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8) var(--GW-SPACING-6);

    div[class*='jut__FieldLabel__left'] {
        flex: 1 2;
    }
}

.agent-card {
    display: block;

    @include gw-breakpoint-down(tablet) {
        padding: 24px 21px !important;
    }

    @include gw-breakpoint-down(phone) {
        padding: 24px 18px !important;
    }

}

.agentComponent {
    margin-bottom: 40px;

    @include gw-breakpoint-down(tablet) {
        margin-bottom: 32px !important;
    }

    @include gw-breakpoint-down(phone) {
        margin-bottom: 32px !important;
    }
}

.agent-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include gw-breakpoint-down(phone) {
        grid-template-columns: repeat(1, 1fr);
    }

    @include gw-breakpoint-down(tablet) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.contact-agent-card {
    margin: 25px 0px;
    padding: 40px 34px 0px 34px !important;
    // width: 160% !important;

    background: $color-amig-white;
    /* Shadow/Base */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-left: 6px solid $color-amig-green-100;

    @include gw-breakpoint-down(tablet) {
        padding: 24px 34px 32px 24px !important;
    }

    @include gw-breakpoint-down(phone) {
        padding: 24px 34px 32px 17px !important;
    }

}

.contact-agent-title {

    color: $color-amig-gray-100 !important;

    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
    }

    @include gw-breakpoint-down(phone) {
        font-size: 16px !important;
    }
}

.contact-agent-subtitle {
    @extend .amig-body1-web-regular;
    margin: 40px 0px !important;
    color: $color-amig-gray-80;

    @include gw-breakpoint-down(tablet) {
        display: none;
    }

    @include gw-breakpoint-down(phone) {
        display: none;
    }
}

.halfBannerContainer {
    --gw-amp-landing-page-tile-container-width: 200px;
    display: none;

    @include gw-breakpoint-only(phone) {
        --gw-amp-landing-page-tile-container-width: 100%;
        --gw-amp-landing-page-tile-container-border: unset;
        display: block;
    }

    width: var(--gw-amp-landing-page-tile-container-width);
}

.textAlignRight {
    text-align: right;
}

.gwTileAmount {
    --gw-amp-landing-page-tile-title-label-min-width: 100px;

    text-align: center;
    align-items: baseline;
    padding-bottom: var(--GW-SPACING-5);

    div:last-child {
        font-size: var(--GW-FONT-SIZE-H4);
        text-align: right;
    }

    .gwTileAmountLabel {
        @include gw-breakpoint-down(phone) {
            width: max-content;
        }
    }
}

.payNowMyBalance {
    --gw-amp-landing-page-my-balance-pay-now-margin-top: 6px;
    margin-top: var(--gw-amp-landing-page-my-balance-pay-now-margin-top);
    width: 100%;
}

.tileWarning span {
    color: var(--GW-COLOR-ERROR);
}

.renewedPolicyIcon {
    color: var(--GW-FOCUS-COLOR);
}

.quickLink {
    --gw-amp-landing-page-quick-link-margin-bottom: 15px;
    display: flex;
    align-items: center;

    i[class*='Link'] {
        font-size: var(--GW-FONT-SIZE-H4);
    }

    @include gw-breakpoint-down(tablet) {
        background-color: var(--GW-FOCUS-COLOR);
        text-align: center;
        width: 100%;

        span {
            color: var(--GW-BACKGROUND-COLOR);
        }

        i {
            display: none;
        }
    }

    &:not(:last-child) {
        margin-bottom: var(--gw-amp-landing-page-quick-link-margin-bottom);
    }
}

.quickLinkTitle {
    --gw-amp-landing-page-quick-link-title-margin-bottom: 16px;
    margin-bottom: var(--gw-amp-landing-page-quick-link-title-margin-bottom) !important;
}

@include gw-breakpoint-down(tablet) {
    .quickLinkTitle {
        margin: auto;
    }
}

.enrollPoliciesContainer {
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    --gw-amp-landing-page-enroll-policy-button-margin-top: 0px;
    margin-top: var(--gw-amp-landing-page-enroll-policy-button-margin-top);

    @include gw-breakpoint-down(tablet) {
        margin-top: 8px;
    }

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.enrollmentLink {
    @extend .text-link-medium;
    margin-left: 0 !important;
}

.policiesTable {
    border-right: 1px solid gray;

    @media(max-width: 480px) {
        td {
            display: table-row;
        }
    }
}

.tableContainer {
    --gw-amp-table-container-padding: 24px 24px 32px 24px;
    --gw-amp-table-container-radius: 4px;
    background: var(--GW-BACKGROUND-COLOR);
    padding: var(--gw-amp-table-container-padding);
    border-radius: var(--gw-amp-table-container-radius);
    box-shadow: var(--GW-SHADOW-2);
}

.mobile-hr {
    display: none;

    @include gw-breakpoint-down(tablet) {
        border: 1px solid #EAEAEA !important;
        display: block;
        margin: 0px !important;
        width: 100%;
    }

    @include gw-breakpoint-down(phone) {
        display: block;
    }
}

.agentinformationtitle {
    color: $color-amig-gray-100 !important;
    margin-bottom: 40px !important;
    margin-top: 42px !important;
    text-align: left;

    @include gw-breakpoint-down(tablet) {
        font-size: 20px !important;
        margin-top: 40px !important;
        line-height: 28px !important;
        margin-bottom: 24px !important;
    }

    @include gw-breakpoint-down(phone) {
        margin-bottom: 24px !important;
    }
}


.claim-btn-regular {
    @extend .btn-secondary-medium;
    background-color: $color-amig-gray-5 !important;
    box-shadow: none !important;
    display: inline-block;
    float: right;

    @media (max-width: 669px) {
        display: none
    }

}

.pageTitle-container {
    @include gw-breakpoint-down(tablet) {
        grid-area: pageTitle;
    }
}

.file-claim-div-mobile {
    display: none;

    @media (max-width: 669px) {
        display: block;
        margin: 8px 0px 16px 0px;
    }
}

.file-claim-mobile {
    @extend .btn-secondary-medium;
    background-color: $color-amig-gray-5 !important;
    box-shadow: none !important;
    width: 100%;
}

 .paperlessNotification {
    height: auto;
	margin: -80px -80px 80px -80px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
	padding: 19px 80px 25px 80px;
	@include gw-breakpoint-down(tablet) {
		margin: -24px -16px 20px -16px;
		padding: 19px 28px 30px 24px;
	}
	
	@media (max-width: 741px) {
		padding: 19px 10px 10px 24px;
	}
	background-color: $color-amig-green-10;
    flex-shrink: 0;
}

.customTextContainer {
    display: inline-block;
	margin: 4px 0px 0px 8px;
	@include gw-breakpoint-down(phone) {
		margin: 0px 0px 0px 8px;
	}

    @media (max-width:1170px) {	
         width: 410px;
     }
    
    @include gw-breakpoint-down(phone) {
        width: 65vw;
    }
}

.customHeading {
	@extend .amig-h6-web-text;
	color: $color-amig-black;
}

.customText {
	display: table-cell;
	@extend .amig-body2-web-regular;
	color: $color-amig-gray-100;
	
	@media (max-width:1170px) {	
		width: 410px;
	}
	@include gw-breakpoint-down(phone) {
		width: 70vw;
	}
}

.goPaperlessActionContainer {
	float: right;	
	@media (max-width: 741px) {
		display: contents;
	}
}

.dismiss-button {
	background-color: transparent !important;
	box-shadow: none;
	height: 24px;
	width: 24px;
	margin: 10px 0px 0px 40px;
	padding: 0px;
	&:focus {
		box-shadow: none;
	}
	@media (max-width: 765px) {
		margin: 10px 0px 0px 20px;	
	}
	@media (max-width: 741px) {
		display: none;
	}
}

.dismiss-button-mobile{
	@extend .dismiss-button;
	margin: 0px;
	float: right;
	display: none;
	@media (max-width: 741px) {
		display: unset
	}
}

.changeDeliveryMethod {
	@extend .text-link-medium, .green-links-states;
	background-color: transparent !important;
	box-shadow: none;
	margin-top: 10px;
	height: 24px;
	padding: 0px;
	@media (max-width: 741px) {
		margin: 5.5px 32px;
	}
}
.iconChecked {
	width: 24px;
	margin-bottom: 6px;
	@include gw-breakpoint-down(phone) {
		vertical-align: top !important;
	}
}