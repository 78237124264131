//new typography
//headers will use Raleway bold font
//body and captions will use arial and arial bold

//for desktop view

.amig-raleway-font {
  font-family: $font-amig-raleway;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

.amig-raleway-bold-font {
  font-family: $font-amig-raleway-bold;
  font-style: normal;
  font-weight: 700;
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
}

.amig-arial-font {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
}

.amig-arial-bold-font {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
}

//desktop headers
.amig-h1-web-text {
  @extend .amig-raleway-bold-font;
  font-size: 40px;
  line-height: 56px;
  margin: unset;
}

.amig-h2-web-text {
  @extend .amig-raleway-bold-font;
  font-size: 32px;
  line-height: 44px;
  margin: unset;
}

.amig-h3-web-text {
  @extend .amig-raleway-bold-font;
  font-size: 28px;
  line-height: 36px;
  margin: unset;
}

.amig-h4-web-text {
  @extend .amig-raleway-bold-font;
  font-size: 24px;
  line-height: 32px;
  margin: unset;
}

.amig-h5-web-text {
  @extend .amig-raleway-bold-font;
  font-size: 20px;
  line-height: 28px;
  margin: unset;
}

//used for tab headers to override styling
.amig-tab-h5-web-text {
  font-family: $font-amig-raleway-bold !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  margin: unset !important;
}

.amig-h6-web-text {
  @extend .amig-raleway-bold-font;
  font-size: 16px;
  line-height: 24px;
  margin: unset;
}

//desktop body
.amig-body1-web-regular {
  @extend .amig-arial-font;
  font-size: 20px;
  line-height: 28px;
}

.amig-body1-web-bold {
  @extend .amig-arial-bold-font;
  font-size: 20px;
  line-height: 28px;
}

.amig-body2-web-regular {
  @extend .amig-arial-font;
  font-size: 16px;
  line-height: 24px;
}

//used for radio button styles
.amig-radio-body2-web-regular {
  @extend .amig-arial-font;
  font-size: 16px !important;
  line-height: 24px !important;
}

.amig-body2-web-bold {
  @extend .amig-arial-bold-font;
  font-size: 16px;
  line-height: 24px;
}

//desktop caption
.amig-caption-web-regular {
  @extend .amig-arial-font;
  font-size: 14px;
  line-height: 20px;
}

.amig-caption-web-bold {
  @extend .amig-arial-bold-font;
  font-size: 14px;
  line-height: 20px;
}

//mobile typography
//mobile headers
.amig-h1-mobile-text {
  @extend .amig-raleway-bold-font;
  font-size: 28px;
  line-height: 36px;
  margin: unset;
}

.amig-h2-mobile-text {
  @extend .amig-raleway-bold-font;
  font-size: 24px;
  line-height: 32px;
  margin: unset;
}

.amig-h3-mobile-text {
  @extend .amig-raleway-bold-font;
  font-size: 20px;
  line-height: 28px;
  margin: unset;
}

.amig-h4-mobile-text {
  @extend .amig-raleway-bold-font;
  font-size: 16px;
  line-height: 24px;
  margin: unset;
}

.amig-h5-mobile-text {
  @extend .amig-raleway-bold-font;
  font-size: 14px;
  line-height: 20px;
  margin: unset;
}

//mobile body
.amig-body1-mobile-regular {
  @extend .amig-arial-font;
  font-size: 16px;
  line-height: 24px;
}

.amig-body1-mobile-bold {
  @extend .amig-arial-bold-font;
  font-size: 16px;
  line-height: 24px;
}

.amig-body2-mobile-regular {
  @extend .amig-arial-font;
  font-size: 14px;
  line-height: 20px;
}

.amig-body2-mobile-bold {
  @extend .amig-arial-bold-font;
  font-size: 14px;
  line-height: 20px;
}

//mobile caption
.amig-caption-mobile-regular {
  @extend .amig-arial-font;
  font-size: 12px;
  line-height: 14px;
}

.amig-caption-mobile-bold {
  @extend .amig-arial-bold-font;
  font-size: 12px;
  line-height: 14px;
}