@import "AccordionCard/AccordionCardTheme.scss";
@import "Button/ButtonTheme.scss";
@import "BurgerMenu/BurgerMenuTheme.scss";
@import "BurgerSidebar/BurgerSidebarTheme.scss";
@import "RadioButton/RadioButtonTheme.scss";
@import "CurrencyField/CurrencyFieldTheme.scss";
@import "DatePickerField/DatePickerFieldTheme.scss";
@import "DropdownSelect/DropdownSelectTheme.scss";
@import "GenericSelectControl/GenericSelectControlTheme.scss";
@import "InlineNotification/InlineNotificationTheme.scss";
@import "Link/LinkTheme.scss";
@import "Toggle/ToggleTheme.scss";
@import "Checkbox/CheckboxTheme.scss";
@import "Stepper/StepperTheme.scss";
@import "Table/TableTheme.scss";
@import "FileUpload/FileUploadTheme.scss";
@import "FieldComponent/FieldComponentTheme.scss";
@import "FieldLabel/FieldLabelTheme.scss";
@import "FieldMessage/FieldMessageTheme.scss";
@import "Header/HeaderTheme.scss";
@import "Tooltip/TooltipTheme.scss";
@import "RootStyles/RootStylesTheme.scss";
@import "Avatar/AvatarTheme.scss";
@import "Avatar/DropdownMenuAvatar.scss";
@import "DropdownMenu/DropdownMenuTheme.scss";
@import "MainContainer/MainContainerTheme.scss";
@import "TabBar/TabBarTheme.scss";
@import "DataTable/DataTableTheme.scss";
@import "Checkbox/CheckboxTheme.scss";
@import "Help/HelpTheme.scss";
@import "Modal/ModalTheme.scss";

.themeRoot {
    @extend .amigRoot;
}

/**
 * Avator
 */
.jut__Avatar__avatar {
    @extend .amigAvatar !optional;
}

.jut__Avatar__overlay {
    @extend .amigAvatarOverlay !optional;
}

.jut__Avatar__image {
    @extend .amigAvataUserImage !optional;
}

.jut__DropdownMenuAvatar__headerText {
    @extend .amigDropdownMenuAvatarHeaderText !optional;
}

.jut__DropdownMenuAvatar__headerTitle {
    @extend .amigDropdownMenuAvatarHeaderTitle !optional;
}

.jut__DropdownMenuAvatar__dropdownMenuAvatarMenu {
    @extend .amigDropdownMenuAvatarMenu !optional;
}

.jut__DropdownMenu__dropdownSeparator {
    @extend .amigDropdownMenuDropdownSeparator !optional;
}

/**
 * ACCORDION CARD
 */
.jut__Accordion__accordionToggle {
    @extend .amigAccordionToggle !optional;
}

/**
*
*/
.jut__BurgerMenu__burgerButton {
    @extend .amigBurgerMenu !optional;
    @extend .amigBurgerSidebarMenu;
    @extend .amigBurgerIcon;

}

.jut__BurgerMenu__burgerButtonIcon {
    @extend .amigBurgerMenu !optional;
    @extend .amigBurgerSidebarMenu;
}

.jut__BurgerMenu__sidebarHeader {
    @extend .amigSidebarMenuHeader;
}

.jut__IconButton__iconButton .jut__IconButton__icon {
    @extend .amigSidebarIcon
}

.jut__BurgerMenu__sidebarHeader .jut__BurgerMenu__menu {
    @extend .hideSidebarTitle
}

/**
 * BUTTON
 */
.jut__Button__button {
    @extend .amigButton !optional;
}

.jut__Button__secondary {
    @extend .amigButtonSecondary !optional;
}

/**
 * RADIO BUTTON
 */
.jut__RadioButton__radioButton {
    @extend .amigRadioButton !optional;
}

/**
 * CHECKBOX
 */
.jut__CheckboxField__checkbox {
    @extend .amigCheckbox !optional;
}

.jut__CheckboxField__checkboxIcon {
    @extend .amigCheckboxIcon !optional;
}

.jut__CheckboxField__checkboxElement {
    @extend .amigCheckboxElement !optional;
}

.jut__CheckboxField__checkboxElementWrapper {
    @extend .amigCheckboxElementWrapper !optional;
}

.jut__CheckboxField__input {
    @extend .amigCheckboxInput !optional;
}

/**
 * CURRENCY FIELD
 */
.jut__CurrencyField__currency {
    @extend .amigCurrency !optional;
}

.jut__CurrencyField__currencyInput {
    @extend .amigCurrencyInput !optional;
}

/**
 * DATE PICKER FIELD
 */
 .jut__InputField__input.jut__DatePickerField__datePicker {
    @extend .amigDatePicker !optional;   
}

/**
 * GENERIC SELECT CONTROL
 */
.jut__GenericSelectControl__genericSelect {
    @extend .amigGenericSelect !optional;
}

.jut__GenericSelectControl__control {
    @extend .amigGenericSelectControl !optional;
}

/**
 * DROPDOWN SELECT
 */
.jut__DropdownSelect__valueContainer {
    @extend .amigValueContainer !optional;
}

.jut__DropdownSelect__control {
    @extend .amigControl !optional;
}

/**
 * INLINE NOTIFICATION
 */
.jut__InlineNotification__inlineNotification {
    @extend .amigInlineNotification !optional;
}

/**
 * TOGGLE
 */
.jut__ToggleField__toggle {
    @extend .amigToggle !optional;
}

.jut__ToggleField__toggleButton {
    @extend .amigToggleButton !optional;
}

.jut__ToggleField__active {
    @extend .amigToggleActive !optional;
}

/**
 * FIELD COMPONENT
 */
.jut__FieldComponent__fieldComponent {
    @extend .amigFieldComponent !optional;
}

.jut__FieldComponent__left {
    @extend .amigFieldComponentAliginLeft !optional;
}

.jut__FieldComponent__reversed {
    @extend .amigReversed !optional;
}

.jut__FieldComponent__contentContainer {
    @extend .amigContentContainer !optional;
}

.jut__FieldComponent__fullWidth {
    @extend .amigFullWidth !optional;
}

.jut__FieldComponent__controlReadOnly {
    @extend .amigControlReadOnly !optional;
}

/**
 * FIELD LABEL
 */
.jut__FieldLabel__fieldLabel {
    @extend .amigFieldLabel !optional;
}

.jut__FieldLabel__optional {
    @extend .amigOptional !optional;
}

.jut__FieldLabel__top {
    @extend .amigFieldLabelTop !optional;
}

/**
 * FIELD MESSAGE
 */
.jut__FieldMessage__fieldMessage {
    @extend .amigFieldMessage !optional;
}

.jut__InputField__input.invalid {
    @extend .amigInputField_inputInvalid;
}

/**
 * FILE UPLOAD
 */
.jut__FileUploadField__wrapper {
    @extend .amigFileUploadWrapper !optional;
}

.jut__FileUploadField__valueText {
    @extend .amigFileUploadValueText !optional;
}

/**
 * LINK
 */
.jut__Link__link {
    @extend .amigLink !optional;
}


/**
 * STEPPER
 */
.jut__StepperField__stepper {
    @extend .amigStepper !optional;
}

.jut__StepperField__button {
    @extend .amigStepperButton !optional;
}

/**
 * TABLE
 */
.jut__Table__table {
    @extend .amigTable !optional;
}

.jut__Table__row {
    @extend .amigRow !optional;
}

/**
 * TYPEAHEAD MULTISELECT
 */
.jut__TypeaheadMultiSelect__control {
    @extend .amigControl !optional;
}

/**
 *HEADER
 */
.jut__Header__header {
    @extend .amigHeaderContent !optional;
}

/**
 * MAIN CONTAINER
 */
.jut__Main__main {
    @extend .amigMain;
}

.jut__Main__container {
    @extend .amigMainContainer;
}

.jut__IFrame__iframeContainer {
    @extend .amigIframeContainer;
}

.jut__TabBar__heading.active {
    @extend .amigActiveTab;
}

.jut__TabBar__heading {
    @extend .amigTabHeader;
}

.jut__GenericSelectControl__option {
    @extend .amigDropdown_option;
}

.jut__GenericSelectControl__optionSelected {
    @extend .amigDropdown_optionSelected;
}

.jut__DataTable__tableHeader {
    @extend .amigDataTableHeader;
}

.jut__DataTable__tableRow {
    @extend .amigDataTableRow;
}

.jut__DataTable__tableBody {
    @extend .amigDataTableBody;
}

.jut__FieldIcon__iconWrapper:last-child {
    @extend .amigFieldIcon;
}

.react-datepicker-wrapper {
    @extend .amigReactDatePickerWrapper;
}

.jut__FilterBar__filterBar {
    @extend .amigFilterBar;
}

.jut__FieldLabel__primaryLabel {
    @extend .amigFieldLabelPrimaryLabel;
}

.jut__Collapse__collapsed.jut__Collapse__show {
    @extend .amigCollapseShow;
}

.jut__GenericSelectControl__control {
    @extend .amigSelectControl;
}

.jut__GenericSelectControl__placeholder{
    @extend .amigSelectControlPlaceholder;
}

.jut__FilterBar__manageFiltersButton {
    @extend .amigManageFiltersButton;
}

.jut__FilterBar__manageFiltersButtonSelected {
    @extend .amigManageFiltersSelectedButton;
}

.jut__FieldIcon__fieldWithIcon input:not(:last-child) {
    @extend .amigFieldWithIcon;
}

.jut__DataTable__tableBar {
    @extend .amigDataTableBar;
}

.jut__DataTable__tableHeader {
    @extend .amigDataTableHeader;
}

.jut__DataTable__tableRow {
    @extend .amigDataTableRow;
}

.jut__RadioButton__radioButton input[type=radio]:checked+.jut__RadioButton__label::before {
    @extend .amigActiveRadioButton;
}


.jut__RadioButton__radioButton input[type=radio]+.jut__RadioButton__label::before {
    @extend .amigRadioButton;
}

.jut__Container__container {
    @extend .amigContainer;
}

.jut__DatePickerField__control {
    @extend .amigDatePickerFieldControl;
}

.react-datepicker {
    @extend .amigReactDatePicker;
}

.react-datepicker__day{
    @extend .amigReactDatePickerDay;
}

.react-datepicker__day-name{
    @extend .amigReactDatePickerDayName;
}

.jut__DatePickerField__todayButton {
    @extend .amigDatePickerFieldTodayButton;
}

.jut__DataTable__tableBody {
    @extend .amigDataTable;
}

.jut__DataTable__tableCell {
    @extend .amigDataTableCell;
}

.jut__DataTable__tableHeaderCell {
    @extend .amigTableHeaderCell;
}

.jut__FilterBar__filterTitle {
    @extend .amigFiltertitle;
}

.jut__Flex__gwWrap {
    @extend .amiggwWrap;
}

.jut__FilterBar__filterItem {
    @extend .amigfilterItem;
}

.jut__FilterBar__titleBar {
    @extend .amigFilterBar;
}

.jut__Pagination__pageSizeSelectWrapper {
    @extend .amigPaginationSelectWrapper;
}

.jut__Pagination__pageButtonsWrapper {
    @extend .amigPaginationPageButtonWrapper;
}

.jut__Pagination__selectedPageButton {
    @extend .amigSelectedPaginationButton;
}

.rt-thead div.rt-th.-cursor-pointer::after {
    @extend .amigHeadDivrtrh;
}

.rt-thead div.rt-th.-sort-asc::after {
    @extend .amigrtHead;
}

.jut__FieldIcon__fieldWithIcon .jut__DataTable__searchWrapper {
    @extend .amigFieldIconSearchWrapper;

}

.jut__DataTable__search {
    @extend .amigDataTableSearch;
}

.jut__FilterBar__searchBar {
    @extend .amigFilterSearchBar;
}

.jut__DataTable__headerOneLine .jut__DataTable__tableHeaderCell {
    @extend .amigDataTableHeaderOnelIne;
}

.rt-thead div.rt-th.-sort-desc:focus,
.ReactTable .rt-thead div.rt-th.-sort-asc:focus {
    @extend .amigDivrtth;
}

.jut__DataTable__dataTable .mir {
    @extend .amig_Data_Table;
}

.jut__DataTable__dataTable *:not(.mir) {
    @extend .amigData_Table;

    .jut__InputMaskField__inputMask {
        @extend .amigInputMaskField;
    }
}

.jut__DataTable__noDataMessage {
    @extend .amigData_table_noDataMessage;
}

.mi-help {
    @extend .amig_mi-help;
}

.mi-help-outline {
    @extend .amig_mi-help-outline;
}

.mi-check:before {
    @extend .amig_mi-check;
}

.jut__CheckboxField__checkboxElement {
    @extend .amig_jut_CheckboxField;
}

.jut__CheckboxField__input:checked+.jut__CheckboxField__checkboxElement {
    @extend .amig_CheckboxElement;
}

.jut__GenericSelectControl__control.invalid {
    @extend .amigInputField_inputInvalid;
}

.jut__FieldMessage__error {
    @extend .amigFieldMessage_error;
}

.jut__Flex__gwDirectionRow.jut__Flex__gwGapSmall>* {
    @extend .amigFlexgwDirectionRow;
}

.jut__ModalNext__modal {
    @extend .amigModal;
}
