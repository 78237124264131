@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.stickyButton {
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 60px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
    figure {
        margin: unset !important;
    }

    @include gw-breakpoint-down(tablet) {
        height: 48px;
        width: 48px;
    }
}

.contactUsButton {

    @extend .stickyButton;
    top: 0px;
    left: calc(100vw - 112px);
    background-color: $color-amig-lightPurple-100;

    &:active,
    &:hover,
    &:active:focus,
    &:active:focus:hover,
    &:focus {
        background-color: $color-amig-lightPurple-100;
        box-shadow: none;
    }

    @include gw-breakpoint-down(tablet) {
        left: calc(100vw - 60px);
    }
    @include gw-breakpoint-down(phone) {
        top: -30px;
    }
}

.chatBotButton {

    @extend .stickyButton;
    top: 128px;
    left: calc(100vw - 112px);
    background-color: $color-amig-lightGreen-100;

    &:active,
    &:hover,
    &:active:focus,
    &:active:focus:hover,
    &:focus {
        background-color: $color-amig-lightGreen-100;
        box-shadow: none;
    }

    @include gw-breakpoint-down(tablet) {
        top: 104px;
        left: calc(100vw - 60px);
   
    }
    @include gw-breakpoint-down(phone) {
        top: 74px;   
    }
}

.stickyButtonImage {
    height: 32px !important;
    width: 32px;
    max-width: unset !important;
    @include gw-breakpoint-down(tablet) {
        height: 24px !important;
        width: 24px;
    }
}

.stickyContainer {
    position: fixed;
    top: 96px;
    right: 0px;
    height: calc(100vh - 96px);
    width: 383px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
    display: none;
    background: $color-amig-white;
    z-index: 400;

    @include gw-breakpoint-down(tablet) {
        top: 0px;
        width: 100vw;
        height: 100vh;
    }
}

.contactUsContainer {
    @extend .stickyContainer;
}

.chatBotContainer {
    @extend .stickyContainer;
}

@keyframes slideIn {
    0% {
        transform: translateX(383px);
    }

    100% {
        transform: translateX(0);
    }
}

.displayStickySection {
    display: block;
    animation-name: slideIn;
    animation-duration: 0.5s;
}

.stickyHeaderContainer {
    height: 76px;
    padding: 24px 32px 24px 44px;

    @include gw-breakpoint-down(tablet) {
        height: 96px;
        padding: 36px 16px 36px 80px;
    }
}

.contactUsHeaderContainer {
    @extend .stickyHeaderContainer;
    background: $color-amig-lightPurple-100;
}

.chatBotHeaderContainer {
    @extend .stickyHeaderContainer;
    background: $color-amig-lightGreen-100;
}

.stickyHeader {
    display: inline-block;
}

.stickyHeaderText {
    @extend .amig-h5-web-text;
    color: $color-amig-white;
    vertical-align: text-top;

    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
        line-height: 24px !important;
    }

}

.closeStickySection {
    float: right;

    @include gw-breakpoint-down(tablet) {
        height: 24px;
    }

    &:active,
    &:hover,
    &:focus {
        background: none;
        box-shadow: none;
    }
}

.closeContactUs {
    @extend .closeStickySection;
    i{
        &:hover{
            background: $color-amig-lightPurple-80;
        }
    }  
}

.closeChatBot {
    @extend .closeStickySection;
    i{
        &:hover{
            background: $color-amig-lightGreen-80;
        }
    }    
}

.stickyHeaderImage {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    position: absolute;
    top: 8px;
    left: -28px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);


    @include gw-breakpoint-down(tablet) {
        height: 48px;
        width: 48px;
        top: 24px;
        left: 16px;
        box-shadow: none;
    }


}

.contactUsHeaderImg {
    @extend .stickyHeaderImage;
    background: $color-amig-lightPurple-100;

    @include gw-breakpoint-down(tablet) {
        background: $color-amig-white;
    }
}

.chatBotHeaderImg {
    @extend .stickyHeaderImage;
    background: $color-amig-lightGreen-100;

    @include gw-breakpoint-down(tablet) {
        background: $color-amig-white;
    }
}

.stickyButtonHeaderImage {
    height: 32px !important;
    width: 32px;
    max-width: unset !important;
    position: absolute;
    left: 14px;
    top: 14px;

    @include gw-breakpoint-down(tablet) {
        height: 24px !important;
        width: 24px;
        left: 12px;
        top: 12px;
    }
}


.contactUsComponentContainer {
    height: calc(100vh - 172px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 10px;

    @include gw-breakpoint-down(tablet) {
        overflow-y: scroll;
        height: calc(100vh - 96px) !important;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $color-amig-gray-5;
        margin: 32px 0px;

        @include gw-breakpoint-down(tablet) {
            margin: 84px 0px;
        }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $color-amig-lightPurple-100;
        border-radius: 5px;

        /* Handle on hover */
        &:hover {
            background: $color-amig-lightPurple-100;
        }
    }

  
}

.customStickyContainer{   
    z-index: 400;    
    position: sticky;
    top: calc(100vh - 372px);
    height: 0px; 
    width: 0px; 

    @include gw-breakpoint-down(tablet) {
        top: calc(100vh - 196px);
    }

    @include gw-breakpoint-down(phone) {
        top: calc(100vh - 245px);
    }

}