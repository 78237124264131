@import '~amig-digital-theme-styles/styles/core';

.popupFieldComponent {
    width: 100%;
}

.pageBreadcrumb {
    padding: 0px;
    margin-bottom: 24px;

    span {
        @extend .amig-h5-mobile-text;
    }

    a[class*='jut__Breadcrumb__breadcrumbLink'] {
        padding: 0px;
        @extend .text-links-states;
        &:active{
            background: none;
        }
    }

    span[class*='jut__Breadcrumb__divider'] {
        color: $color-amig-gray-80;
        margin: 0px 6px;
    }

    span[class*='jut__Breadcrumb__currentLink'] {
        color: $color-amig-gray-100;
        padding: 0px;
    }
}