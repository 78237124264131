@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.amig-logo {
    position: absolute;
    width: 170px;
    height: 47.69px;
    left: 80px;
    top: 24.15px;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0px;
    box-shadow: 0px 0px $color-amig-blue-100 !important;
    background: $color-amig-blue-100 !important;

    @include gw-breakpoint-down(tablet) {
        position: absolute;
        height: 36px;
        width: 128px;
        left: 16px;
        right: 0px;
        top: 14px;
    }

    @include gw-breakpoint-down(phone) {
        position: absolute;
        height: 36px;
        width: 128px;
        left: 16px;
        right: 0px;
        top: 30px !important;
    }

    &:hover,
    &:active,
    &:visited {
        box-shadow: 0px 0px $color-amig-blue-100 !important;
        background: $color-amig-blue-100 !important;
        border: none !important;
    }

}


.amig-header {
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding: 24px 80px;
    height: 96px;
    left: 0px;
    top: 0px;
    /* Blue/100 */
    background: $color-amig-blue-100;
    /* Shadow/Base */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 350;

    @include gw-breakpoint-down(tablet) {
        height: 64px;
        padding: 24px;
    }

    @include gw-breakpoint-down(phone) {
        height: 96px;
        padding: 24px 26px;
    }
}

.amig-header a {
    text-decoration: none;
}

.customHeaderMobile {
    @include gw-breakpoint-down(phone) {
        margin-bottom: 0px;
    }
}

.exit-button {
    @extend .btn-secondary-medium;
    background-color: transparent !important;
    box-shadow: none !important;
    color: $color-amig-white;
    border: 2px solid $color-amig-white;
}

.closebutton-mobile {
    float: right;

    @include gw-breakpoint-down(tablet) {
        height: 24px;
    }

    &:active,
    &:hover,
    &:focus {
        background: none;
        box-shadow: none;
    }
}