@import "~@jutro/theme/assets/sass/helpers";
@import '~amig-digital-theme-styles/styles/core';

.tabContent {
    display: flex;
    align-items: center;

    >i {
        padding-right: 2px;
    }
}

.pageHeaderId {
    margin-bottom: 40px !important;
    color: $color-amig-gray-100 !important;

    @include gw-breakpoint-down(tablet) {
        font-size: 20px !important;
        margin-bottom: 24px !important;
        line-height: 28px !important;
    }
}

.policyselector {
    z-index: 0;
    div[class*='jut__GenericSelectControl__control'] {
        @extend .amig-body2-web-regular;
    }
    div {
        margin-top: 0.5px;
        box-shadow: none !important;
        color: $color-amig-gray-100;
    }
    >div{
        height: 48px !important;
        width: 438px !important;

        @include gw-breakpoint-down(tablet) {
            width: 284px !important;
        }
        @media (max-width: 669px) { 
            width: 100% !important;
        }
    }
}

.policies-label{
    @extend .amig-caption-web-bold;
    margin-bottom: 8px;
    color: $color-amig-gray-100;
    @include gw-breakpoint-down(tablet) {
        font-size: 12px;
    }
}

.policyselectorChevron {
    span {
        transform: rotateX(180deg);
    }
}

.billing-policy-card {
    height: auto;
    margin: 24px 0px;
    margin-top: 40px;
    padding: 40px !important;
    background: $color-amig-white;
    /* Shadow/Base */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border-left: 6px solid $color-amig-blue-100;
    
    @include gw-breakpoint-down(tablet) {
        height: auto !important;
        margin-top: 24px;
        padding: 24px 18px 40px 18px !important;
    }
    
    @include gw-breakpoint-down(phone) {
        margin-top: 40px;
    }
}

.policyCardTabStory {
    >hr {
        display: none;
    }
    >div>div>button {
        @extend .amig-tab-h5-web-text;
        padding: 0px !important;
        color: $color-amig-gray-60 !important;
        margin-right: 24px !important;
    }
    button[class*='jut__TabBar__prevIconButton'], 
    button[class*='jut__TabBar__nextIconButton'] {
        display: none;
    }

    @include gw-breakpoint-down(tablet) {
        >div>div>button {
            font-size: 16px !important;
        }
    }
}

.policyCardTab {
    @extend .amig-tab-h5-web-text;
    padding: 0px;
}

.agencyBillDetails {
    >div:first-of-type {
        display: none;
    }
}

.agency-bill-header {
    @extend .amig-h5-web-text;

    @include gw-breakpoint-down(tablet) {
        font-size: 16px !important;
    }
}

.page-container{
    position: relative;
}

.billing-summary-div{
    @include gw-breakpoint-down(tablet) { 
    position: relative;        
    }
}

.ezPay-button{
    @extend .btn-secondary-medium;
    background-color: transparent !important;
    box-shadow: none !important;

    @include gw-breakpoint-down(tablet){
        position: absolute;
        right: 185px
    }   
    
    @media (max-width: 669px) { 
        position: initial !important; 
    }    
   
}

.payment-btn-regular{
    margin-left: 16px;
    box-shadow: none !important;

    @include gw-breakpoint-down(tablet) { 
        position: absolute;
        right: 0px;
        justify-content: right;
        width: 169px;
        margin: 0;
    }
    @media (max-width:669px) {
      display: none;
    }
}

.mt-custom{
    margin-top: 28px;
    @media (max-width: 669px) { 
        margin-top: 0 !important;
    }
}

.payment-div{
    @media(min-width: 669px){
        grid-template-columns: 0.8fr 0.2fr !important;
    }
}

.payment-div-mobile{
    display: none;
    @media (max-width: 669px) { 
    display: grid;
    position: sticky;
    bottom: 0;
    background-color: $color-amig-gray-5;
}
}

.make-a-payment-mobile{
    display: none;
    @media (max-width:669px) {
        display: block;
        margin-bottom: 24px !important;
        width: 100% !important;
        box-shadow: none !important;      
    }
}

.hrStyle{
    display: none;
    @include gw-breakpoint-down(tablet){
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    background: $color-amig-black !important;
    opacity: 0.2 !important;
    width: 100% !important;
    }
}

.enrollPoliciesContainer {
    @extend .amig-body2-web-regular;
    --gw-amp-landing-page-enroll-policy-button-margin-top: 16px;
    margin-top: var(--gw-amp-landing-page-enroll-policy-button-margin-top);
    color: $color-amig-gray-80;
    a:hover{
        text-decoration: none !important;
    }
    @media (max-width: 669px) { 
        display: none !important;
    }
}   

.enrollPoliciesContainer-mobile {
    display: none;
    @extend .amig-body2-web-regular;
    color: $color-amig-gray-80;
    @media (max-width: 669px) { 
        display: block;
        margin: 16px 0 24px;
        a:hover{
            text-decoration: none !important;
        }
    }
}

.enrollmentLink{
    @extend .text-link-medium;
    margin-left: 0 !important;
}

.billing-layout{
    grid-column-gap: 0 !important;
}