:root {
  --gw-cost-box-padding: 20px;
  --gw-box-padding: 25px;
  --gw-border-solid: 2px;
  --gw-divider-margin: 20px;
  --gw-divider-padding: 20px;
  --gw-divider-border: 1px;
  --gw-page-section-header-row-padding-top: 8px;
  --gw-titles-subtitle-offset: 16px;
  --gw-label-left-padding: 58px;
  --gw-icon-top-padding: 50px;
  --gw-icon-right-padding: 45px;
  --gw-date-left-padding: 100px;
  --gw-right-padding-space: 36px;
}

.gwProducerCodeDivider {
  display: inline-block;
  border-left: solid var(--gw-divider-border) var(--gw-grey-light-30-color);
  padding-right: var(--gw-divider-padding);
  margin-left: var(--gw-divider-margin);
}

.inputFieldThin {
  width: 100%;

  :global(.jut__FieldComponent__controls) {
    display: contents;
  }
}

.costContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.totalDue {
  border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
}

.gridContainer {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  padding: var(--gw-cost-box-padding);
}

.iconStyle {
  --gw-icon-top-padding: 45px;
  padding-top: var(--gw-icon-top-padding);
}

.endorsementPolicyPeriodDateLabelClass{
  text-align: center;

}

.policyChangeDateContainerStyle {
  align-items: center;
  align-content: center;
}

.hoDateIdClassName {
  div[class*='datePicker'] {
    font-size: var(--font-xlg);
  }
  text-align: center;
}
